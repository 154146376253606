import React, {Component} from 'react';
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNT_API} from "../../../../constants/backend/ApiConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {Connector} from "../../../../../../redux/Connector";
import LoadingOrErrorOrComponent from "../../../general/LoadingOrErrorOrComponent";

class BusinessPermissions extends Component {
    state = {
        is_loading: true,
        something_went_wrong: false,
        business: {}
    }

    constructor(props) {
        super(props);
        this.BusinessComponent = this.BusinessComponent.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNT_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]), (response) => {
            this.setState({
                is_loading: false,
                business: response.body
            });
        }, (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true
            });
        })
    }

    BusinessComponent() {
        return <div>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    {this.state.business.businessName}
                </h4>
                <p className={"text-muted m-0"}>
                    Manage all business permission for members
                </p>
            </div>

            <div className={"bg-white rounded shadow-sm p-2 mt-2 border border-secondary"}>
                This feature is coming soon
            </div>
        </div>;
    }

    render() {
        return (<div className={"p-2 flex-fill flex-column d-flex"}>
            <LoadingOrErrorOrComponent
                is_loading={this.state.is_loading}
                something_went_wrong={this.state.something_went_wrong}
                label={"Loading business/services"}
                component={this.BusinessComponent}/>
        </div>);
    }
}

export default Connector(BusinessPermissions);