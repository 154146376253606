import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {LOGIN_PAGE_URL} from "../../constants/application/ApplicationUrl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SignInButton extends Component {
    render() {
        return (
            <div>
                <Button variant={"outline-primary"} as={Link} to={LOGIN_PAGE_URL + "?redirect=" + window.location.pathname}>
                    <FontAwesomeIcon icon={"sign-in-alt"} /> Sign in
                </Button>
            </div>
        );
    }
}

export default SignInButton;