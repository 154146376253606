import React, {Component} from 'react';
import {Offcanvas} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Connector} from "../../../../../../redux/Connector";
import {ACCOUNT_URL, ITS_MY_WEB_PRICING_URL, LOGIN_PAGE_URL} from "../../../../constants/application/ApplicationUrl";
import {MY_SERVICES_URL} from "../../../../constants/application/account/services/ServiceAccountUrls";
import {ACCOUNT_ORDERS_URL} from "../../../../constants/application/account/OrdersUrls";
import {LOGOUT_API} from "../../../../constants/backend/ApiConstant";
import {isNullOrUndefinedObject} from "../../../../utils/validators/Validator";

class SidePanel extends Component {
    state = {
        sections: [
            {
                title: "",
                tabs: [
                    {
                        name: "Pricing",
                        link: ITS_MY_WEB_PRICING_URL
                    },
                ]
            },
            {
                title: "Manage ItsMyWeb Account",
                tabs: [
                    {
                        name: "Account",
                        link: ACCOUNT_URL
                    },
                    {
                        name: "Services",
                        link: MY_SERVICES_URL
                    },
                    {
                        name: "Orders",
                        link: ACCOUNT_ORDERS_URL
                    }
                ]
            },
            {
                title: "",
                tabs: [
                    {
                        name: "Logout",
                        hidden: !isNullOrUndefinedObject(this.props.username),
                        link: LOGOUT_API
                    }
                ]
            }
        ]
    }

    render() {
        return (
            <div>
                <Offcanvas show={this.props.show} onHide={this.props.onHide} style={{width: 280, zIndex: 3333}}>
                    <Offcanvas.Header className={"bg-light px-2"} closeButton>
                        <Offcanvas.Title>
                            {
                                this.props.user.username ?
                                    <div>
                                        <FontAwesomeIcon icon={"user-circle"} className={"me-2"} size={"1x"} />
                                        {this.props.user.firstName} {this.props.user.lastName}
                                    </div>:
                                    <a href={LOGIN_PAGE_URL} className={"no-text-decor"}>
                                        <FontAwesomeIcon icon={"user-circle"} className={"me-2"} size={"1x"} />
                                        Login
                                    </a>
                            }
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={"border-top p-0"}>
                        {
                            this.state.sections.map((section, s_index) => {
                                return <div key={"s_section_" + s_index} className={"border-top p-2"}>
                                    <div>
                                        <h6 className={"fw-bold py-1"}>{section.title}</h6>
                                    </div>
                                    {
                                        section.tabs.map((tab, t_index) => {
                                            return <Link to={tab.link} onClick={this.props.onHide} className={"no-text-decor"} key={"s_tab_" + s_index + "_" + t_index}><div>
                                                <p className={""}>{tab.name}</p>
                                            </div></Link>;
                                        })
                                    }
                                </div>
                            })
                        }
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        );
    }
}

export default Connector(SidePanel);