import React, {Component} from "react";

import {Navbar, Container, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Connector} from "../../../../../redux/Connector";
import {
    HOME_URL,
    ITS_MY_WEB_PRICING_URL,
    LOGIN_PAGE_URL
} from "../../../constants/application/ApplicationUrl";
import "./navbar.css";
import SidePanel from "./sidepanel/SidePanel";
import SignInButton from "../../general/SignInButton";

class NavbarComponent extends Component {

    state = {
        account_dropdown: false,
        showSidePanel: false
    }

    constructor(props) {
        super(props);
        this.showDropdown = this.showDropdown.bind(this);
        this.hideDropdown = this.hideDropdown.bind(this);
        this.OnClickSidePanelShow = this.OnClickSidePanelShow.bind(this);
    }

    showDropdown() {
        this.setState({
            account_dropdown: true
        });
    }


    hideDropdown() {
        this.setState({
            account_dropdown: false
        });
    }

    OnClickSidePanelShow() {
        this.setState({
            showSidePanel: !this.state.showSidePanel
        });
    }

    render() {
        return (
            <>
                <Navbar bg="light" expand="lg" fixed={"top"} sticky={"top"} className={"shadow-sm"}>
                    <Container fluid>
                        <div className={"d-flex"}>
                            <div className={"d-flex align-items-center"}>
                                <div className={"me-2 px-1 show-on-small-screen cursor-pointer"} onClick={this.OnClickSidePanelShow}>
                                    <FontAwesomeIcon icon={"bars"} color={"black"} />
                                </div>
                            </div>
                            <Navbar.Brand href={HOME_URL} className={"fw-bold"}>
                                <div>
                                    <FontAwesomeIcon icon={"home"}/> ITSMYWEB
                                </div>
                            </Navbar.Brand>
                        </div>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav>
                                <Nav.Link href={ITS_MY_WEB_PRICING_URL}>
                                    Pricing
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Collapse id="responsive-navbar-nav" className={"justify-content-end"}>
                            <Navbar.Brand id="responsive-navbar-nav" className={"d-flex"}>
                            {
                                this.props.user.username ? <>
                                    {/*<Dropdown align={"end"} className={"me-1"}>*/}
                                    {/*    <Dropdown.Toggle variant="outline-dark" className={"shadow-none"} bsPrefix={"none"}>*/}
                                    {/*        <FontAwesomeIcon icon={"bell"}/>*/}
                                    {/*    </Dropdown.Toggle>*/}

                                    {/*    <Dropdown.Menu className={"navbar-dropdown-bg border-0 m-0"}>*/}
                                    {/*        <div className={"p-2 bg-white"}>*/}
                                    {/*            <div className={"border-bottom p-2"}>*/}
                                    {/*                Notification 01*/}
                                    {/*            </div>*/}
                                    {/*            <div className={"border-bottom p-2"}>*/}
                                    {/*                Notification 01*/}
                                    {/*            </div>*/}
                                    {/*            <div className={"border-bottom p-2"}>*/}
                                    {/*                Notification 01*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </Dropdown.Menu>*/}
                                    {/*</Dropdown>*/}
                                </> : <div>
                                    {
                                        window.location.pathname !== LOGIN_PAGE_URL &&
                                        <SignInButton />
                                    }
                                </div>
                            }
                        </Navbar.Brand>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <SidePanel show={this.state.showSidePanel} onHide={this.OnClickSidePanelShow}/>
            </>
        );
    }
}

export default Connector(NavbarComponent);

