import React, {Component} from 'react';
import {Form, Button, Spinner} from "react-bootstrap";
import {Connector} from "../../../../../../redux/Connector";
import PublicServiceFooter from "../../../../../public/components/footer/PublicServiceFooter";
import {BackendGETRequest, BackendPOSTRequest} from "../../../webrequests/BackendRequest";
import {
    GET_ALL_CUSTOM_WEB_PAGES_API,
    GET_CUSTOM_WEB_PAGE_FOOTER_API
} from "../../../../constants/backend/ApiConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {AboutUsSection} from "./AboutUsSection";
import {PoliciesSection} from "./PoliciesSection";
import {ContactUsSection} from "./ContactUsSection";
import {AddressSection} from "./AddressSection";
import LoadingOrErrorOrComponent from "../../../general/LoadingOrErrorOrComponent";
import {ShowErrorModalFromError} from "../../../itsmyweb/modals/centermodal/CenterModalScreenManager";

class ManageFooter extends Component {
    state = {
        is_loading: false,
        something_went_wrong: false,
        updating_footer: false,
        page_settings: {
            tabs: [],
            pages: []
        },
        footer: {
            enabled: true,
            name: "",
            about_us: {
                enabled: false,
                pages: []
            },
            policies: {
                enabled: false,
                pages: []
            },
            contact_us: {
                enabled: false,
                contacts: {}
            },
            address: {
                enabled: false,
                address: ""
            }
        }
    }

    constructor(props) {
        super(props);
        this.WebsiteComponent = this.WebsiteComponent.bind(this);
        this.OnChangeInput = this.OnChangeInput.bind(this);
        this.OnClickDeletePage = this.OnClickDeletePage.bind(this);
        this.OnClickAddPage = this.OnClickAddPage.bind(this);
        this.OnClickUpdateButton = this.OnClickUpdateButton.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ALL_CUSTOM_WEB_PAGES_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),
            (response) => {
                this.setState({page_settings: response.body});
            }, (error) => {
                console.error(error);
            });

        BackendGETRequest(this.props, GET_CUSTOM_WEB_PAGE_FOOTER_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),
            (response) => {
                if(!response.body["about_us"] || !response.body["policies"] || !response.body["address"] || !response.body["contact_us"]) {
                    this.setState({
                        is_loading: false
                    });
                    return;
                }

                this.setState({
                    footer: response.body, is_loading:false
                });
            }, (error) => {
                this.setState({something_went_wrong: true, is_loading: false});
                ShowErrorModalFromError(this.props, "Footer", error);
            });
    }

    OnChangeInput(event) {
        let localFooter = this.state.footer;
        if(event.target.type === "checkbox") {
            let prevElement = localFooter;
            let elementKeys = event.target.name.split(".");
            elementKeys.slice(0,-1).forEach(data => {
                if(prevElement[data] === undefined) {
                    prevElement[data] = {};
                }

                prevElement = prevElement[data];
            });
            prevElement[elementKeys[elementKeys.length - 1]] = event.target.checked;
        } else {
            let prevElement = localFooter;
            let elementKeys = event.target.name.split(".");
            elementKeys.slice(0,-1).forEach(data => {
                if(prevElement[data] === undefined) {
                    prevElement[data] = {};
                }

                prevElement = prevElement[data];
            });
            prevElement[elementKeys[elementKeys.length - 1]] = event.target.value;
        }

        this.setState({
            footer: localFooter
        });
    }

    OnClickDeletePage(index, page_name) {
        let localFooter = this.state.footer;
        let section = localFooter[page_name];
        section.pages.splice(index, 1);
        this.setState({
            footer: localFooter
        });
    }

    OnClickAddPage(page_name, page) {
        let localFooter = this.state.footer;
        let section = localFooter[page_name];
        section.pages.push({
           name: page.pageName,
           link: page.id
        });
        this.setState({
            footer: localFooter
        });
    }

    WebsiteComponent() {
        return <div>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Website Footer Settings
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage footer page settings
                </p>
            </div>
            <div className={"bg-white rounded shadow-sm p-2 mt-2"}>
                <div className={"px-1"}>
                    <Form.Check type={"switch"} className={"shadow-none py-2"} label={"Enable footer at website"}
                                name={"enabled"} checked={this.state.footer.enabled} onChange={this.OnChangeInput} />
                </div>
                {
                    this.state.footer.enabled &&
                    <div className={"d-flex flex-wrap"}>
                        <AboutUsSection footer={this.state.footer} page_settings={this.state.page_settings}
                                        OnChangeInput={this.OnChangeInput}
                                        OnClickDeletePage={this.OnClickDeletePage}
                                        OnClickAddPage={this.OnClickAddPage} />
                        <PoliciesSection footer={this.state.footer} page_settings={this.state.page_settings}
                                         OnChangeInput={this.OnChangeInput}
                                         OnClickDeletePage={this.OnClickDeletePage}
                                         OnClickAddPage={this.OnClickAddPage} />
                        <ContactUsSection footer={this.state.footer} OnChangeInput={this.OnChangeInput} />
                        <AddressSection footer={this.state.footer} OnChangeInput={this.OnChangeInput} />
                    </div>
                }
                <div className={"px-1"}>
                    <Button className={"my-2 shadow-none"} onClick={this.OnClickUpdateButton} disabled={this.state.updating_footer}>
                        {
                            this.state.updating_footer &&
                            <Spinner animation={"border"} size={"sm"} className={"me-1"} />
                        }
                        Update
                    </Button>
                </div>
            </div>
            {
                this.state.footer.enabled &&
                <div className={"py-4"}>
                    <h6 className={"fw-bold"}>
                        Preview Footer
                    </h6>
                    <div className={"position-relative"}>
                        <div className={"bg-secondary"} style={{height: 200}}/>
                        <PublicServiceFooter footer={this.state.footer}/>
                        <div className={"position-absolute top-0 start-0 h-100 w-100"} style={{zIndex: 1111}}/>
                    </div>
                </div>
            }
        </div>;
    }

    OnClickUpdateButton() {
        this.setState({
            updating_footer: true
        });

        BackendPOSTRequest(this.props, GET_CUSTOM_WEB_PAGE_FOOTER_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),this.state.footer,
            (response) => {
                this.setState({
                    updating_footer: false
                });
            }, (error) => {
                this.setState({
                    updating_footer: false
                });
                ShowErrorModalFromError(this.props, "Footer", error);
            });
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                <LoadingOrErrorOrComponent is_loading={this.state.is_loading}
                                           label={"Loading footer settings"}
                                           something_went_wrong={this.state.something_went_wrong}
                                           component={this.WebsiteComponent} />
            </div>
        );
    }
}

export default Connector(ManageFooter);