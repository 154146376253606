import React, {Component} from 'react';
import {isEmptyString, isNullOrUndefinedObject} from "../../../itsmyweb/utils/validators/Validator";
import {DEFAULT_IMAGE_REPLACER_URL} from "../../../itsmyweb/constants/Constant";

class ImageGalleryWidget extends Component {

    constructor(props) {
        super(props);
        this.ImageColumn = this.ImageColumn.bind(this);
    }

    ImageColumn({columnIndex}) {
        if(isNullOrUndefinedObject(this.props.images) || this.props.images.length === 0 ) {
            return [...new Array(2)].map((element, index) => <div key={"image_" + columnIndex + "_" + index}
                                                       className={"p-1"}>
                <img alt={"its-my-web"} className={"w-100"} src={DEFAULT_IMAGE_REPLACER_URL}/>
            </div>);
        }

        return this.props.images
            .filter((image, index) => index % 3 + 1 === columnIndex)
            .map((image, index) => <div key={"image_" + columnIndex + "_" + index} className={this.props.imageParentClassName} >
                <img alt={"its-my-web"} className={this.props.imageClassName} src={isEmptyString(image)?DEFAULT_IMAGE_REPLACER_URL:image} />
            </div>);
    }

    render() {
        return (
            <section>
                <div className={"d-flex flex-wrap " + this.props.parentClassName}>
                    <div className={"col-lg-4 d-flex flex-column"}>
                        <this.ImageColumn columnIndex={1} />
                    </div>
                    <div className={"col-lg-4 d-flex flex-column"}>
                        <this.ImageColumn columnIndex={2} />
                    </div>
                    <div className={"col-lg-4 d-flex flex-column"}>
                        <this.ImageColumn columnIndex={3} />
                    </div>
                </div>
            </section>
        );
    }
}

export default ImageGalleryWidget;