import React, {Component} from 'react';
import {WIDGET_TYPES} from "./WidgetTypes";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    widgetGallery,
    widgetHeader1, widgetHeader2, widgetHeader3, widgetHeader4, widgetHeader5,
    widgetImageWithText,
    widgetImageWithText2, widgetImageWithText3,
    widgetTextWithPara
} from "./WidgetDummyProvider";

class WidgetPicker extends Component {
    data = [
        widgetHeader1(),
        widgetHeader2(),
        widgetHeader3(),
        widgetHeader4(),
        widgetHeader5(),
        widgetTextWithPara(),
        widgetImageWithText(),
        widgetImageWithText2(),
        widgetImageWithText3(),
        widgetGallery()
    ]

    render() {
        return (
            <div className={"bg-light pb-5"}>
                <div className={"p-2 m-2 shadow-sm position-relative itsmyweb-title"}>
                    <h5>
                        Click to add a widget from below to the web page
                    </h5>
                    <p className={"text-muted"}>
                        You can discard/cancel adding widget by click on close button at right corner
                    </p>
                    <div className={"position-absolute top-0 end-0 p-2"} >
                        <Button variant={"outline-danger"} onClick={this.props.onCloseButtonClick}>
                            <FontAwesomeIcon icon={"times"} />
                        </Button>
                    </div>
                </div>
                {
                    this.data.map((dataComponent, index) => <div key={dataComponent.widgetKey + "_" + index}
                                                        onClick={()=>this.props.onClickWidget(dataComponent)}
                                                        className={"m-2 border hover-shadow bg-hover-white"}>
                        {
                            WIDGET_TYPES[dataComponent.widgetKey].component(dataComponent)
                        }
                    </div>)
                }
            </div>
        );
    }
}

export default WidgetPicker;