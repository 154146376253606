import {Button, Form, FormControl, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export function SelectFromFollowing({footer, page_settings, page_name, OnClickAddPage}) {
    let page_ids = footer["about_us"].pages.map(page => page.link);
    page_ids = page_ids.concat(footer["policies"].pages.map(page => page.link));

    return <div className={"py-2"}>
        <p className={"fw-bold small m-0"}>
            Select from the following pages
        </p>
        {
            [...page_settings.pages, ...page_settings.tabs].filter(page => page.enabled && !page_ids.includes(page.id)).map((page, index) => <div key={"page_i_" + page_name + page.id} className={"py-1"}>
                <InputGroup>
                    <FormControl className={"shadow-none"} defaultValue={page.pageName}
                                 disabled={true}
                                 data-link={page.id}/>
                    <Button variant={"success"} className={"shadow-none"} onClick={() => OnClickAddPage(page_name, page)}>
                        <FontAwesomeIcon icon={"check-circle"} />
                    </Button>
                </InputGroup>
            </div>)
        }
        <Button>
            Create new page
        </Button>
    </div>
}

export function AboutUsSection({footer, page_settings, OnChangeInput, OnClickDeletePage, OnClickAddPage}) {
    return <div className={"col-md-6 p-1"}>
        <div className={"shadow-sm p-2 rounded border h-100"}>
            <h5 className={"fw-bold m-0"}>About Us</h5>
            <p className={"text-muted fw-bold m-0"}>{footer.name}</p>
            <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Enable"}
                        checked={footer.about_us.enabled} name={"about_us.enabled"} onChange={OnChangeInput}/>
            <div className={"about_us_pages py-2"}>
                <p className={"fw-bold small m-0"}>
                    Selected pages for about us
                </p>
                {
                    footer.about_us.pages.map((page, index) => <div key={"au_page_i_" + page.link} className={"py-1"}>
                        <InputGroup>
                            <FormControl className={"shadow-none"} defaultValue={page.name}
                                         disabled={true}
                                         data-link={page.link}/>
                            <Button variant={"danger"} className={"shadow-none"} onClick={() => OnClickDeletePage(index, "about_us")} >
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                        </InputGroup>
                    </div>)
                }
            </div>
            <SelectFromFollowing footer={footer} page_settings={page_settings} OnClickAddPage={OnClickAddPage} page_name={"about_us"} />
        </div>
    </div>
}