import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {HOME_URL, TRACKING_SERVICE_URL} from "../../itsmyweb/constants/application/ApplicationUrl";
import PublicHome from "../components/home/PublicHome";
import {PUBLIC_WEB_PAGE_URL} from "./PublicUrls";
import PublicWebPage from "../components/page/PublicWebPage";
import PublicNavbarComponent from "../components/navbar/PublicNavbarComponent";
import {GetServiceAccountNameFromDomain} from "../../itsmyweb/utils/ItsMyWebUtil";
import {BackendGETRequest} from "../../itsmyweb/components/webrequests/BackendRequest";
import {PUBLIC_GET_FOOTER_API, PUBLIC_GET_NAVBAR_API} from "../../itsmyweb/constants/backend/ApiPublicConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../itsmyweb/constants/ParamConstant";
import {Connector} from "../../../redux/Connector";
import ItsMyWebFooter from "../components/footer/ItsMyWebFooter";
import PublicServiceFooter from "../components/footer/PublicServiceFooter";
import ShowPublicTrackingService from "../components/home/mobile/ShowPublicTrackingService";

class PublicUrlsComponent extends Component {
    serviceAccountName = GetServiceAccountNameFromDomain();

    state = {
        navbar: {
            enabled: true,
            logo: "",
            enabledLogo: false,
            enabledSignIn: false,
            logoClass: "",
            navbarTabTestClass: "",
            navbarParentClass: "",
            tabs: {
                pages: []
            }
        },
        footer: {
            enabled: false,
            name: "",
            about_us: {
                enabled: false
            },
            policies: {
                enabled: false
            },
            contact_us: {
                enabled: false
            },
            address: {
                enabled: false
            }
        }
    }

    componentDidMount() {
        BackendGETRequest(this.props, PUBLIC_GET_NAVBAR_API.replace(SERVICE_ACCOUNT_PARAM, this.serviceAccountName), (response) => this.setState({navbar: response.body}));
        BackendGETRequest(this.props, PUBLIC_GET_FOOTER_API.replace(SERVICE_ACCOUNT_PARAM, this.serviceAccountName), (response) => this.setState({footer: response.body}));
    }

    render() {
        return (
            <div className={"d-flex flex-column flex-fill"}>
                <PublicNavbarComponent navbar={this.state.navbar} showSignIn={!this.props.user.username} />

                <div className={"flex-fill d-flex flex-column"}>
                    <Switch>
                        <Route path={HOME_URL} exact={true} component={PublicHome} />
                        <Route path={PUBLIC_WEB_PAGE_URL} exact={true} component={PublicWebPage} />
                        <Route path={TRACKING_SERVICE_URL} exact={true} component={ShowPublicTrackingService}/>
                    </Switch>
                </div>

                <PublicServiceFooter footer={this.state.footer} />
                <ItsMyWebFooter />
            </div>
        );
    }
}

export default Connector(PublicUrlsComponent);