import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/app/App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import {store, persist_store} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {fab, faChrome} from '@fortawesome/free-brands-svg-icons';
import {
    faUser,
    faCoffee,
    faAddressBook,
    faLocationArrow,
    faSearchLocation,
    faEdit,
    faTrash,
    faSearch,
    faAddressCard,
    faBox,
    faBoxOpen,
    faUserTie,
    faUserLock,
    faKey,
    faUsers,
    faBullseye,
    faHandHoldingUsd,
    faImages,
    faTags,
    faComments,
    faQuestionCircle,
    faBell,
    faCogs,
    faCheckCircle,
    faTimes,
    faTimesCircle,
    faHome,
    faSignOutAlt,
    faSignInAlt,
    faMobile,
    faBars,
    faChevronLeft,
    faChevronRight,
    faCheck, faCheckSquare, faCalendar, faUserCircle, faPlus, faEnvelope, faPhone
} from '@fortawesome/free-solid-svg-icons';

library.add(
    fab,
    faUser,
    faCoffee,
    faAddressBook,
    faLocationArrow,
    faSearchLocation,
    faEdit,
    faTrash,
    faSearch,
    faAddressCard,
    faBox,
    faBoxOpen,
    faUserTie,
    faUserLock,
    faKey,
    faUsers,
    faBullseye,
    faHandHoldingUsd,
    faImages,
    faTags,
    faComments,
    faQuestionCircle,
    faChrome,
    faBell,
    faCogs,
    faCheckCircle,
    faTimes,
    faTimesCircle, faHome,
    faSignOutAlt,
    faSignInAlt,
    faMobile,
    faBars,
    faChevronLeft,
    faChevronRight,
    faCheck,
    faCheckSquare,
    faCheck,
    faCalendar,
    faUserCircle,
    faPlus,
    faEnvelope,
    faPhone
)

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persist_store}>
              <BrowserRouter/>
              <App/>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
