import {GetAlphaNumStringWithoutSpace, GetAlphaStringWithoutSpace, GetAlphaStringWithSpace} from "./ItsMyWebUtil";

export function JsonFromFormEvent(event) {
    let formJsonData = {};

    for(const element of event.target.elements) {
        if(element.type === "submit" || element.name === "image") {
            continue;
        }

        if(element.type === "checkbox") {
            formJsonData[element.name] = element.checked;
        } else if(element instanceof HTMLInputElement || element instanceof HTMLSelectElement) {
            formJsonData[element.name] = element.value;
        }
    }

    return formJsonData;
}

export function CreteImageForm(key, value, imageBlob) {
    let formData = new FormData();
    formData.set(key, value);
    formData.set("image", imageBlob);
    return formData;
}

export function AlphaAndSpaceOnlyForInput(event) {
    event.target.value = GetAlphaStringWithSpace(event.target.value);
}

export function AlphaOnlyForInput(event) {
    event.target.value = GetAlphaStringWithoutSpace(event.target.value);
}

export function AlphaNumOnlyForInput(event) {
    event.target.value = GetAlphaNumStringWithoutSpace(event.target.value);
}