import React, {Component} from 'react';
import {Button, FormControl} from "react-bootstrap";

class TextWithParaWidgetEdit extends Component {
    constructor(props) {
        super(props);
        this.OnUpdateEditField = this.OnUpdateEditField.bind(this);
    }


    OnUpdateEditField(event) {
        let value = event.target.value;
        if(event.target.type === "checkbox") {
            value = event.target.checked;
        }

        this.props.OnUpdateEditField(this.props.index, event.target.name, value);
    }

    render() {
        return (
            <div className={"position-relative"}>
                <div className={"position-absolute top-0 end-0"}>
                    <Button onClick={this.props.onClickDone} className={"rounded-0"}>
                        Done
                    </Button>
                </div>

                <h5 className={"itsmyweb-title p-2"}>Text with paragraph widget edit</h5>
                <div>
                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Parent classes
                        </label>
                        <FormControl value={this.props.dataComponent.parentClassName} onChange={this.OnUpdateEditField} name={"parentClassName"} placeholder={"Parent classes"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Text classes
                        </label>
                        <FormControl value={this.props.dataComponent.textClassName} onChange={this.OnUpdateEditField} name={"textClassName"} placeholder={"Text classes"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Text content
                        </label>
                        <FormControl as={"textarea"} value={this.props.dataComponent.content} onChange={this.OnUpdateEditField} name={"content"} placeholder={"Text content"} className={"shadow-none"} />
                    </div>

                </div>
            </div>
        );
    }
}

export default TextWithParaWidgetEdit;