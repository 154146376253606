import React, {Component} from 'react';
import {Connector} from "../../../../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SetDocumentTitle} from "../../../../managers/DocumentTitleManager";
import "./pricing.css";

class ItsMyWebPricing extends Component {
    componentDidMount() {
        SetDocumentTitle("Pricing");
    }

    Free() {
        return <div className={"p-2 col-md-4 flex-fill"}>
            <div className={"rounded-pm-1 overflow-hidden bg-white shadow-sm hover-shadow gold_gradient h-100"}>
                <div style={{background: "rgba(255,255,255,0.74)"}} className={"p-4 h-100"} >
                    <h4 className={"fw-bold border-bottom"}>Free</h4>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        Number of service account to create <b className={"px-1"}>2</b>
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        Upto <b className={"px-1"}>100 static image</b> upload for your website
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        <div>
                            You can create upto <b className={"px-1"}>10 pages</b> per service account (including tabs)
                        </div>
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        Page and tab management
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        Basic version of tracking service
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                        Free basic insights
                    </div>
                </div>
            </div>
        </div>
    }

    Premium() {
        return <div className={"p-2 col-md-4 flex-fill"}>
            <div className={"rounded-pm-1 overflow-hidden bg-white shadow-sm hover-shadow gold_gradient h-100"}>
                <div style={{background: "rgba(255,255,255,0.4)"}} className={"p-4 h-100"}>
                    <h4 className={"fw-bold border-bottom"}>Premium (Free)</h4>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        Number of service account to create <b className={"px-1"}>3</b>
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        Upto <b className={"px-1"}>200 static image</b> upload for your website
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        <div>
                            You can create upto <b className={"px-1"}>12 pages</b> per service account (including tabs)
                        </div>
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        Page and tab management
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        Basic version of tracking service
                    </div>
                    <div className={"py-1 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"}/>
                        Free basic insights
                    </div>
                </div>
            </div>
        </div>
    }

    Super() {
        return <div className={"p-2 col-md-4 flex-fill"}>
            <div className={"rounded-pm-1 p-4 bg-white shadow-sm hover-shadow gold_gradient h-100"}>
                <h4 className={"fw-bold border-bottom"}>Super (Free)</h4>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    Number of service account to create <b className={"px-1"}>5</b>
                </div>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    Upto <b className={"px-1"}>500 static image</b> upload for your website
                </div>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    <div>
                        You can create upto <b className={"px-1"}>15 pages</b> per service account (including tabs)
                    </div>
                </div>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    Page and tab management
                </div>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    Basic version of tracking service
                </div>
                <div className={"py-1 d-flex align-items-center"}>
                    <FontAwesomeIcon icon={"check-circle"} className={"me-2"} color={"green"} />
                    Free basic insights
                </div>
            </div>
        </div>
    }

    render() {
        return (
            <div className={"flex-grow-1 d-flex flex-fill flex-column"}>
                <div className={"w-100 flex-fill d-flex position-relative overflow-hidden"} >
                    <svg className={"position-absolute top-0"} style={{zIndex: -1, height: "70%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#294cda" fillOpacity="1" d="M0,256L60,224C120,192,240,128,360,133.3C480,139,600,213,720,245.3C840,277,960,267,1080,250.7C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                    </svg>
                    <div className={"p-3 d-flex flex-fill flex-wrap"}>
                        <this.Free />
                        <this.Premium />
                        <this.Super />
                    </div>
                </div>
            </div>
        );
    }
}

export default Connector(ItsMyWebPricing);