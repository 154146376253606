import React, {Component} from 'react';

class HeadingWidget extends Component {

    constructor(props) {
        super(props);
        this.Header = this.Header.bind(this);
    }

    Header() {
        switch (this.props.headerType) {
            case "h-1":
                return <h1 className={this.props.headerClassName}>
                    {this.props.content}
                </h1>;

            case "h-2":
                return <h2 className={this.props.headerClassName}>
                    {this.props.content}
                </h2>;

            case "h-3":
                return <h3 className={this.props.headerClassName}>
                    {this.props.content}
                </h3>;

            case "h-4":
                return <h4 className={this.props.headerClassName}>
                    {this.props.content}
                </h4>;

            case "h-5":
                return <h5 className={this.props.headerClassName}>
                    {this.props.content}
                </h5>;

            default:
                return <h6 className={this.props.headerClassName}>
                    {this.props.content}
                </h6>;
        }
    }

    render() {
        return (
            <section>
                <div className={this.props.parentClassName} >
                    <this.Header />
                </div>

                {
                    String(this.props.centerLine) === "true" &&
                    <div className={"w-100 d-flex justify-content-center"}>
                        <div style={{width: 100, borderBottom: "4px solid #000"}}/>
                    </div>
                }
            </section>
        );
    }
}

export default HeadingWidget;