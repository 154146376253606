import { Component } from "react";
import {Container, Row} from "react-bootstrap";
import {MY_SERVICES_URL} from "../../constants/application/account/services/ServiceAccountUrls";
import {ALL_ADDRESS_URL} from "../../constants/application/account/AddressUrls";
import {ACCOUNT_SECURITY_URL} from "../../constants/application/account/SecurityUrls";
import {ACCOUNT_ORDERS_URL} from "../../constants/application/account/OrdersUrls";
import {ACCOUNT_PROFILE_URL} from "../../constants/application/ApplicationUrl";
import {SmallPanel} from "./panels/SmallPanel";

class MyAccount extends Component{
    state = {
        sections : [
            {
                label: "My profile",
                icon: "user",
                content: "Edit, manage my profile",
                url: ACCOUNT_PROFILE_URL
            },
            {
                label: "My services",
                icon: "user-tie",
                content: "Manage my services, business",
                url: MY_SERVICES_URL
            },
            {
                label: "My Orders",
                icon: "box-open",
                content: "Track, return, manage, buy again",
                url: ACCOUNT_ORDERS_URL
            },
            {
                label: "Security",
                icon: "user-lock",
                content: "Secure your account",
                url: ACCOUNT_SECURITY_URL
            },
            {
                label: "My Address",
                icon: "address-card",
                content: "Edit, manage my address",
                url: ALL_ADDRESS_URL
            }
        ]
    }

    render(){
        return(
            <Container className={"p-2 flex-fill"}>
                <h4 className={""}>
                    My Account
                </h4>
                <Row noGutters={true}>
                    {
                        this.state.sections.map(section =>
                            <SmallPanel label={section.label}
                                        icon={section.icon}
                                        content={section.content}
                                        to={section.url}/>)
                    }
                </Row>
            </Container>
        )
    }
}
export default MyAccount;