import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {
    AlphaNumOnlyForInput,
    JsonFromFormEvent
} from "../../../../../utils/FormManager";
import {SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {CREATE_SERVICE_TRACKING_API} from "../../../../../constants/backend/ApiConstant";
import {
    SHOW_ALL_SERVICES_TRACKING_URL
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {Badge, Button, Form, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import TrackServiceBlock from "./TrackServiceBlock";

class CreateNewServiceTracking extends Component {
    state = {
        is_loading: false,
        show_success_msg: false,
        form: {
            estimate: 1,
            discount: 0,
            discountAmount: 0,
            accessoriesTaken: "",
            accessoriesAttached: ""
        },
        services: []
    }

    constructor(props) {
        super(props);
        this.OnChangeInput = this.OnChangeInput.bind(this);
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
        this.OnSuccessCreate = this.OnSuccessCreate.bind(this);
        this.OnFailureCreate = this.OnFailureCreate.bind(this);
    }

    OnChangeInput(event) {
        let localForm = this.state.form;
        localForm[event.target.name] = event.target.value;
        this.setState({
            form: localForm
        })
    }

    OnSubmitForm(event) {
        event.preventDefault();
        let formJsonData = JsonFromFormEvent(event);
        formJsonData["businessId"] = this.props.match.params[SERVICE_ACCOUNT_PARAM];

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_SERVICE_TRACKING_API, formJsonData, this.OnSuccessCreate, this.OnFailureCreate);
        return false;
    }

    OnSuccessCreate(response) {
        let localServices = this.state.services;
        if(localServices.length > 3) {
            localServices.pop();
        }
        localServices.push(response.body);

        this.setState({
            is_loading: false,
            show_success_msg: true,
            services: localServices
        });

        window.screenTop = 0;
        setTimeout(()=>this.setState({
            show_success_msg: false
        }), 2000);
    }

    OnFailureCreate(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Custom page error", error);
    }

    render() {
        return (
            <div className={"p-1 flex-fill flex-column d-flex"}>
                <div className={"d-flex flex-wrap"}>
                    <div className={"col-md-9 col-lg-10"}>
                        <div className={"bg-white rounded shadow-sm p-2 m-1"}>
                            <h5 className={"fw-bold"}>
                                Create New Service Tracking
                            </h5>
                            <p className={"text-secondary m-0 small"}>
                                Create new tracking service and manage the realtime track for your end users
                            </p>
                        </div>

                        {
                            this.state.show_success_msg &&
                            <div className={"rounded shadow-sm p-2 m-1 my-2 bg-success text-white"}>
                                Tracking service has been created successfully
                            </div>
                        }
                        {
                            this.state.services.length > 0 &&
                            <div className={"my-2 d-flex"}>
                                {
                                    this.state.services.map(service => {
                                        return <div className={"p-1 col-md-4"} key={service.id}>
                                            <TrackServiceBlock service={service} businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]}/>
                                        </div>
                                    })
                                }
                            </div>
                        }

                        <div className={"bg-light rounded shadow-sm p-2 m-1 my-2"}>
                            <form onSubmit={this.OnSubmitForm}>
                                <div>
                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Bill Number <span className={"text-danger"}>*</span>
                                        </label>
                                        <Form.Control required minLength={2} maxLength={12} onChange={AlphaNumOnlyForInput}
                                                      className={"shadow-none mb-2 text-uppercase validate-input bg-white"} name={"billNumber"} />
                                    </div>

                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Description <span className={"text-danger"}>*</span>
                                        </label>
                                        <Form.Control required minLength={2} maxLength={200} className={"shadow-none mb-2 validate-input"}
                                                      name={"description"}/>
                                    </div>

                                    <div className={"d-flex flex-wrap"}>
                                        <div className={"col-md-4 p-1 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Customer email
                                            </label>
                                            <Form.Control minLength={5} maxLength={50} type={"email"}
                                                          className={"shadow-none mb-2 validate-input"}
                                                          name={"customerEmail"}/>
                                        </div>
                                        <div className={"col-md-4 p-1 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Customer mobile number
                                            </label>
                                            <Form.Control type={"number"} min={1000000000} max={9999999999}
                                                          className={"shadow-none mb-2 validate-input"}
                                                          name={"customerMobile"}/>
                                        </div>
                                        <div className={"col-md-4 p-1 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Customer name
                                            </label>
                                            <Form.Control minLength={2} maxLength={40}
                                                          className={"shadow-none mb-2 validate-input"}
                                                          name={"customerName"}/>
                                        </div>
                                    </div>

                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Customer address
                                        </label>
                                        <Form.Control as={"textarea"} maxLength={200} className={"shadow-none mb-2 validate-input"}
                                                      name={"customerAddress"}/>
                                    </div>

                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Any Note
                                        </label>
                                        <Form.Control maxLength={100} className={"shadow-none mb-2 validate-input"}
                                                      placeholder={"Enter note eg. pattern lock"} name={"note"}/>
                                    </div>

                                    <div className={"d-flex flex-wrap"}>
                                        <div className={"p-1 col-md-4 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Estimate (Rs)
                                            </label>
                                            <Form.Control required value={this.state.form.estimate} onChange={this.OnChangeInput} type={"number"} min={1} max={100000} className={"shadow-none mb-2 validate-input"}
                                                          placeholder={"Enter estimate"} name={"estimate"}/>
                                        </div>

                                        <div className={"p-1 col-md-4 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Discount Amount (Rs)
                                            </label>
                                            <Form.Control type={"number"} value={this.state.form.discountAmount} onChange={this.OnChangeInput} min={0} max={this.state.form.estimate} className={"shadow-none mb-2 validate-input"} defaultValue={0}
                                                          placeholder={"Enter discount"} name={"discountAmount"}/>
                                        </div>

                                        <div className={"p-1 col-md-4 flex-fill"}>
                                            <label className={"fw-bold small"}>
                                                Discount (%)
                                            </label>
                                            <Form.Control type={"number"} value={this.state.form.discount} onChange={this.OnChangeInput} min={0} max={99} className={"shadow-none mb-2 validate-input"} defaultValue={0}
                                                          placeholder={"Enter discount"} name={"discount"}/>
                                        </div>
                                    </div>

                                    <div className={"p-1"}>
                                        <b>Net Price:</b> {((this.state.form.estimate - this.state.form.discountAmount)*(100 - this.state.form.discount)/100).toFixed(2)} Rs.
                                    </div>
                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Accessories taken from customer
                                        </label>
                                        <div className={"pb-1"}>
                                            {
                                                this.state.form.accessoriesTaken?.split(/[\s,]+/).map(accessories => <Badge className={"me-1"}>{accessories}</Badge>)
                                            }
                                        </div>
                                        <Form.Control value={this.state.form.accessoriesTaken} onChange={this.OnChangeInput} maxLength={100} className={"shadow-none mb-2 validate-input"}
                                                      name={"accessoriesTaken"}/>
                                    </div>
                                    <div className={"p-1"}>
                                        <label className={"fw-bold small"}>
                                            Accessories attached
                                        </label>
                                        <div className={"pb-1"}>
                                            {
                                                this.state.form.accessoriesAttached?.split(/[\s,]+/).map(accessories => <Badge className={"me-1"}>{accessories}</Badge>)
                                            }
                                        </div>
                                        <Form.Control value={this.state.form.accessoriesAttached} onChange={this.OnChangeInput} maxLength={100} className={"shadow-none mb-2 validate-input"}
                                                      placeholder={"eg. glass, rc"} name={"accessoriesAttached"}/>
                                    </div>



                                    <div className={"my-3 p-1"}>
                                        <Button type={"submit"} disabled={this.state.is_loading}>
                                            {
                                                this.state.is_loading &&
                                                <Spinner animation={"border"} size={"sm"} />
                                            }
                                            Create Service Ticket
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className={"p-1 col-md-3 col-lg-2 d-flex flex-column"}>
                        <h6 className={"fw-bold"}>Are you looking for this</h6>
                        <Button variant={"outline-primary"} disabled={this.state.is_loading} as={Link} to={SHOW_ALL_SERVICES_TRACKING_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])}>
                            Show all service tracking
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Connector(CreateNewServiceTracking);