import React, {Component} from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {CUSTOM_PAGE_SINGLE_WEB_PAGE_URL} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {HOME_PAGE, TRACKING_SERVICE_PAGE} from "../../../../../constants/Constant";
import {PageType} from "../../../../../constants/PageType";

class SectionComponent extends Component {

    PageUrlFromId(pageId, pageType) {
        if(pageId === undefined) return;
        if(pageType === PageType.HOME) {
            pageId = HOME_PAGE;
        } else if(pageType === PageType.TRACKING_SERVICE) {
            pageId = TRACKING_SERVICE_PAGE;
        }

        return CUSTOM_PAGE_SINGLE_WEB_PAGE_URL
            .replace(COL + SERVICE_ACCOUNT_PARAM, this.props.businessId)
            .replace(COL + PAGE_ID_PARAM, pageId);
    }

    render() {
        return <Droppable direction={"horizontal"} droppableId={this.props.droppableId}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}
                     className={"bg-light rounded shadow-sm p-2 mt-2"}>
                    <h5 className={"itsmyweb-title p-1"}>{this.props.title}</h5>
                    <p className={"text-muted small"}>
                        Drag in between, rearrange & edit
                    </p>
                    <div className={"d-flex"}>
                        {
                            this.props.sections.map((page, index) => <Draggable key={"drop_" + this.props.prefix + index} draggableId={this.props.prefix + index}
                                                                                    index={index}>
                                {(providedDraggable) => (
                                    <div
                                        ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps}
                                        className={"p-2 d-flex flex-column bg-white m-1 rounded hover-shadow border shadow-sm"}>
                                        <div className={"position-relative flex-fill"}>
                                            <h6>
                                                {
                                                    page.iconName &&
                                                    <FontAwesomeIcon icon={page.iconName}/>
                                                } {page.pageName}
                                            </h6>
                                            <p className={"flex-fill small text-muted m-0"}> {page.pageShortName} </p>
                                            {
                                                page.enabled && <p className={"flex-fill small text-success"}>
                                                    <FontAwesomeIcon icon={"check-circle"} color={"green"} /> Enabled
                                                </p>
                                            }
                                        </div>
                                        <div className={"d-flex flex-column"}>
                                            <Button className={"shadow-none"} as={Link} to={this.PageUrlFromId(page.id, page.pageType)}>
                                                <FontAwesomeIcon icon={"edit"} /> Edit {page.pageName}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Draggable>)
                        }
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>;
    }
}

export default SectionComponent;