import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {
    SHOW_SINGLE_SERVICE_TRACKING_URL
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {SERVICE_ACCOUNT_PARAM, TRACKING_ID_PARAM} from "../../../../../constants/ParamConstant";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {Table} from "react-bootstrap";

class TrackServiceBlock extends Component {
    render() {
        return (
            <Link
                to={SHOW_SINGLE_SERVICE_TRACKING_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.businessId).replace(COL + TRACKING_ID_PARAM, this.props.service.id)}
                className={"no-text-decor"}>
                <div className={"bg-white rounded p-2 shadow-sm hover-shadow border"}>
                    <Table borderless className={"w-100"}>
                        <tbody>
                        <tr>
                            <td className={"fw-bold"}>
                                Bill Number
                            </td>
                            <td>
                                {this.props.service.billNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className={"fw-bold"}>
                                Tracking Number
                            </td>
                            <td>
                                {this.props.service.trackingNumber}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <div className={"small"}>
                        <div className={"bg-light p-1 rounded"}>
                            {this.props.service.description}
                        </div>
                    </div>
                    <div className={"text-primary fw-bold"}>
                        {this.props.service.customerName}
                    </div>
                    <div className={"text-secondary fw-bold small"}>
                        {this.props.service.customerMobile}
                    </div>
                    <div>
                        <b>Estimate: </b> {this.props.service.estimate} Rs.
                    </div>
                </div>
            </Link>
        );
    }
}

export default TrackServiceBlock;