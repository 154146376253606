import React, {Component} from 'react';
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {TRACKING_ID_PARAM} from "../../../itsmyweb/constants/ParamConstant";
import {COL, TRACKING_SERVICE_URL} from "../../../itsmyweb/constants/application/ApplicationUrl";
import {isEmptyString} from "../../../itsmyweb/utils/validators/Validator";
import {
    ShowOkButtonErrorModal
} from "../../../itsmyweb/components/itsmyweb/modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../redux/Connector";
import {AlphaNumOnlyForInput} from "../../../itsmyweb/utils/FormManager";

class ServiceTrackingWidget extends Component {

    constructor(props) {
        super(props);
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
    }

    OnSubmitForm(event) {
        event.preventDefault();
        let trackingId = event.target.elements["trackingId"].value;
        if(isEmptyString(trackingId)) {
            ShowOkButtonErrorModal(this.props, "Invalid Tracking Id", "Tracking id can't be empty");
            return false;
        }

        trackingId = trackingId.toUpperCase();
        if(trackingId.length !== 10 && !trackingId.startsWith("TN")) {
            ShowOkButtonErrorModal(this.props, "Invalid Tracking Id", "Tracking id " + trackingId + " is invalid");
            return false;
        }

        window.location.href = TRACKING_SERVICE_URL.replace(COL + TRACKING_ID_PARAM, trackingId);
        return false;
    }

    render() {
        return (
            <section>
                <div className={"container py-3"}>
                    <div className={"d-flex justify-content-center"}>
                        <form onSubmit={this.OnSubmitForm}>
                            <div className={"p-2"}>
                                <label className={"fw-bold small"}>
                                    Enter tracking id
                                </label>
                                <InputGroup>
                                    <FormControl onChange={AlphaNumOnlyForInput} className={"shadow-none text-uppercase"} maxLength={50} name={"trackingId"} placeholder={"Enter tracking id eg.TN..."} />
                                    <Button type={"submit"} className={"shadow-none"}>Submit</Button>
                                </InputGroup>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

export default Connector(ServiceTrackingWidget);