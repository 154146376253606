import React, {Component} from 'react';

class AccountSecurity extends Component {
    render() {
        return (
            <div>
                Account security
            </div>
        );
    }
}

export default AccountSecurity;