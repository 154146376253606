import React, {Component} from 'react';
import {Connector} from "../../../../../../redux/Connector";
import {Route, Switch} from "react-router-dom";
import {
    CUSTOM_PAGE_MANAGE_URL,
    MANAGE_WEBSITE_FOOTER_SETTINGS_URL,
    MANAGE_WEBSITE_NAVBAR_SETTINGS_URL,
    MANAGE_WEBSITE_SETTINGS_URL,
    MY_SINGLE_SERVICE_SETTINGS_URL,
    CUSTOM_PAGE_SINGLE_WEB_PAGE_URL,
    CUSTOM_PAGE_CREATE_NEW_PAGE_URL,
    CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL,
    SHOW_ALL_SERVICES_TRACKING_URL,
    SHOW_SINGLE_SERVICE_TRACKING_URL,
    MY_SINGLE_SERVICE_PERMISSION_URL,
} from "../../../../constants/application/account/services/ServiceAccountUrls";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import ServiceTabLeftPanel from "../../leftpanel/ServiceTabLeftPanel";
import BusinessAndServiceSettings from "../../sections/business_and_service/BusinessAndServiceSettings";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNT_API} from "../../../../constants/backend/ApiConstant";
import ManageWebsite from "../../sections/manage_website/ManageWebsite";
import ManageNavbar from "../../sections/manage_navbar/ManageNavbar";
import ManageFooter from "../../sections/manage_footer/ManageFooter";
import CustomPageSetting from "../../sections/custome_page/manage_page_tabs/CustomPageSetting";
import CustomSinglePage from "../../sections/custome_page/manage_page_tabs/CustomSinglePage";
import CustomCreateNewPage from "../../sections/custome_page/manage_page_tabs/CustomCreateNewPage";
import {COL, HOME_URL} from "../../../../constants/application/ApplicationUrl";
import CreateNewServiceTracking from "../../sections/custome_page/service_tracking/CreateNewServiceTracking";
import ShowAllServiceTracking from "../../sections/custome_page/service_tracking/ShowAllServiceTracking";
import ShowSingleServiceTracking from "../../sections/custome_page/service_tracking/ShowSingleServiceTracking";
import {HOME_PAGE, TRACKING_SERVICE_PAGE} from "../../../../constants/Constant";
import {businessType} from "../../../../constants/businessType";
import BusinessPermissions from "../../sections/business_and_service/BusinessPermissions";

class SingleServiceAccountHome extends Component {
    CUSTOM_PAGE = "Custom Webpage";
    SERVICE_TRACKING = "Service Tracking";

    state = {
        business: {},
        sections: [
            {
                name: "Business/Service",
                tabs: [{
                    name: "Service setting",
                    icon: "users",
                    component: BusinessAndServiceSettings,
                    link: MY_SINGLE_SERVICE_SETTINGS_URL
                },{
                    name: "Members and permission",
                    icon: "users",
                    component: BusinessPermissions,
                    link: MY_SINGLE_SERVICE_PERMISSION_URL
                }],
                showOnApproved: false
            },
            {
                name: this.CUSTOM_PAGE,
                tabs: [{
                    name: "Manage pages/tabs",
                    icon: "cogs",
                    sub_tabs: [{
                        name: "Create new page",
                        icon: "edit",
                        link: CUSTOM_PAGE_CREATE_NEW_PAGE_URL
                    }, {
                        name: "Home page",
                        icon: "home",
                        link: CUSTOM_PAGE_SINGLE_WEB_PAGE_URL.replace(COL + PAGE_ID_PARAM, HOME_PAGE)
                    }, {
                        name: "Tracking page",
                        icon: "calendar",
                        hidden: true,
                        link: CUSTOM_PAGE_SINGLE_WEB_PAGE_URL.replace(COL + PAGE_ID_PARAM, TRACKING_SERVICE_PAGE)
                    }],
                    component: CustomPageSetting,
                    link: CUSTOM_PAGE_MANAGE_URL
                },{
                    name: "Navbar settings",
                    icon: "cogs",
                    component: ManageNavbar,
                    link: MANAGE_WEBSITE_NAVBAR_SETTINGS_URL
                },{
                    name: "Footer settings",
                    icon: "cogs",
                    component: ManageFooter,
                    link: MANAGE_WEBSITE_FOOTER_SETTINGS_URL
                },{
                    name: "Page settings",
                    icon: "cogs",
                    component: ManageWebsite,
                    link: MANAGE_WEBSITE_SETTINGS_URL
                }]
            },
            {
                name: this.SERVICE_TRACKING,
                hidden: true,
                tabs: [{
                        name: "Create service",
                        icon: "cogs",
                        component: CreateNewServiceTracking,
                        link: CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL
                    },
                    {
                        name: "Show services",
                        icon: "cogs",
                        component: ShowAllServiceTracking,
                        link: SHOW_ALL_SERVICES_TRACKING_URL
                    }]
            },
            {
                name: "Exit Business/Service",
                tabs: [{
                    name: "Exit",
                    icon: "sign-out-alt",
                    link: HOME_URL,
                    nonComponent: true
                }]
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNT_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]), this.OnSuccessRequest);
    }

    OnSuccessRequest(response) {
        let localSection = this.state.sections;
        localSection.filter(section => section.name === this.SERVICE_TRACKING)[0].hidden = (response.body.businessType !== businessType.MOBILE);
        localSection.filter(section => section.name === this.CUSTOM_PAGE)[0].tabs[0].sub_tabs[2].hidden = (response.body.businessType !== businessType.MOBILE);

        this.setState({
            business: response.body,
            localSection: localSection
        });
    }

    render() {
        let section_tabs = this.state.sections.reduce((tabs, section) => tabs.concat(section.tabs), []).filter(tab => tab.nonComponent !== true);
        return (
            <div className={"flex-fill d-flex"}>
                {
                    this.state.business.approved &&
                    <ServiceTabLeftPanel title={this.state.business.businessName}
                                         businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]}
                                         sections={this.state.sections}/>

                }

                <Switch>
                    <Route path={CUSTOM_PAGE_SINGLE_WEB_PAGE_URL} exact={true} render={(props)=><CustomSinglePage key={props.location.key} {...props} />} />
                    <Route path={CUSTOM_PAGE_CREATE_NEW_PAGE_URL} exact={true} component={CustomCreateNewPage} />
                    <Route path={SHOW_SINGLE_SERVICE_TRACKING_URL} exact={true} component={ShowSingleServiceTracking} />

                    {
                        section_tabs !== undefined &&
                        section_tabs.map((tab, index) => <Route key={"tab_index_" + index} path={tab.link} component={tab.component} />)
                    }
                </Switch>
                <div>

                </div>
            </div>
        );
    }
}

export default Connector(SingleServiceAccountHome);