import React, {Component, createRef} from 'react';
import {Spinner, Form, Button, FormControl} from "react-bootstrap";
import {BackendGETRequest, BackendPOSTRequest, BackendPOSTRequestWithImage} from "../../../webrequests/BackendRequest";
import {
    GET_CUSTOM_WEB_PAGE_NAVBAR_API,
    UPLOAD_NAVBAR_LOGO_IMAGE_API
} from "../../../../constants/backend/ApiConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {Connector} from "../../../../../../redux/Connector";
import PublicNavbarComponent from "../../../../../public/components/navbar/PublicNavbarComponent";
import {ShowErrorModalFromError} from "../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {ImageUploadPreview} from "../../../general/ImageUploadPreview";
import {CreteImageForm} from "../../../../utils/FormManager";

class ManageNavbar extends Component {
    state = {
        is_loading: true,
        something_went_wrong: false,
        updating_navbar: false,
        image: {
            imageUrl: "",
            imageBlob: null,
            is_uploading_image: false,
            image_upload_progress: 0
        },
        navbar: {
            enabled: true,
            logo: "",
            enabledLogo: false,
            enabledSignIn: false,
            logoClass: "",
            navbarTabTestClass: "",
            navbarParentClass: "",
            tabs: {
                pages: []
            }
        }
    }

    constructor(props) {
        super(props);
        this.image_input = createRef();
        this.WebsiteComponent = this.WebsiteComponent.bind(this);
        this.LoaderComponent = this.LoaderComponent.bind(this);
        this.OnChangeInput = this.OnChangeInput.bind(this);
        this.OnChangeImage = this.OnChangeImage.bind(this);
        this.OnSuccessImageUpload = this.OnSuccessImageUpload.bind(this);
        this.OnFailureImageUpload = this.OnFailureImageUpload.bind(this);
        this.OnImageUploadProgress = this.OnImageUploadProgress.bind(this);
        this.StartImageUpload = this.StartImageUpload.bind(this);
        this.OnClickUpdateButton = this.OnClickUpdateButton.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_CUSTOM_WEB_PAGE_NAVBAR_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),
            (response) => this.setState({
                navbar: response.body, is_loading:false
            }), (error) => {
                this.setState({something_went_wrong: true, is_loading: false});
                ShowErrorModalFromError(this.props, "Navbar", error);
            });
    }

    OnClickUpdateButton() {
        this.setState({
            updating_navbar: true
        });

        BackendPOSTRequest(this.props, GET_CUSTOM_WEB_PAGE_NAVBAR_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),this.state.navbar,
            (response) => {
                this.setState({
                    updating_navbar: false
                });
            }, (error) => {
                this.setState({
                    updating_navbar: false
                });
                ShowErrorModalFromError(this.props, "Navbar", error);
            });
    }

    OnChangeInput(event) {
        if(event.target.type === "checkbox") {
            let navbar = this.state.navbar;
            switch (event.target.name) {
                case "enabled":
                    navbar.enabled = event.target.checked;
                    this.setState({
                        navbar: navbar
                    });
                    break;
                case "enabledLogo":
                    navbar.enabledLogo = event.target.checked;
                    this.setState({
                        navbar: navbar
                    });
                    break;
                case "enabledSignIn":
                    navbar.enabledSignIn = event.target.checked;
                    this.setState({
                        navbar: navbar
                    });
                    break;
                default:
                    break;
            }
        }
    }

    OnChangeImage(event) {
        if (event.target.files.length <= 0) return;
        let image = this.state.image;
        image.imageUrl = URL.createObjectURL(event.target.files[0]);
        image.imageBlob = event.target.files[0];

        this.setState({
            image: image
        });

        this.StartImageUpload(event.target.files[0]);
    }

    StartImageUpload(imageBlob) {
        this.setState({
            is_uploading_image: true,
            image_upload_progress: 0
        });

        let serviceId = this.props.match.params[SERVICE_ACCOUNT_PARAM];
        let formData = CreteImageForm("serviceId", serviceId, imageBlob);
        BackendPOSTRequestWithImage(this.props, UPLOAD_NAVBAR_LOGO_IMAGE_API.replace(SERVICE_ACCOUNT_PARAM, serviceId), formData, this.OnSuccessImageUpload, this.OnFailureImageUpload, this.OnImageUploadProgress);
    }

    OnSuccessImageUpload(response) {
        let image = this.state.image;
        image.is_uploading_image = false;
        image.imageUrl = "";
        image.imageBlob = null;

        let navbar = this.state.navbar;
        navbar.logo = response.body["logo"];

        this.setState({
            image: image,
            navbar: navbar
        });
    }

    OnFailureImageUpload(error) {
        let image = this.state.image;
        image.image_upload_progress = false;

        this.setState({
            image: image
        });

        ShowErrorModalFromError(this.props, "Image Upload Error", error);
    }

    OnImageUploadProgress(percent) {
        this.setState({
            image_upload_progress: percent
        });
    }

    LoaderComponent() {
        return <div className={"d-flex flex-column justify-content-center align-items-center h-100 text-secondary"}>
            <Spinner animation={"border"} />
            Loading navbar settings
        </div>;
    }

    WebsiteComponent() {
        if(this.state.is_loading) {
            return <this.LoaderComponent />
        }

        if(this.state.something_went_wrong) {
            return <div className={"d-flex justify-content-center align-items-center h-100 text-muted"}>
                Something went wrong
            </div>
        }

        return <div>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Website Navbar Settings
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage website navbar settings
                </p>
            </div>
            <div className={"bg-white rounded shadow-sm p-2 mt-2"}>
                <div className={"d-flex"}>
                    <div className={"me-1 bg-light border d-flex justify-content-center text-secondary small align-items-center cursor-pointer"} onClick={() => {this.image_input.current.click()}} style={{height: 100}}>
                        {
                            this.state.navbar.logo ? <img src={this.state.navbar.logo} alt={"logo"} className={"img-object-fit-contain"} />: <div className={"px-5"}>+ LOGO</div>
                        }
                    </div>
                    <div style={{height: 100}}>
                        <ImageUploadPreview imageUrl={this.state.image.imageUrl}
                                        imageBlob={this.state.image.imageBlob}
                                        showProgress={this.state.image.is_uploading_image}
                                        progress={this.state.image.image_upload_progress}
                                        retryUploadCallback={this.StartImageUpload} />
                    </div>
                </div>
                <p className={"text-muted small"}>Click image to select/update the new logo</p>

                <FormControl ref={this.image_input} type={"file"} name={"image"} className={"visually-hidden"} onChange={this.OnChangeImage} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} defaultChecked={this.state.navbar.enabled} onChange={this.OnChangeInput} label={"Enable Navbar"} name={"enabled"} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} defaultChecked={this.state.navbar.enabledLogo} onChange={this.OnChangeInput} label={"Show logo"} name={"enabledLogo"} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} defaultChecked={this.state.navbar.enabledSignIn} onChange={this.OnChangeInput} label={"Show sign in"} name={"enabledSignIn"} />

                <div className={"px-1"}>
                    <Button className={"my-2 shadow-none"} onClick={this.OnClickUpdateButton} disabled={this.state.updating_navbar}>
                        {
                            this.state.updating_navbar &&
                            <Spinner animation={"border"} size={"sm"} className={"me-1"} />
                        }
                        Update
                    </Button>
                </div>
            </div>
            {
                this.state.navbar.enabled &&
                <div className={"py-4"}>
                    <h6 className={"fw-bold"}>
                        Preview Navbar
                    </h6>
                    <div className={"position-relative"}>
                        <PublicNavbarComponent navbar={this.state.navbar} showSignIn={true} />
                        <div className={"bg-secondary"} style={{height: 200}}/>
                        <div className={"position-absolute top-0 start-0 h-100 w-100"} style={{zIndex: 1111}}/>
                    </div>
                </div>
            }
        </div>;
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                <this.WebsiteComponent />
            </div>
        );
    }
}

export default Connector(ManageNavbar);