import React, {Component} from 'react';
import {Connector} from "../../../../redux/Connector";
import {BackendGETRequest} from "../../../itsmyweb/components/webrequests/BackendRequest";
import {
    ShowErrorModalFromError
} from "../../../itsmyweb/components/itsmyweb/modals/centermodal/CenterModalScreenManager";
import {PUBLIC_GET_WEB_PAGE_API} from "../../../itsmyweb/constants/backend/ApiPublicConstant";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM} from "../../../itsmyweb/constants/ParamConstant";
import {GetServiceAccountNameFromDomain} from "../../../itsmyweb/utils/ItsMyWebUtil";
import {WidgetRenderer} from "./WidgetRenderer";

class PublicWebPage extends Component {
    serviceAccountName = GetServiceAccountNameFromDomain();

    state = {
        is_loading: true,
        something_went_wrong: false,
        page: {
            widgets: []
        }
    }

    componentDidMount() {
        BackendGETRequest(this.props, PUBLIC_GET_WEB_PAGE_API
            .replace(SERVICE_ACCOUNT_PARAM, this.serviceAccountName)
            .replace(PAGE_ID_PARAM, this.props.match.params[PAGE_ID_PARAM]), (response) => this.setState({page: response.body, is_loading: false}), (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true
            });

            ShowErrorModalFromError(this.props, "", error);
        });
    }

    render() {
        return (
            <div className={"d-flex flex-column"}>
                <WidgetRenderer widgets={this.state.page.widgets} />
            </div>
        );
    }
}

export default Connector(PublicWebPage);