import {SetErrorModalScreen} from "../../../../../../redux/ConnectorWrapper";
import {DEFAULT_ERROR_MSG} from "../../../../constants/Constant";
import {isNullOrUndefinedObject} from "../../../../utils/validators/Validator";

export function HideErrorModal(props) {
    SetErrorModalScreen(props,{});
}

export function ShowOkButtonErrorModal(props, label, content) {
    SetErrorModalScreen(props,{
        show:true, image: "", label: label, content: content, okButton: true
    });
}

export function ShowSuccessErrorModal(props, label, content) {
    SetErrorModalScreen(props, {show:true, label: label, content: content, okButton: true});
}

function getMessageFromError(error) {
    if(!isNullOrUndefinedObject(error.data) && error.data.message) {
        return error.data.message;
    }

    if(!isNullOrUndefinedObject(error.response?.data) && error.response?.data.message) {
        return error.response?.data.message;
    }

    if(error.message) {
        return error.message;
    }

    return DEFAULT_ERROR_MSG;
}

export function ShowErrorModalFromError(props, label, error) {
    console.log(error.data);
    ShowOkButtonErrorModal(props, label, getMessageFromError(error));
}
