import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import contact_us from "../../../statics/images/contact_us.png";

class ContactUs extends Component {
    render() {
        return (
            <div className={"bg-white"}>
                <Container className={"py-4"}>
                    <h1 className={"fs_heading fw-bold m-0 px-1"}>
                        Contact Us
                    </h1>
                    <p className={"text-secondary fw-bold px-1"}>
                        If you have any issues, concerns feel free to contact Its My Web
                    </p>
                    <div className={"d-flex flex-wrap"}>
                        <a href="mailto:support@itsmyweb.in" rel="noreferrer" target="_blank"
                           className={"no-text-decor col-md-3"}>
                            <div className={"bg-light rounded p-2 py-4 m-1"}>
                                <p className={"my-1 text-center fs_75c_10"}>
                                    <FontAwesomeIcon icon={"envelope"} size={"3x"} className={"me-1"}/>
                                    <div>support@itsmyweb.in</div>
                                </p>
                            </div>
                        </a>
                        <a href={"https://www.facebook.com/itsmywebsite"} rel="noreferrer" target="_blank"
                           className={"no-text-decor col-md-3"}>
                            <div className={"bg-light rounded p-2 py-4 m-1"}>
                                <p className={"my-1 text-center fs_75c_10"}>
                                    <FontAwesomeIcon icon={["fab", "facebook"]} size={"3x"} className={"me-1"}/>
                                    <div>
                                        Facebook
                                    </div>
                                </p>
                            </div>
                        </a>
                        <a href={"https://www.instagram.com/itsmyweb.in"} rel="noreferrer" target="_blank"
                           className={"no-text-decor col-md-3"}>
                            <div className={"bg-light rounded p-2 py-4 m-1"}>
                                <p className={"my-1 text-center fs_75c_10"}>
                                    <FontAwesomeIcon icon={["fab", "instagram"]} size={"3x"} className={"me-1"}/>
                                    <div>
                                        Instagram
                                    </div>
                                </p>
                            </div>
                        </a>
                        <a href={"tel:+918142022097"} rel="noreferrer" target="_blank"
                           className={"no-text-decor col-md-3"}>
                            <div className={"bg-light rounded p-2 py-4 m-1"}>
                                <p className={"my-1 text-center fs_75c_10"}>
                                    <FontAwesomeIcon icon={"phone"} size={"3x"} className={"me-1"}/>
                                    <div>
                                        +91 8142022097
                                    </div>
                                </p>
                            </div>
                        </a>
                        <a href={"https://wa.me/918142022097?text=Hi%20ItsMyWeb"} rel="noreferrer" target="_blank"
                           className={"no-text-decor col-md-3"}>
                            <div className={"bg-light rounded p-2 py-4 m-1"}>
                                <p className={"my-1 text-center fs_75c_10"}>
                                    <FontAwesomeIcon icon={["fab", "whatsapp"]} size={"3x"} className={"me-1"}/>
                                    <div>
                                        +91 8142022097
                                    </div>
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className={"d-flex py-4"}>
                        <div className={"text-start"} style={{height: 400}} >
                            <img src={contact_us} alt={"contact_us"} className={"img-object-fit-contain"} />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default ContactUs;