import {Component} from 'react';
import {BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {
    DELETE_CUSTOM_WEB_PAGE_API,
    DELETE_CUSTOM_WEB_PAGE_WIDGET_API, UPDATE_CUSTOM_WEB_PAGE_WIDGET_API
} from "../../../../../constants/backend/ApiConstant";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {isEmptyString, isNullOrUndefinedObject} from "../../../../../utils/validators/Validator";
import {v4} from "uuid";
import {GetAlphaStringWithoutSpace, GetAlphaStringWithSpace} from "../../../../../utils/ItsMyWebUtil";
import {SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {CUSTOM_PAGE_MANAGE_URL} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {IMAGE_GALLERY, IMAGE_WITH_TEXT} from "../../../../../../public/widgets/WidgetKey";

class WebPageFunctions extends Component {

    constructor(props) {
        super(props);
        this.HideWidgetPicker = this.HideWidgetPicker.bind(this);
        this.ShowWidgetPicker = this.ShowWidgetPicker.bind(this);
        this.OnClickWidget = this.OnClickWidget.bind(this);
        this.OnDragEnd = this.OnDragEnd.bind(this);
        this.OnClickEditWidget = this.OnClickEditWidget.bind(this);
        this.OnClickDeleteWidget = this.OnClickDeleteWidget.bind(this);
        this.OnSuccessDeleteWidget = this.OnSuccessDeleteWidget.bind(this);
        this.OnClickSaveWidget = this.OnClickSaveWidget.bind(this);
        this.OnSuccessUpdateWidget = this.OnSuccessUpdateWidget.bind(this);
        this.OnFailureUpdateWidget = this.OnFailureUpdateWidget.bind(this);
        this.OnClickDeletePageButton = this.OnClickDeletePageButton.bind(this);
        this.OnSuccessDeletePage = this.OnSuccessDeletePage.bind(this);
        this.OnShortNameUpdate = this.OnShortNameUpdate.bind(this);
        this.OnPageNameUpdate = this.OnPageNameUpdate.bind(this);
        this.OnSelectDropdownIcon = this.OnSelectDropdownIcon.bind(this);
        this.OnUpdateEditField = this.OnUpdateEditField.bind(this);
        this.OnChangeImage = this.OnChangeImage.bind(this);
    }

    HideWidgetPicker() {
        this.setState({
            show_widget_picker: false,
            active_widget_edit_index: null
        });
    }

    ShowWidgetPicker() {
        this.setState({
            show_widget_picker: true
        });
    }

    OnClickWidget(data) {
        let localPage = this.props.page;
        if(localPage.widgets === undefined) {
            localPage.widgets = [];
        }

        localPage.widgets.push(data);
        this.HideWidgetPicker();
    }

    OnDragEnd(droppableResult) {
        if(droppableResult.destination === null) {
            return;
        }

        if(droppableResult.source.droppableId === "widget_items_d" &&
            droppableResult.destination.droppableId === "widget_items_d") {
            let element = this.props.page.widgets.splice(droppableResult.source.index,1)[0];
            this.props.page.widgets.splice(droppableResult.destination.index, 0, element);
        }

        this.HideWidgetPicker();
    }

    OnClickEditWidget(index) {
        this.setState({
            active_widget_edit_index: index
        });
    }

    OnClickDeleteWidget(index) {
        if(this.props.page.widgets[index].id === undefined) {
            this.props.page.widgets.splice(index, 1);
            this.setState({});
            return;
        }

        let formJsonData = {
            businessId: this.props.serviceId,
            pageId: this.props.pageId,
            widgets: [{
                "id": this.props.page.widgets[index].id
            }]
        };

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, DELETE_CUSTOM_WEB_PAGE_WIDGET_API, formJsonData, this.OnSuccessDeleteWidget, (error) => {
            this.setState({
                is_loading: false
            });
            ShowErrorModalFromError(this.props, "Web page error", error);
        });
    }

    OnSuccessDeleteWidget(response) {
        let localPage = this.props.page;
        let index = localPage.widgets.findIndex(widget => widget.id === response.body.id);
        if(index > -1) {
            localPage.widgets.splice(index, 1);
        }

        this.setState({
            is_loading: false
        });
    }

    OnClickSaveWidget() {
        this.ToggleMobileView();
        this.setState({
            is_loading: true
        });

        let widgetToSaveData = {
            businessId: this.props.serviceId,
            pageId: this.props.pageId
        };

        widgetToSaveData.widgets = this.props.page.widgets.map(widget => {
           if(isNullOrUndefinedObject(widget.id)) {
               widget.id = v4().replaceAll("-", "");
           }

           if(this.GetWidgetHash(widget) === widget.attribute?.hash) {
               return {id:widget.id};
           }

           return widget;
        });

        BackendPOSTRequest(this.props, UPDATE_CUSTOM_WEB_PAGE_WIDGET_API, widgetToSaveData, this.OnSuccessUpdateWidget, this.OnFailureUpdateWidget);
    }

    OnSuccessUpdateWidget(response) {
        this.SetupInitialHashes();
        this.setState({
            is_loading: false
        });
    }

    OnFailureUpdateWidget(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Custom web page error", error);
    }

    OnClickDeletePageButton() {
        let formJsonData = {
            "businessId": this.props.serviceId,
            "id": this.props.pageId
        };

        BackendPOSTRequest(this.props, DELETE_CUSTOM_WEB_PAGE_API, formJsonData, this.OnSuccessDeletePage, (error) => ShowErrorModalFromError(this.props, "Web page error", error));
    }

    OnSuccessDeletePage(response) {
        window.location.href = CUSTOM_PAGE_MANAGE_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.serviceId);
    }

    OnShortNameUpdate(event) {
        let localPage = this.props.page;
        localPage.pageShortName = GetAlphaStringWithoutSpace(event.target.value);

        this.setState({
            is_loading: false
        });
    }

    OnPageNameUpdate(event) {
        let localPage = this.props.page;
        localPage.pageName = GetAlphaStringWithSpace(event.target.value);

        this.setState({
            is_loading: false
        });
    }

    OnSelectDropdownIcon(icon) {
        let localPage = this.props.page;
        localPage.iconName = icon;

        this.setState({
            is_loading: false
        });
    }

    OnUpdateEditField(index, name, value) {
        let localPage = this.props.page;
        localPage.widgets[index][name] = value;

        this.setState({
            is_loading: false
        });
    }

    OnChangeImage(index, imageUrl, isUploaded) {
        let localPage = this.props.page;
        let widget = localPage.widgets[index];

        if(widget.widgetKey === IMAGE_WITH_TEXT) {
            widget.image = imageUrl;
        } else if(widget.widgetKey === IMAGE_GALLERY) {
            if(isNullOrUndefinedObject(widget.images)) {
                widget.images = [];
            }

            if(this.state.image_added_to_gallery === true && isEmptyString(imageUrl)) {
                widget.images.pop();
                this.setState({
                    image_added_to_gallery: false
                });
            } else if(this.state.image_added_to_gallery === true) {
                widget.images[widget.images.length -1] = imageUrl;
                if(isUploaded === true) {
                    this.setState({
                        image_added_to_gallery: false
                    });
                }
            } else {
                widget.images.push(imageUrl);
                this.setState({
                    image_added_to_gallery: true
                });
            }
        }

        this.setState({
            is_loading: false
        });
    }
}

export default WebPageFunctions;