import React, {Component} from 'react';
import OrderItemCol from "./OrderItemCol";
import {Button, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Orders extends Component {
    state = {
        orders: []
    }

    render() {
        return (
            <div className={"flex-fill flex-column d-flex"}>
                {
                    this.state.orders.length > 0 ? <div>
                        <Row noGutters>
                            {
                                this.state.orders.map(order => <OrderItemCol name={order.name} content={order.content}
                                                                             image={null}/>)
                            }
                        </Row>
                    </div> : <div className={"d-flex flex-column justify-content-center flex-fill align-items-center w-100 h-100"}>
                        <p className={"text-secondary"}>
                            No orders found!
                        </p>
                        <Button as={Link} to={HOME_URL}>
                            <FontAwesomeIcon icon={"home"} /> Home
                        </Button>
                    </div>
                }

            </div>
        );
    }
}

export default Orders;