import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {DragDropContext} from "react-beautiful-dnd";
import {SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import SectionComponent from "./SectionComponent";
import {DEFAULT_ERROR_MSG} from "../../../../../constants/Constant";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {
    GET_ALL_CUSTOM_WEB_PAGES_API, UPDATE_ALL_CUSTOM_WEB_PAGES_API,
} from "../../../../../constants/backend/ApiConstant";
import {Button, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import hash from "object-hash";

class CustomPageSetting extends Component {
    state = {
        website: {
            tabs: [],
            pages: []
        },
        website_hash: null,
        is_loading: false
    }

    constructor(props) {
        super(props);
        this.OnDragEnd = this.OnDragEnd.bind(this);
        this.OnSuccessGetPages = this.OnSuccessGetPages.bind(this);
        this.OnFailureGetPages = this.OnFailureGetPages.bind(this);
        this.OnClickSaveCustomPageAndTabs = this.OnClickSaveCustomPageAndTabs.bind(this);
    }

    componentDidMount() {
        this.setState({
           is_loading: true
        });

        BackendGETRequest(this.props, GET_ALL_CUSTOM_WEB_PAGES_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),
            this.OnSuccessGetPages, this.OnFailureGetPages);
    }

    OnDragEnd(droppableResult) {
        if(droppableResult.destination === null) {
            return;
        }

        let element;
        if(droppableResult.source.droppableId === "tabs") {
            element = this.state.website.tabs.splice(droppableResult.source.index,1)[0];
        } else {
            element = this.state.website.pages.splice(droppableResult.source.index,1)[0];
        }


        if(droppableResult.destination.droppableId === "tabs") {
            this.state.website.tabs.splice(droppableResult.destination.index, 0, element);
        } else {
            this.state.website.pages.splice(droppableResult.destination.index, 0, element);
        }

        this.setState({
           website: this.state.website
        });
    }

    OnSuccessGetPages(response) {
        this.setState({
            is_loading: false,
            website: response.body,
            website_hash: hash.sha1(response.body)
        });
    }

    OnFailureGetPages(error) {
        this.setState({
            is_loading: false,
            something_went_wrong: true
        });

        ShowErrorModalFromError(this.props, "Custom Page", error);
    }

    OnClickSaveCustomPageAndTabs() {
        this.setState({
            is_loading: true
        });

        let requestBody = {
            tabs: this.state.website.tabs,
            pages: this.state.website.pages
        }

        BackendPOSTRequest(this.props, UPDATE_ALL_CUSTOM_WEB_PAGES_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]),
            requestBody , (response) => this.setState({website_hash: hash.sha1(this.state.website), is_loading: false}), (error) => {
            this.setState({
                is_loading: false
            });
            ShowErrorModalFromError(this.props, "Custom Page", error);
        });
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                {
                    this.state.something_went_wrong &&
                    <div className={"d-flex flex-fill justify-content-center align-items-center text-muted"}>
                        {DEFAULT_ERROR_MSG}
                    </div>
                }
                {
                    !this.state.something_went_wrong &&
                    <div>
                        <div className={"bg-white rounded shadow-sm p-2"}>
                            <h4>
                                Custom Page Settings
                            </h4>
                            <p className={"text-secondary m-0"}>
                                Manage custom page settings, you can drag and drop in between pages and tabs to rearrange them.
                            </p>
                        </div>

                        <DragDropContext onDragEnd={this.OnDragEnd}>
                            <SectionComponent sections={this.state.website.tabs} droppableId={"tabs"} prefix={"tab_"} businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]} title={"Manage Tabs"} />
                            <SectionComponent sections={this.state.website.pages} droppableId={"pages"} prefix={"page_"} businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]} title={"Manage Pages"} />
                        </DragDropContext>

                        {
                            this.state.website_hash !== hash.sha1(this.state.website) &&
                            <div className={"p-2 text-center"}>
                                <Button variant={"success"} className={"me-2 rounded-pill shadow-none"}
                                        onClick={this.OnClickSaveCustomPageAndTabs} disabled={this.state.is_loading}>
                                    {this.state.is_loading ? <Spinner animation={"border"} size={"sm"}/> :
                                        <FontAwesomeIcon icon={"check-circle"}/>} Save updates
                                </Button>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default Connector(CustomPageSetting);