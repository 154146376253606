import {GET_ADDRESS_API, CREATE_ADDRESS_API, UPDATE_ADDRESS_API, DELETE_ADDRESS_API} from "../../constants/backend/ApiConstant";
import {BackendGETRequest, BackendPOSTRequest} from "./BackendRequest";

export function GetAddress(props, SuccessCallback, ErrorCallback) {
    BackendGETRequest(props, GET_ADDRESS_API, SuccessCallback, ErrorCallback);
}

export function CreateNewAddress(props, address, SuccessCallback, ErrorCallback) {
    UpdateCreateOrDeleteAddress(props, address, CREATE_ADDRESS_API, SuccessCallback, ErrorCallback);
}

export function UpdateAddress(props, address, SuccessCallback, ErrorCallback) {
    UpdateCreateOrDeleteAddress(props, address, UPDATE_ADDRESS_API, SuccessCallback, ErrorCallback);
}

export function DeleteAddress(props, address, SuccessCallback, ErrorCallback) {
    UpdateCreateOrDeleteAddress(props, address, DELETE_ADDRESS_API, SuccessCallback, ErrorCallback);
}

function UpdateCreateOrDeleteAddress(props, address, url, SuccessCallback, ErrorCallback) {
    BackendPOSTRequest(props, url, address, SuccessCallback, ErrorCallback);
}