import {Form, FormControl} from "react-bootstrap";
import React from "react";

export function AddressSection({footer, OnChangeInput}) {
    return <div className={"col-md-6 p-1"}>
        <div className={"shadow-sm p-2 rounded border h-100"}>
            <h5 className={"fw-bold"}>Address {footer.name}</h5>
            <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Enable"}
                        checked={footer.address.enabled} name={"address.enabled"} onChange={OnChangeInput} />
            <FormControl className={"shadow-none"} value={footer.address.address}
                         name={"address.address"}
                         onChange={OnChangeInput}
                         as={"textarea"} rows={6} />
        </div>
    </div>
}