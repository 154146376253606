import React, {Component} from "react";

import {Container, Nav, Navbar} from "react-bootstrap";
import {COL, HOME_URL, LOGIN_PAGE_URL} from "../../../itsmyweb/constants/application/ApplicationUrl";
import {PAGE_ID_PARAM} from "../../../itsmyweb/constants/ParamConstant";
import {PUBLIC_WEB_PAGE_URL} from "../../urls/PublicUrls";
import PublicSidePanel from "./sidepanel/PublicSidePanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PublicNavbarComponent extends Component {
    state = {
        showSidePanel: false
    }

    constructor(props) {
        super(props);
        this.OnClickSidePanelShow = this.OnClickSidePanelShow.bind(this);
        this.SectionLinkProvider = this.SectionLinkProvider.bind(this);
    }

    OnClickSidePanelShow() {
        this.setState({
            showSidePanel: !this.state.showSidePanel
        });
    }

    SectionLinkProvider() {
        let section = [{title: "", tabs: []}];
        this.props.navbar.tabs.pages.forEach(page => {
            section[0].tabs.push({
                name: page.pageName,
                link: PUBLIC_WEB_PAGE_URL.replace(COL + PAGE_ID_PARAM, page.id)
            });
        });
        return section;
    }

    render() {

        return (
            <>
                <Navbar bg="light" expand="lg" fixed={"top"} sticky={"top"} className={"shadow-sm"}>
                    <Container className={this.props.navbar.navbarParentClass}>
                        <div className={"d-flex"}>
                            <div className={"d-flex align-items-center"}>
                                <div className={"me-2 px-1 show-on-small-screen cursor-pointer"} onClick={this.OnClickSidePanelShow}>
                                    <FontAwesomeIcon icon={"bars"} color={"black"} />
                                </div>
                            </div>
                            {
                                this.props.navbar.enabledLogo &&
                                this.props.navbar.logo &&
                                <Navbar.Brand href={HOME_URL} className={"font-weight-bold"}>
                                    <img src={this.props.navbar.logo} alt={"logo"} className={this.props.navbar.logoClass}
                                         style={{height: 34}}/>
                                </Navbar.Brand>
                            }
                            {
                                this.props.navbar.enabledLogoName &&
                                this.props.navbar.logoName &&
                                <Navbar.Brand href={HOME_URL} className={"fw-bold"}>
                                    <div>
                                        <FontAwesomeIcon icon={"home"}/> {this.props.navbar.logoName}
                                    </div>
                                </Navbar.Brand>
                            }
                        </div>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="m-auto">
                                {
                                    this.props.navbar.tabs.pages.map((it, index) => {
                                        return <Nav.Link href={PUBLIC_WEB_PAGE_URL.replace(COL + PAGE_ID_PARAM, it.id)}
                                                         target={it.new_tab && "_blank"} key={"navbar_menu_" + index}
                                                         className={"nav-link " + this.props.navbar.navbarTabTestClass}>{it.pageShortName}</Nav.Link>;
                                    })
                                }
                            </Nav>
                            {
                                this.props.showSignIn &&
                                this.props.navbar.enabledSignIn &&
                                <Nav>
                                    <Nav.Link href={LOGIN_PAGE_URL}>
                                        Sign In
                                    </Nav.Link>
                                </Nav>
                            }
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <PublicSidePanel show={this.state.showSidePanel} onHide={this.OnClickSidePanelShow} sections={this.SectionLinkProvider()} showSignIn={this.props.navbar.enabledSignIn} />
            </>
        );
    }
}

export default PublicNavbarComponent;

