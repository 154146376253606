import React from 'react';
import {HEADING, IMAGE_GALLERY, IMAGE_WITH_TEXT, TEXT_WITH_PARA} from "../WidgetKey";
import HeaderWidgetEdit from "./HeaderWidgetEdit";
import TextWithParaWidgetEdit from "./TextWithParaWidgetEdit";
import ImageWithTextWidgetEdit from "./ImageWithTextWidgetEdit";
import ImageGalleryWidgetEdit from "./ImageGalleryWidgetEdit";

export function WidgetEditProvider(props, index, businessId, pageId, dataComponent, OnUpdateEditField, OnClickDone, OnChangeImage) {
    switch (dataComponent.widgetKey) {
        case HEADING:
            return <HeaderWidgetEdit dataComponent={dataComponent}
                                     OnUpdateEditField={OnUpdateEditField}
                                     onClickDone={OnClickDone}
                                     index={index}
                                     businessId={businessId}
                                     pageId={pageId}
                                     {...props} />;
        case TEXT_WITH_PARA:
            return <TextWithParaWidgetEdit dataComponent={dataComponent}
                                           OnUpdateEditField={OnUpdateEditField}
                                           onClickDone={OnClickDone}
                                           index={index}
                                           businessId={businessId}
                                           pageId={pageId}
                                           {...props} />;
        case IMAGE_WITH_TEXT:
            return <ImageWithTextWidgetEdit dataComponent={dataComponent}
                                            OnUpdateEditField={OnUpdateEditField}
                                            onClickDone={OnClickDone}
                                            onChangeImage={OnChangeImage}
                                            index={index}
                                            businessId={businessId}
                                            pageId={pageId}
                                            {...props} />;
        case IMAGE_GALLERY:
            return <ImageGalleryWidgetEdit dataComponent={dataComponent}
                                           OnUpdateEditField={OnUpdateEditField}
                                           onClickDone={OnClickDone}
                                           onChangeImage={OnChangeImage}
                                           index={index}
                                           businessId={businessId}
                                           pageId={pageId}
                                           {...props} />;
        default:
            return <HeaderWidgetEdit dataComponent={dataComponent}
                                     OnUpdateEditField={OnUpdateEditField}
                                     onClickDone={OnClickDone}
                                     index={index}
                                     businessId={businessId}
                                     pageId={pageId}
                                     {...props} />;
    }
}