import React, {Component} from 'react';

class TextParaWidget extends Component {
    render() {
        return (
            <section className={""}>
                <div className={this.props.parentClassName}>
                    <p className={this.props.textClassName}>
                        {this.props.content}
                    </p>
                </div>
            </section>
        );
    }
}

export default TextParaWidget;