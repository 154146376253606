import React, {Component} from 'react';
import {Spinner} from "react-bootstrap";

class Loader extends Component {
    render() {
        return (
            <div className={"d-flex flex-column flex-fill align-items-center justify-content-center p-5"}>
                <Spinner animation={"border"} />
                <b className={"text-muted"}>{this.props.label}</b>
            </div>
        );
    }
}

export default Loader;