import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {Connector} from "../../../../../../redux/Connector";
import Loader from "../../../itsmyweb/loader/Loader";
import {
    CREATE_SERVICE_ACCOUNT_URL, MY_SINGLE_SERVICE_SETTINGS_URL
} from "../../../../constants/application/account/services/ServiceAccountUrls";
import {PARAM_SEPARATOR} from "../../../../constants/application/ApplicationUrl";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNTS_API} from "../../../../constants/backend/ApiConstant";
import {ServiceAccount} from "./ServiceAccountCard";
import {FAQBlock} from "../../../general/FAQBlock";
import {DEFAULT_ERROR_MSG} from "../../../../constants/Constant";

class MyServiceAccountHome extends Component {

    state = {
        is_loading_service_accounts: true,
        something_went_wrong: false,
        service_accounts: [],
        faqs: [
            {
                question: "What is a service account?",
                answer: "Service or business account is to grow or connect to your customers and manage different services like product delivery, service management, statistics etc."
            },
            {
                question: "Who can create service account?",
                answer: "Service account is for the business, local retailers or service providers and want their service or business to became online."
            }
        ]
    }

    constructor(props) {
        super(props);
        this.onGetServiceAccountSuccess = this.onGetServiceAccountSuccess.bind(this);
        this.onGetServiceAccountFailure = this.onGetServiceAccountFailure.bind(this);
        this.ServiceAccountComponent = this.ServiceAccountComponent.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNTS_API, this.onGetServiceAccountSuccess, this.onGetServiceAccountFailure);
    }

    onGetServiceAccountSuccess(serviceAccounts) {
        this.setState({
            is_loading_service_accounts: false,
            service_accounts: serviceAccounts.body["businessAccountResponseList"]
        });
    }

    onGetServiceAccountFailure() {
        this.setState({
            is_loading_service_accounts: false,
            something_went_wrong: true
        });
    }

    ServiceAccountComponent() {
        if(this.state.is_loading_service_accounts) {
            return <div className={"flex-grow-1 d-flex align-items-center"}>
                <Loader label={"Service Accounts"}/>
            </div>
        } else if(this.state.something_went_wrong) {
            return  <div className={"flex-grow-1 d-flex text-muted justify-content-center align-items-center"}>
                {DEFAULT_ERROR_MSG}
            </div>
        }

        return <div className={"d-flex flex-column justify-content-center flex-grow-1"}>
            <Row className={"g-0 justify-content-center"}>
                {
                    this.state.service_accounts.map(account => {
                        return <Col md={3} className={"p-1 no-text-decor"} as={Link}
                                    to={MY_SINGLE_SERVICE_SETTINGS_URL.replace(PARAM_SEPARATOR + SERVICE_ACCOUNT_PARAM, "/" + account.id)}>
                            <ServiceAccount name={account.businessName} type={account.businessType} approved={account.approved} />
                        </Col>;
                    })
                }

                <Col md={3} className={"p-1 no-text-decor"} as={Link}
                     to={CREATE_SERVICE_ACCOUNT_URL} >
                    <div
                        className={"h-100 p-2 border bg-light hover-shadow rounded fw-bold d-flex flex-column align-items-center justify-content-center"}>
                        <div>Create a new</div>
                        <div className={"text-secondary"}>service account</div>
                    </div>
                </Col>
            </Row>
        </div>
    }

    render() {
        return (
            <div>
                <div className={"p-2 d-flex flex-column"} style={{minHeight: "100vh"}}>
                    <this.ServiceAccountComponent />
                    <FAQBlock title={"FAQ"} faqs={this.state.faqs} />
                </div>
            </div>
        );
    }
}

export default Connector(MyServiceAccountHome);