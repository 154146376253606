import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {Button, Accordion, Badge, Spinner, Form, InputGroup, FormControl, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
    CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL, SHOW_ALL_SERVICES_TRACKING_URL,
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {SERVICE_ACCOUNT_PARAM, TRACKING_ID_PARAM} from "../../../../../constants/ParamConstant";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import Loader from "../../../../itsmyweb/loader/Loader";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {DEFAULT_ERROR_MSG} from "../../../../../constants/Constant";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {
    GET_SINGLE_SERVICE_TRACKING_API, UPDATE_SERVICE_TRACKING_API
} from "../../../../../constants/backend/ApiConstant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TrackServiceType} from "../../../../../constants/TrackServiceType";
import hash from "object-hash";
import {isNullOrUndefinedObject} from "../../../../../utils/validators/Validator";
import {JsonFromFormEvent} from "../../../../../utils/FormManager";

class ShowSingleServiceTracking extends Component {
    state = {
        is_loading: true,
        something_went_wrong: false,
        service: {},
        edits_enabled: {
            accessoriesAttached: false
        },
        page_hash: ""
    }

    constructor(props) {
        super(props);
        this.TrackServiceListComponent = this.TrackServiceListComponent.bind(this);
        this.OnChangeSelectedServiceFilter = this.OnChangeSelectedServiceFilter.bind(this);
        this.OnEditInput = this.OnEditInput.bind(this);
        this.OnClickEditButton = this.OnClickEditButton.bind(this);
        this.OnFormSubmit = this.OnFormSubmit.bind(this);
        this.OnSuccessCreate = this.OnSuccessCreate.bind(this);
        this.OnFailureCreate = this.OnFailureCreate.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_SERVICE_TRACKING_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]).replace(TRACKING_ID_PARAM, this.props.match.params[TRACKING_ID_PARAM]), (response) => this.setState({
            is_loading: false,
            service: response.body,
            page_hash: hash.sha1(response.body)
        }), (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true
            });

            ShowErrorModalFromError(this.props, "Tracking services", error);
        });
    }

    OnEditInput(event) {
        let localService = this.state.service;
        localService[event.target.name] = event.target.value;

        this.setState({
            service: localService
        });
    }

    OnClickEditButton(event) {
        if(isNullOrUndefinedObject(event.target.value)) return;

        let localEdits = this.state.edits_enabled;
        localEdits[event.target.value] = true;
        this.setState({
            edits_enabled: localEdits
        });
    }

    OnFormSubmit(event) {
        event.preventDefault();
        let formJsonData = JsonFromFormEvent(event);
        formJsonData["businessId"] = this.props.match.params[SERVICE_ACCOUNT_PARAM];

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, UPDATE_SERVICE_TRACKING_API, formJsonData, this.OnSuccessCreate, this.OnFailureCreate);
        return false;
    }

    OnSuccessCreate(response) {
        this.setState({
            is_loading: false,
            page_hash: hash.sha1(this.state.service)
        })
    }

    OnFailureCreate(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Tracking updates", error);
    }

    OnChangeSelectedServiceFilter(event) {
        this.setState({
            selectedServiceFilter: event.target.value
        });
    }

    TrackServiceListComponent() {
        if (this.state.something_went_wrong) {
            return <div className={"d-flex flex-fill justify-content-center align-items-center text-muted"}>
                {DEFAULT_ERROR_MSG}
            </div>
        }

        if (this.state.is_loading) {
            return <Loader label={"Loading tracking services"}/>
        }

        return <div className={"d-flex flex-column flex-fill"}>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Service Tracking ({this.state.service.trackingNumber})
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage single service tracking
                </p>
            </div>

            <form onSubmit={this.OnFormSubmit}>
                <div className={"bg-light rounded g-0 my-2 flex-fill"}>
                    <FormControl name="id" defaultValue={this.state.service.id} className={"visually-hidden"} />

                    <div className={"d-flex flex-wrap p-1"}>
                        <div className={"p-1 col-md-4 flex-fill"}>
                            <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                                <Table borderless className={""}>
                                    <tr>
                                        <td className={"fw-bold"}>
                                            Bill Number
                                        </td>
                                        <td>
                                            {this.state.service.billNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"fw-bold"}>
                                            Tracking Number
                                        </td>
                                        <td>
                                            {this.state.service.trackingNumber}
                                        </td>
                                    </tr>
                                </Table>
                            </div>
                        </div>
                        <div className={"p-1 col-md-4 flex-fill"}>
                            <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                                <div className={"fw-bold text-primary"}>
                                    {this.state.service.customerName}
                                </div>
                                <div className={"small fw-bold text-muted"}>
                                    {this.state.service.customerMobile}
                                </div>
                                <div className={"small text-muted"}>
                                    {this.state.service.customerAddress}
                                </div>
                            </div>
                        </div>
                        <div className={"p-1 col-md-4 flex-fill"}>
                            <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                                <h5 className={"fw-bold"}>
                                    Estimate
                                </h5>
                                <h4>
                                    Rs. {this.state.service.estimate}
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className={"p-1"}>
                        <div className={"p-1"}>
                            <b>Update order type </b> {this.state.service.orderType}
                        </div>
                        <div className={"p-1 d-flex"}>
                            <div>
                                <InputGroup>
                                    <Button variant={"secondary"}>Status</Button>
                                    <Form.Select defaultValue={this.state.service.state} className={"shadow-none"} onChange={this.OnEditInput} name={"state"}>
                                        {
                                            Object.keys(TrackServiceType).filter(key=>key!==TrackServiceType.START).map(key => <option key={key} value={key}>{key.replaceAll("_", " ")}</option>)
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </div>
                        </div>
                    </div>

                    <div className={"p-1"}>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Description</div></Accordion.Header>
                                <Accordion.Body>
                                    {this.state.service.description}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Note</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {this.state.service.note}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Accessories taken from customer</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {
                                        this.state.service.accessoriesTaken?.split(/[\s,]+/).map(accessories => <Badge className={"me-1"}>{accessories}</Badge>)
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Extra accessories ordered/added</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {
                                        this.state.service.accessoriesAttached?.split(/[\s,]+/).map(accessories => <Badge className={"me-1"}>{accessories}</Badge>)
                                    }
                                    <div className={"py-1"}>
                                        {
                                            this.state.edits_enabled.accessoriesAttached?
                                                <Form.Control value={this.state.service.accessoriesAttached} onChange={this.OnEditInput} maxLength={100} className={"shadow-none mb-2 validate-input"}
                                                              placeholder={"Enter accessories attached eg. glass, rc"} name={"accessoriesAttached"}/>:
                                                <Button variant={"success"} className={"me-1 rounded-pill shadow-none"}
                                                        onClick={this.OnClickEditButton} value={"accessoriesAttached"}>
                                                    <FontAwesomeIcon icon={"edit"}/> Edit
                                                </Button>
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Activity Log</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className={"p-2 small"}>
                                        {this.state.service.activities?.map(activity => <div>
                                            {activity}
                                        </div>)}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    <div className={"fw-bolder"}>Chat with customer</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className={"py-2 text-center"}>
                    {
                        this.state.page_hash !== hash.sha1(this.state.service) &&
                        <div>
                            {
                                this.state.service.state === TrackServiceType.COMPLETED &&
                                <div className={"small text-muted p-2"}>
                                    Are you sure to want update ticket status to COMPLETED, it can't be undone or you'll
                                    be not able to edit.
                                </div>
                            }
                            <Button type={"submit"} variant={"success"} className={"rounded-pill shadow-none"}>
                                {this.state.is_loading ? <Spinner animation={"border"} size={"sm"}/> :
                                    <FontAwesomeIcon icon={"check-circle"}/>} Save updates
                            </Button>
                        </div>
                    }
                </div>
            </form>


            <div className={"py-2"}>
                <h6 className={"fw-bolder"}>More Links</h6>
                <div>
                    <Button variant={"outline-primary m-1"} as={Link}
                            to={CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])}>
                        Create new service
                    </Button>
                    <Button variant={"outline-primary m-1"} disabled={this.state.is_loading} as={Link} to={SHOW_ALL_SERVICES_TRACKING_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])}>
                        Show all service tracking
                    </Button>
                </div>
            </div>
        </div>
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                <this.TrackServiceListComponent/>
            </div>
        );
    }
}

export default Connector(ShowSingleServiceTracking);