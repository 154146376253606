import React, {Component} from 'react';
import {isEmptyString} from "../../../itsmyweb/utils/validators/Validator";
import {DEFAULT_IMAGE_REPLACER_URL} from "../../../itsmyweb/constants/Constant";

class ImageWithTextWidget extends Component {
    render() {
        return (
            <section>
                <div className={"d-flex flex-wrap " + this.props.parentClassName}>
                    {
                        this.props.imagePosition === "left" &&
                        <div className={this.props.imageParentClassName}>
                            {
                                <img alt={"its-my-web"} className={this.props.imageClassName} src={isEmptyString(this.props.image)?DEFAULT_IMAGE_REPLACER_URL:this.props.image}/>
                            }
                        </div>
                    }
                    <p className={this.props.textClassName} contentEditable={true}>
                        {this.props.content}
                    </p>
                    {
                        this.props.imagePosition === "right" &&
                        <div className={this.props.imageParentClassName}>
                            <img alt={"its-my-web"} className={this.props.imageClassName} src={isEmptyString(this.props.image)?DEFAULT_IMAGE_REPLACER_URL:this.props.image}/>
                        </div>
                    }
                </div>
            </section>
        );
    }
}

export default ImageWithTextWidget;