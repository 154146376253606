import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {Button, Col, Row, Form, InputGroup, FormControl} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
    CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL,
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import TrackServiceBlock from "./TrackServiceBlock";
import Loader from "../../../../itsmyweb/loader/Loader";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {DEFAULT_ERROR_MSG, SMALL_DEVICES_WIDTH_PIVOT} from "../../../../../constants/Constant";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {GET_ALL_SERVICE_TRACKING_API} from "../../../../../constants/backend/ApiConstant";
import {TrackServiceType} from "../../../../../constants/TrackServiceType";

class ShowAllServiceTracking extends Component {
    state = {
        is_loading: true,
        something_went_wrong: false,
        services: [],
        selectedServiceFilter: "ALL",
        search_query: ""
    }

    constructor(props) {
        super(props);
        this.TrackServiceListComponent = this.TrackServiceListComponent.bind(this);
        this.OnChangeSelectedServiceFilter = this.OnChangeSelectedServiceFilter.bind(this);
        this.ServiceSection = this.ServiceSection.bind(this);
        this.OnChangeSearchQuery = this.OnChangeSearchQuery.bind(this);
        this.SearchSection = this.SearchSection.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ALL_SERVICE_TRACKING_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]), (response) => this.setState({
            is_loading: false,
            services: response.body["services"]
        }), (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true
            });

            ShowErrorModalFromError(this.props, "Tracking services", error);
        });

        if(window.innerWidth < SMALL_DEVICES_WIDTH_PIVOT) {
            this.setState({
                selectedServiceFilter: TrackServiceType.WORK_IN_PROGRESS
            });
        }
    }

    OnChangeSelectedServiceFilter(event) {
        this.setState({
            selectedServiceFilter: event.target.value
        });
    }

    OnChangeSearchQuery(event) {
        this.setState({
            search_query: event.target.value
        });
    }

    ServiceSection({title, filterCallback, showCreateNew}) {
        if(filterCallback(this.state.selectedServiceFilter) || this.state.selectedServiceFilter === "ALL") {
            return <Col className={"p-1"}>
                <div className={"p-2 rounded bg-light-secondary-1 h-100"}>
                    <div className={"border-bottom fw-bold d-inline-block text-truncate w-100"}>
                        {title.replaceAll("_", " ")}
                    </div>
                    <div className={""}>
                        {
                            showCreateNew &&
                            <Col className={"py-1"}>
                                <Button className={"w-100"} as={Link}
                                        to={CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL.replace(COL + SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])}>
                                    Create new service
                                </Button>
                            </Col>
                        }
                        {
                            this.state.services.filter(service => filterCallback(service.state)).map(service => {
                                return <Col className={"py-1"} key={service.id}>
                                    <TrackServiceBlock service={service} businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]}/>
                                </Col>
                            })
                        }
                    </div>
                </div>
            </Col>
        }

        return <></>;
    }

    SearchSection() {
        return <div className={"position-absolute bg-white rounded w-100 px-1"}>
            {
                this.state.services.filter(service => {
                    return this.state.search_query.length > 2 && JSON.stringify(service).toLowerCase().replace(" ","").includes(this.state.search_query.toLowerCase().replace(" ",""))
                }).map(service => {
                    return <Col className={"py-1"} key={"search_" + service.id}>
                        <TrackServiceBlock service={service} businessId={this.props.match.params[SERVICE_ACCOUNT_PARAM]}/>
                    </Col>
                })
            }
        </div>
    }

    TrackServiceListComponent() {
        if (this.state.something_went_wrong) {
            return <div className={"d-flex flex-fill justify-content-center align-items-center text-muted"}>
                {DEFAULT_ERROR_MSG}
            </div>
        }

        if (this.state.is_loading) {
            return <Loader label={"Loading tracking services"}/>
        }

        return <div className={"d-flex flex-column flex-fill"}>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Service Tracking
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage all service tracking
                </p>
            </div>

            <div className={"bg-white p-2 my-2 rounded"}>
                <p className={"small fw-bold m-0"}>
                    Filters
                </p>
                <div className={"d-flex flex-wrap"}>
                    <div className={"p-1"}>
                        <InputGroup>
                            <Button variant={"secondary"}>
                                Service status
                            </Button>
                            <Form.Select value={this.state.selectedServiceFilter} onChange={this.OnChangeSelectedServiceFilter} className={"shadow-none"}>
                                <option value={"ALL"} className={"hide-on-small-screen"}>ALL</option>
                                {
                                    Object.keys(TrackServiceType).map(key => <option key={key} value={key}>{key.replaceAll("_", " ")}</option>)
                                }
                            </Form.Select>
                        </InputGroup>
                    </div>
                    <div className={"position-relative"} style={{minWidth: 300}}>
                        <div className={"p-1"}>
                            <FormControl className={"shadow-none"} placeholder={"Search for tracking ticket/service"} value={this.state.search_query} onChange={this.OnChangeSearchQuery} />
                        </div>
                        <this.SearchSection />
                    </div>
                </div>
            </div>

            <div className={"bg-light rounded g-0 my-2 flex-fill"}>
                <Row className={"g-0 h-100 p-1"}>
                    <this.ServiceSection title={TrackServiceType.START} filterCallback={(state) => state === TrackServiceType.START} showCreateNew={true} />
                    <this.ServiceSection title={TrackServiceType.WORK_IN_PROGRESS} filterCallback={(state) => state === TrackServiceType.WORK_IN_PROGRESS} />
                    <this.ServiceSection title={"OTHERS"} filterCallback={(state) => state !== TrackServiceType.START && state !== TrackServiceType.WORK_IN_PROGRESS && state !== TrackServiceType.COMPLETED} />
                    <this.ServiceSection title={TrackServiceType.COMPLETED} filterCallback={(state) => state === TrackServiceType.COMPLETED} />
                </Row>
            </div>
        </div>
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                <this.TrackServiceListComponent/>
            </div>
        );
    }
}

export default Connector(ShowAllServiceTracking);