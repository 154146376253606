import React, {Component, createRef} from 'react';
import {CreateNewAddress} from "../../webrequests/AddressRequest";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Urls from "../../../constants/application/ApplicationUrl";
import NewAddress from "./NewAddress";
import {Link} from "react-router-dom";
import {IsInvalidAddressComponent} from "../../validators/Validator";
import InvalidNotification from "../../general/InvalidNotification";
import {Connector} from "../../../../../redux/Connector";
import {CreateAddressPayload} from "../../managers/AddressManager";

class CreateAddress extends Component {
    constructor(props) {
        super(props);
        this.onClickCreateNewAddress = this.onClickCreateNewAddress.bind(this);
        this.GoBack = this.GoBack.bind(this);
        this.ShowInvalidNotification = this.ShowInvalidNotification.bind(this);
        this.AddressComponent = createRef();
    }

    state = {
        show_invalid_notification: false
    }

    ShowInvalidNotification() {
        this.setState({
            show_invalid_notification: true
        });
        setTimeout(() => {
            this.setState({
                show_invalid_notification: false
            });
        }, 5000);
    }

    onClickCreateNewAddress() {
        if(IsInvalidAddressComponent(this.AddressComponent)) {
            this.ShowInvalidNotification();
            return;
        }

        CreateNewAddress(this.props, CreateAddressPayload(this.AddressComponent), this.GoBack, null);
    }

    GoBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <Container fluid>
                <Row noGutters>
                    <Col lg={2}>
                        <div className={"p-1 d-flex flex-wrap"}>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"}
                                    onClick={this.GoBack}>
                                    <FontAwesomeIcon icon={"cross"}/> Cancel
                            </Button>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"} as={Link}
                                    to={Urls.ACCOUNT_URL}>
                                <FontAwesomeIcon icon="address-book"/> My Account
                            </Button>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"} as={Link}
                                    to={Urls.HOME_URL}>
                                <FontAwesomeIcon icon="address-book"/> Back to home
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <Row noGutters>
                            <Col>
                                <div className={"p-4 bg-white rounded my-2"}>
                                    <form>
                                        <NewAddress ref={this.AddressComponent}/>
                                        <InvalidNotification show_invalid_notification={this.state.show_invalid_notification} />
                                        <Button type={"button"} variant={"danger"} onClick={this.GoBack}
                                                className={"me-1"}>Cancel</Button>
                                        <Button type={"button"} variant={"success"} className={"m-1"} onClick={this.onClickCreateNewAddress}>Create
                                            Address</Button>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Connector(CreateAddress);