import React, {Component} from 'react';
import {Offcanvas} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {Connector} from "../../../../../redux/Connector";
import {LOGIN_PAGE_URL} from "../../../../itsmyweb/constants/application/ApplicationUrl";
import {LOGOUT_API} from "../../../../itsmyweb/constants/backend/ApiConstant";

class PublicSidePanel extends Component {
    render() {
        return (
            <div>
                <Offcanvas show={this.props.show} onHide={this.props.onHide} style={{width: 280, zIndex: 3333}}>
                    <Offcanvas.Header className={"bg-light px-2"} closeButton>
                        <Offcanvas.Title>
                            {
                                this.props.user.username &&
                                    <div>
                                        <FontAwesomeIcon icon={"user-circle"} className={"me-2"} size={"1x"} />
                                        {this.props.user.firstName} {this.props.user.lastName}
                                    </div>
                            }
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={"border-top p-0"}>
                        {
                            this.props.sections && this.props.sections.map((section, s_index) => {
                                return <div key={"s_section_" + s_index} className={"border-top p-2"}>
                                    <div>
                                        <h6 className={"fw-bold py-1"}>{section.title}</h6>
                                    </div>
                                    {
                                        section.tabs.map((tab, t_index) => {
                                            return <Link to={tab.link} onClick={this.props.onHide} className={"no-text-decor"} key={"s_tab_" + s_index + "_" + t_index}><div>
                                                <p className={""}>{tab.name}</p>
                                            </div></Link>;
                                        })
                                    }
                                </div>
                            })
                        }
                    </Offcanvas.Body>
                    {
                        this.props.showSignIn &&
                        <Offcanvas.Header className={"p-0 no-text-decor"} as={Link}
                                          to={this.props.user.username ? LOGIN_PAGE_URL : LOGOUT_API}>
                            <div className={"bg-light p-3 w-100 cursor-pointer"}>
                                <FontAwesomeIcon icon={this.props.user.username ? "sign-in-alt" : "sign-out-alt"}
                                                 className={"me-1"}/>
                                {
                                    this.props.user.username ? "Sign out" : "Sign in"
                                }
                            </div>
                        </Offcanvas.Header>
                    }
                </Offcanvas>
            </div>
        );
    }
}

export default Connector(PublicSidePanel);