import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {Button} from "react-bootstrap";
import React from "react";

export function ImageUploadPreview({imageUrl, imageBlob, showProgress, progress, retryUploadCallback}) {
    if(!imageUrl) return <></>;

    return <div className={"position-relative h-100"}>
        <img alt={"upload_bicycle"} src={imageUrl} className={"h-100 rounded"} />
        <div className={"position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 left-0"}>
            {
                showProgress ?
                    <div className={"w-25"}>
                        <CircularProgressbar
                            value={progress}
                            text={`${progress}%`}
                            background
                            backgroundPadding={6}
                            styles={buildStyles({
                                backgroundColor: "#124A8B",
                                textColor: "#fff",
                                pathColor: "#fff",
                                trailColor: "transparent"
                            })}
                        />
                    </div>: <div>
                        <Button onClick={()=>retryUploadCallback(imageBlob)}>Retry upload</Button>
                    </div>
            }
        </div>
    </div>
}