import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {Form, Button, Dropdown, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    CUSTOM_PAGE_SINGLE_WEB_PAGE_URL,
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {ALLOWED_ICONS} from "../../../../../constants/Constant";
import {AlphaAndSpaceOnlyForInput, AlphaOnlyForInput, JsonFromFormEvent} from "../../../../../utils/FormManager";
import {BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {
    CREATE_CUSTOM_WEB_PAGE_API,
} from "../../../../../constants/backend/ApiConstant";

class CustomCreateNewPage extends Component {
    state = {
        selected_icon: "user",
        is_loading: false
    }

    constructor(props) {
        super(props);
        this.OnSelectDropdownIcon = this.OnSelectDropdownIcon.bind(this);
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
        this.OnSuccessCreate = this.OnSuccessCreate.bind(this);
        this.OnFailureCreate = this.OnFailureCreate.bind(this);
    }

    OnSelectDropdownIcon(icon) {
        this.setState({
            selected_icon: icon
        });
    }

    OnSubmitForm(event) {
        event.preventDefault();
        let formJsonData = JsonFromFormEvent(event);
        formJsonData["businessId"] = this.props.match.params[SERVICE_ACCOUNT_PARAM];

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_CUSTOM_WEB_PAGE_API, formJsonData, this.OnSuccessCreate, this.OnFailureCreate);
        return false;
    }

    OnSuccessCreate(response) {
        this.setState({
            is_loading: false
        });

        window.location.href = CUSTOM_PAGE_SINGLE_WEB_PAGE_URL
            .replace(COL + SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])
            .replace(COL + PAGE_ID_PARAM, response.body.id);
    }

    OnFailureCreate(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Custom page error", error);
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                <div>
                    <div className={"bg-white rounded shadow-sm p-2"}>
                        <h4>
                            Create New Web Page
                        </h4>
                        <p className={"text-secondary m-0"}>
                            Tip to create new tab: First create new page and then drag it to tab section.
                        </p>
                    </div>

                    <div className={"bg-white rounded shadow-sm p-2 mt-2"}>
                        <form onSubmit={this.OnSubmitForm}>
                            <div>
                                <InputGroup className={"col-2"}>
                                    <InputGroup.Text>
                                        Select Icon For Page
                                    </InputGroup.Text>
                                    <Dropdown align={"start"} onSelect={this.OnSelectDropdownIcon}>
                                        <Dropdown.Toggle variant={"light"} className={"shadow-none border"} bsPrefix={"p-0"}>
                                            <div style={{width: 100}} className={"d-flex justify-content-center p-2"}>
                                                <FontAwesomeIcon icon={this.state.selected_icon} />
                                            </div>
                                            <Form.Control disabled name={"iconName"} className={"visually-hidden"} value={this.state.selected_icon} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={"border-primary-light"} style={{minWidth: 100}}>
                                            {
                                                ALLOWED_ICONS.map(icon => <Dropdown.Item key={icon} eventKey={icon} className={"p-3"} style={{width: 100}} >
                                                    <div className={"d-flex justify-content-center"}>
                                                        <FontAwesomeIcon icon={icon} />
                                                    </div>
                                                </Dropdown.Item>)
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </InputGroup>

                                <label className={"mt-3 fw-bold"}>
                                    Page short name
                                </label>
                                <Form.Control required minLength={3} maxLength={20} onChange={AlphaOnlyForInput} className={"shadow-none mb-2"} name={"pageShortName"} placeholder={"Page short name without space"}/>

                                <label className={"fw-bold"}>
                                    Page name
                                </label>
                                <Form.Control required minLength={3} maxLength={20} onChange={AlphaAndSpaceOnlyForInput} className={"shadow-none mb-2"} placeholder={"Page name"} name={"pageName"} />

                                <div className={"my-3"}>
                                    <Button type={"submit"}>Create New Page</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Connector(CustomCreateNewPage);