import React, {Component} from 'react';
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {
    GET_SINGLE_CUSTOM_WEB_PAGE_API} from "../../../../../constants/backend/ApiConstant";
import {Connector} from "../../../../../../../redux/Connector";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import Loader from "../../../../itsmyweb/loader/Loader";
import {ShowErrorModalFromError} from "../../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {isNullOrUndefinedObject} from "../../../../../utils/validators/Validator";
import {OnPageLoadErrorComponent} from "../common/OnPageLoadErrorComponent";
import CustomWebPage from "../common/CustomWebPage";
import "../widget.css";

class CustomSinglePage extends Component {
    state = {
        is_loading: true,
        loading_error: false,
        page: {
            pageName: "",
            pageShortName: "",
            widgets: []
        }
    }

    constructor(props) {
        super(props);
        this.OnSuccessGetPage = this.OnSuccessGetPage.bind(this);
        this.OnFailureGetPage = this.OnFailureGetPage.bind(this);
        this.SinglePage = this.SinglePage.bind(this);
    }

    componentDidMount() {
        this.setState({
            is_loading: true
        });

        BackendGETRequest(this.props, GET_SINGLE_CUSTOM_WEB_PAGE_API
                .replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM])
                .replace(PAGE_ID_PARAM, this.props.match.params[PAGE_ID_PARAM]),
            this.OnSuccessGetPage, this.OnFailureGetPage);
    }

    OnSuccessGetPage(response) {
        if(isNullOrUndefinedObject(response.body.widgets)) {
            response.body.widgets = [];
        }

        this.setState({
            is_loading: false,
            page: response.body
        });
    }

    OnFailureGetPage(error) {
        this.setState({
            is_loading: false,
            loading_error: true
        });

        ShowErrorModalFromError(this.props, "Web page error", error);
    }

    SinglePage() {
        if(this.state.is_loading) {
            return <div className={"d-flex flex-fill"}>
                <Loader label={"Loading page"}/>
            </div>;
        } else if(this.state.loading_error) {
            return <OnPageLoadErrorComponent serviceAccountId={this.props.match.params[SERVICE_ACCOUNT_PARAM]} />;
        }

        return <CustomWebPage page={this.state.page} serviceId={this.props.match.params[SERVICE_ACCOUNT_PARAM]} pageId={this.props.match.params[PAGE_ID_PARAM]} />;
    }

    render() {
        return (
            <div className={"d-flex flex-column flex-fill"}>
                <this.SinglePage />
            </div>
        );
    }
}

export default Connector(CustomSinglePage);