import React, {Component, createRef} from 'react';
import "./leftpanel.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {PARAM_SEPARATOR} from "../../../constants/application/ApplicationUrl";
import {SERVICE_ACCOUNT_PARAM} from "../../../constants/ParamConstant";
import {CloseButton} from "react-bootstrap";
import "../../../../app/App.css";
import {SMALL_DEVICES_WIDTH_PIVOT} from "../../../constants/Constant";

class ServiceTabLeftPanel extends Component {
    state = {
        show_left_panel: true
    }

    constructor(props) {
        super(props);
        this.element = createRef();
        this.ToggleLeftPanel = this.ToggleLeftPanel.bind(this);
        this.HideLeftPanelOnClickNav = this.HideLeftPanelOnClickNav.bind(this);
    }

    ToggleLeftPanel() {
        this.setState({
            show_left_panel: !this.state.show_left_panel
        });
    }

    HideLeftPanelOnClickNav() {
        if(window.innerWidth > SMALL_DEVICES_WIDTH_PIVOT) {
            return;
        }

        this.setState({
            show_left_panel: false
        });
    }

    IsActiveTabPrefixMatch(link, nonComponent) {
        return window.location.pathname.startsWith(link) && nonComponent !== true;
    }

    IsActiveTabExactMatch(link) {
        return link === window.location.pathname;
    }

    ReplaceParamUrl(link) {
        return link.replace(PARAM_SEPARATOR + SERVICE_ACCOUNT_PARAM, "/" + this.props.businessId);
    }

    render() {
        if(!this.state.show_left_panel) {
            return <div className={"position-relative"}>
                <div className={"p-2 fw-bold bg-primary position-fixed cursor-pointer d-flex align-items-center rounded-left-0"} style={{bottom:"30vh", height: 100, zIndex: 2222}} onClick={this.ToggleLeftPanel}>
                    <FontAwesomeIcon icon={"chevron-right"} color={"white"} />
                </div>
            </div>
        }

        return (
            <div className={"its-my-web-left-panel bg-dark overflow-auto flex-shrink-0"}>
                <div ref={this.element} className={"text-white shadow-sm bg-dark w-100"}>
                    <div className={"p-2 fw-bold bg-primary position-relative"}>
                        {this.props.title}
                        <CloseButton variant={"white"} className={"float-end shadow-none show-on-small-screen"} onClick={this.ToggleLeftPanel} />
                    </div>
                    {
                        this.props.sections.filter(section => !section.hidden).map((section, s_index) => <div key={"section_" + s_index}>
                            <div className={"p-2 fw-bold"}>{section.name}</div>
                            {
                                section.tabs.filter(tab => !tab.hidden).map((tab, index) => {
                                    let tab_link = this.ReplaceParamUrl(tab.link);
                                    return <div key={"service_tab_index_" + s_index + index}>
                                        <Link className={"no-text-decor"}
                                              to={tab_link} onClick={this.HideLeftPanelOnClickNav}>
                                            <div
                                                className={`d-flex align-items-center left_panel_item p-2 ps-0 w-100 ${this.IsActiveTabPrefixMatch(tab_link, tab.nonComponent) && "left_panel_active"}`}>
                                                <FontAwesomeIcon icon={tab.icon} style={{width: 46}}/>
                                                <div className={""}>
                                                    {tab.name}
                                                </div>
                                            </div>
                                        </Link>
                                        {
                                            this.IsActiveTabPrefixMatch(tab_link) && tab.sub_tabs &&
                                            tab.sub_tabs.filter(sub_tab => !sub_tab.hidden).map((sub_tab, ss_index) => {
                                                let sub_tab_link = this.ReplaceParamUrl(sub_tab.link);
                                                return <Link className={"no-text-decor"} key={"service_sub_tab_index_" + s_index + index + ss_index}
                                                      to={sub_tab_link} onClick={this.HideLeftPanelOnClickNav}>
                                                    <div className={`d-flex align-items-center left_panel_item p-2 ps-4 ps-0 w-100 ${this.IsActiveTabExactMatch(sub_tab_link) && "left_panel_active"}`}>
                                                        <FontAwesomeIcon icon={sub_tab.icon? sub_tab.icon:tab.icon} style={{width: 46}}/>
                                                        <div className={""}>
                                                            {sub_tab.name}
                                                        </div>
                                                    </div>
                                                </Link>})
                                        }
                                    </div>
                                })
                            }
                        </div>)
                    }
                </div>
            </div>
        );
    }
}

export default ServiceTabLeftPanel;