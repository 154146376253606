import React, {Component} from 'react';
import {Spinner, Form, Button} from "react-bootstrap";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNT_API} from "../../../../constants/backend/ApiConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {Connector} from "../../../../../../redux/Connector";

class ManageWebsite extends Component {
    state = {
        is_loading: true,
        business: {},
        is_disabled: false
    }

    constructor(props) {
        super(props);
        this.WebsiteComponent = this.WebsiteComponent.bind(this);
        this.LoaderComponent = this.LoaderComponent.bind(this);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
        this.OnFailureRequest = this.OnFailureRequest.bind(this);
        this.OnSwitchCheck = this.OnSwitchCheck.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNT_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]), this.OnSuccessRequest, this.OnFailureRequest)
    }

    OnSuccessRequest(response) {
        this.setState({
            is_loading: false,
            business: response.body
        });
    }

    OnFailureRequest(error) {
        this.setState({
            is_loading: false
        });
    }

    OnSwitchCheck(event) {
        if(window.confirm(`Are you sure to ${this.state.is_disabled? "enable":"disable"} this business/service?`)) {
            this.setState({
                is_disabled: event.target.checked
            });
        }
    }

    LoaderComponent() {
        return <div className={"d-flex flex-column justify-content-center align-items-center h-100 text-secondary"}>
            <Spinner animation={"border"} />
            Loading business/services
        </div>;
    }

    WebsiteComponent() {
        if(this.state.business.businessName === undefined) {
            return <div className={"d-flex justify-content-center align-items-center h-100 text-muted"}>
                Something went wrong
            </div>
        }

        return <div>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Website Page Settings
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage website page settings
                </p>
            </div>
            <div className={"bg-white rounded shadow-sm p-2 mt-2"}>
                <Form.Control className={"shadow-none"} placeholder={"website title"} name={"customWebsite"} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Allow dynamic title (will change with individual page)"} name={"customWebsite"} />

                <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Show instagram link on right bottom corner"} name={""} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Show facebook link on right bottom corner"} name={""} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Show youtube channel link on right bottom corner"} name={""} />
                <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Show whatsapp link on right bottom corner"} name={""} />

                <Button disabled className={"my-2"}> Update </Button>
            </div>
        </div>;
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                {
                    this.state.is_loading ? <this.LoaderComponent />: <this.WebsiteComponent />
                }
            </div>
        );
    }
}

export default Connector(ManageWebsite);