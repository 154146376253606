import React, {Component, createRef} from 'react';
import {Container, FormControl, Button, Form, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import NewAddress from "../../../user/address/NewAddress";
import {Connector} from "../../../../../../redux/Connector";
import {
    MY_SERVICES_URL,
    MY_SINGLE_SERVICE_SETTINGS_URL
} from "../../../../constants/application/account/services/ServiceAccountUrls";
import Loader from "../../../itsmyweb/loader/Loader";
import {AlphaOnlyForInput, JsonFromFormEvent} from "../../../../utils/FormManager";
import {BackendGETRequest, BackendPOSTRequest} from "../../../webrequests/BackendRequest";
import {
    CREATE_USER_SERVICE_ACCOUNT_API,
    GET_USER_SERVICE_ACCOUNT_TYPE_API
} from "../../../../constants/backend/ApiConstant";
import {
    ShowErrorModalFromError,
    ShowOkButtonErrorModal
} from "../../../itsmyweb/modals/centermodal/CenterModalScreenManager";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {COL} from "../../../../constants/application/ApplicationUrl";

class CreateServiceAccount extends Component {
    state = {
        is_creating: false,
        business_types: [],
        serviceUsername: ""
    }

    constructor(props) {
        super(props);
        this.OnSubmitCreateAccountForm = this.OnSubmitCreateAccountForm.bind(this);
        this.OnSuccessCreateService = this.OnSuccessCreateService.bind(this);
        this.OnFailureCreateService = this.OnFailureCreateService.bind(this);
        this.OnSuccessGetServiceAccountType = this.OnSuccessGetServiceAccountType.bind(this);
        this.OnFailureGetServiceAccountType = this.OnFailureGetServiceAccountType.bind(this);
        this.OnChangeServiceUserName = this.OnChangeServiceUserName.bind(this);
        this.AddressComponent = createRef();
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNT_TYPE_API, this.OnSuccessGetServiceAccountType, this.OnFailureGetServiceAccountType);
    }

    OnSubmitCreateAccountForm(event) {
        event.preventDefault();

        if(this.state.serviceUsername.length < 4 || this.state.serviceUsername.length > 30) {
            ShowOkButtonErrorModal(this.props, "Validation Error", "Business username should at least 4 characters");
            return false;
        }

        this.setState({
            creating: true
        });

        BackendPOSTRequest(this.props, CREATE_USER_SERVICE_ACCOUNT_API, JsonFromFormEvent(event), this.OnSuccessCreateService, this.OnFailureCreateService);
        return false;
    }

    OnSuccessCreateService(response) {
        window.location.href = MY_SINGLE_SERVICE_SETTINGS_URL.replace(COL + SERVICE_ACCOUNT_PARAM, response.body.id);
    }

    OnFailureCreateService(error) {
        this.setState({
            is_creating: false
        });
        ShowErrorModalFromError(this.props, "Service account error", error);
    }

    OnSuccessGetServiceAccountType(response) {
        const businessTypes = response.body["serviceAccountTypes"];
        this.setState({
            business_types: businessTypes,
            business_type: businessTypes[0].key
        });
    }

    OnFailureGetServiceAccountType(error) {
        ShowErrorModalFromError(this.props, "Service account error", error);
    }

    OnChangeServiceUserName(event) {
        AlphaOnlyForInput(event);
        this.setState({
            serviceUsername: event.target.value.toLowerCase()
        });
    }

    render() {
        return (
            <Container>
                <h4 className="p-2 itsmyweb-title rounded mt-2 text-center">Create new service/business account</h4>
                <form onSubmit={this.OnSubmitCreateAccountForm}>
                    <Row className={"g-0 bg-white my-2 rounded p-3"}>
                        <Col className="p-1 my-2" md={6}>
                        <div className="position-relative">
                            <label className={"input-top"}>Enter service/business name</label>
                            <FormControl name={"businessName"} className={"shadow-none validate-input text-capitalize"} minLength={4} maxLength={35} required />
                        </div>
                        </Col>
                        <Col className="p-1 my-2" md={6}>
                            <div className="position-relative">
                                <label className={"input-top"}>Enter username for your service</label>
                                <FormControl name={"businessUsername"} className={"shadow-none validate-input text-lowercase"} value={this.state.serviceUsername} onChange={this.OnChangeServiceUserName} minLength={4} maxLength={30} required />
                                {
                                    this.state.serviceUsername &&
                                    <p className={"fs-12"}><b>Your website:</b> https://{this.state.serviceUsername}.itsmyweb.in</p>
                                }
                            </div>
                        </Col>
                        <Col className="p-1">
                            <div className="position-relative">
                            <label className={"input-top"}>Business or service type</label>
                            <Form.Select name={"businessType"} defaultValue={"MOBILE"} className={"shadow-none"} required>
                                {
                                    this.state.business_types.map(businessType => {
                                        return <option value={businessType.key} key={"bt_key_" + businessType.key}>{businessType.value}</option>;
                                    })
                                }
                            </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    <div className={"bg-white p-4 rounded my-2"}>
                        <NewAddress header={"Address"} ref={this.AddressComponent} />
                    </div>
                    <div className={"bg-white p-4 my-2 rounded"}>
                        <div className={"text-center"}>
                            {
                                this.state.is_creating ? <Loader label={"Creating service account, please wait"}/> : <>
                                    <Button type={"button"} variant={"danger"} className={"m-1"} as={Link}
                                            to={MY_SERVICES_URL}>Cancel and back</Button>
                                    <Button type={"submit"} variant={"primary"} className={"m-1"}> Create service
                                        account </Button>
                                </>
                            }
                        </div>
                    </div>
                </form>
            </Container>
        );
    }
}

export default Connector(CreateServiceAccount);