import React, {Component} from 'react';
import {Button, Container, FormControl, InputGroup, FormCheck} from "react-bootstrap";
import GoogleAuthenticate from "../itsmyweb/googleLogin/GoogleAuthenticate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import login_image from "../../statics/images/login_page_image2.png";
import {Connector} from "../../../../redux/Connector";

class LoginPage extends Component {
    render() {
        return (
            <div className={"position-relative overflow-hidden flex-fill d-flex"}>
                <svg className={"position-absolute top-0"} style={{zIndex: -1, height: "70%"}}
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#294cda" fillOpacity="1"
                          d="M0,256L60,224C120,192,240,128,360,133.3C480,139,600,213,720,245.3C840,277,960,267,1080,250.7C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
                <Container className={"d-flex justify-content-center flex-fill align-items-center"}>
                    <div className={"d-flex bg-primary overflow-hidden rounded shadow"} style={{minWidth: "70%"}}>
                        <div className={"flex-fill w-50 hide-on-small-screen"}
                             style={{background: `url(${login_image}) transparent no-repeat bottom center  / cover`}}>
                            {/*<img />*/}
                        </div>
                        <div className={"flex-fill w-50 bg-white p-4"}>
                            <h4 className={"fw-bold"}>
                                Welcome to ItsMyWeb
                            </h4>
                            <p className={"text-secondary small"}>
                                Create your website with different services to reach your customers.
                            </p>

                            <div className={"py-2"}>
                                <div className={"d-flex py-3"}>
                                    <GoogleAuthenticate/>
                                </div>

                                <div className={"text-secondary small"}>
                                    Or sign in with
                                </div>
                            </div>

                            <InputGroup className={"shadow-none my-2 mt-4"}>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={"user"}/>
                                </InputGroup.Text>
                                <FormControl name="username" placeholder={"Email or username"}
                                             className={"shadow-none"}/>
                            </InputGroup>

                            <InputGroup className={"shadow-none mb-4"}>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={"key"}/>
                                </InputGroup.Text>
                                <FormControl name="password" type={"password"} placeholder={"Password"}
                                             className={"shadow-none"}/>
                            </InputGroup>

                            <FormCheck label="Remember Me" className={"shadow-none small"}/>

                            <Button className={"my-2 w-100 shadow-none"}>Login</Button>
                            <div className={"text-primary small"}>
                                Forgot password
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(LoginPage);