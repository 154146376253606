import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {
    ACCOUNT_PROFILE_URL,
    ACCOUNT_URL, CONTACT_US_URL, FEEDBACK_URL, HELP_AND_SUPPORT_URL,
    HOME_URL, ITS_MY_WEB_PRICING_URL,
} from "../constants/application/ApplicationUrl";
import ItsMyWebHome from "../components/itsmyweb/home/ItsMyWebHome";
import MyAccount from "../components/user/MyAccount";
import Profile from "../components/user/profile/Profile";
import {ACCOUNT_ORDERS_URL} from "../constants/application/account/OrdersUrls";
import Orders from "../components/user/orders/Orders";
import {ACCOUNT_SECURITY_URL} from "../constants/application/account/SecurityUrls";
import AccountSecurity from "../components/user/security/AccountSecurity";
import {MY_SERVICES_URL} from "../constants/application/account/services/ServiceAccountUrls";
import ServiceAccountUrlComponent from "../components/business/account/services/urls/ServiceAccountUrlComponent";
import {ALL_ADDRESS_URL, CREATE_ADDRESS_URL} from "../constants/application/account/AddressUrls";
import MyAddress from "../components/user/address/MyAddress";
import CreateAddress from "../components/user/address/CreateAddress";
import Navbar from "../components/itsmyweb/navbar/NavbarComponent";
import Footer from "../components/itsmyweb/footer/Footer";
import {Connector} from "../../../redux/Connector";
import ItsMyWebPricing from "../components/itsmyweb/plan/pricing/ItsMyWebPricing";
import ContactUs from "../components/itsmyweb/help/ContactUs";
import Feedback from "../components/itsmyweb/feedback/Feedback";
import HelpAndSupportHome from "../components/itsmyweb/help/support/HelpAndSupportHome";

class ItsMyWebUrlsComponent extends Component {

    constructor(props) {
        super(props);
        this.AuthenticatedRouteComponent = this.AuthenticatedRouteComponent.bind(this);
        this.AddressUrls = this.AddressUrls.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            window.location.href = HOME_URL;
        }, 108000000);
    }

    AuthenticatedRouteComponent() {
        if(!this.props.user.username) {
            return <Redirect to={HOME_URL} exact={true} />;
        }

        return <>
            <Route path={ACCOUNT_URL} exact={true} component={MyAccount}/>
            <Route path={ACCOUNT_PROFILE_URL} exact={true} component={Profile}/>
            <Route path={ACCOUNT_ORDERS_URL} exact={true} component={Orders}/>
            <Route path={ACCOUNT_SECURITY_URL} exact={true} component={AccountSecurity}/>

            <this.AddressUrls />
            <Route path={MY_SERVICES_URL} component={ServiceAccountUrlComponent} />
        </>;
    }

    AddressUrls() {
        return <>
            <Route path={ALL_ADDRESS_URL} exact={true} component={MyAddress}/>
            <Route path={CREATE_ADDRESS_URL} exact={true} component={CreateAddress} />
        </>;
    }

    render() {
        return (
            <div className={"flex-fill d-flex flex-column"}>
                <Navbar/>
                <div className={"flex-fill d-flex flex-column"}>
                    <Switch>
                        <Route path={HOME_URL} exact={true} component={ItsMyWebHome} />
                        <Route path={ITS_MY_WEB_PRICING_URL} exact={true} component={ItsMyWebPricing} />
                        <Route path={CONTACT_US_URL} exact={true} component={ContactUs} />
                        <Route path={FEEDBACK_URL} exact={true} component={Feedback} />
                        <Route path={HELP_AND_SUPPORT_URL} exact={true} component={HelpAndSupportHome} />

                        <this.AuthenticatedRouteComponent />
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Connector(ItsMyWebUrlsComponent);