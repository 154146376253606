import {GetAppBackendUrl} from "../../utils/ItsMyWebUtil";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM, SERVICE_TAB_ID_PARAM, TRACKING_ID_PARAM} from "../ParamConstant";


export const GET_USER_API = GetAppBackendUrl() + "/api/user";
export const LOGOUT_API = GetAppBackendUrl() + "/api/logout/";
export const GET_TOKEN_API = GetAppBackendUrl() + "/auth/authenticate/token/google_oauth2";

export const GET_USER_SERVICE_ACCOUNTS_API = GetAppBackendUrl() + "/api/user/service";
export const GET_USER_SERVICE_ACCOUNT_TYPE_API = GET_USER_SERVICE_ACCOUNTS_API + "/business_types";
export const GET_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/get/" + SERVICE_ACCOUNT_PARAM;
export const CREATE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/create";
export const UPDATE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/update";
export const DELETE_USER_SERVICE_ACCOUNT_API = GET_USER_SERVICE_ACCOUNTS_API + "/delete";

export const GET_SERVICE_ACCOUNT_ABOUT_US_API = GET_USER_SERVICE_ACCOUNTS_API + "/about_us";
export const UPDATE_USER_SERVICE_ABOUT_US_API = GET_USER_SERVICE_ACCOUNTS_API + "/update";

// ADDRESS API
export const GET_ADDRESS_API = GetAppBackendUrl() + "/api/user/address"
export const CREATE_ADDRESS_API = GET_ADDRESS_API + "/create";
export const UPDATE_ADDRESS_API = GET_ADDRESS_API + "/update";
export const DELETE_ADDRESS_API = GET_ADDRESS_API + "/delete";

// SERVICE API
const GET_SERVICE_TAB_BASE_API = GET_USER_SERVICE_ACCOUNTS_API + "/tab/service";
export const GET_SERVICE_TAB_ALL_ITEMS_API = GET_SERVICE_TAB_BASE_API + "/get/" + SERVICE_ACCOUNT_PARAM;
export const GET_SERVICE_TAB_SINGLE_ITEM_API = GET_SERVICE_TAB_ALL_ITEMS_API + "/" + SERVICE_TAB_ID_PARAM;
export const CREATE_SERVICE_TAB_ITEM_API = GET_SERVICE_TAB_BASE_API + "/create";
export const UPDATE_SERVICE_TAB_ITEM_API = GET_SERVICE_TAB_BASE_API + "/update";
export const DELETE_SERVICE_TAB_ITEM_API = GET_SERVICE_TAB_BASE_API + "/delete";
export const UPDATE_SERVICE_TAB_ITEM_IMAGE_API = GET_SERVICE_TAB_BASE_API + "/create/image";

// WEB PAGE API
const GET_CUSTOM_WEB_PAGE_BASE_API = GET_USER_SERVICE_ACCOUNTS_API + "/custom_page";
export const GET_ALL_CUSTOM_WEB_PAGES_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/" + SERVICE_ACCOUNT_PARAM + "/settings";
export const UPDATE_ALL_CUSTOM_WEB_PAGES_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/" + SERVICE_ACCOUNT_PARAM + "/settings/update";
export const GET_SINGLE_CUSTOM_WEB_PAGE_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/" + SERVICE_ACCOUNT_PARAM + "/page/" + PAGE_ID_PARAM;
export const CREATE_CUSTOM_WEB_PAGE_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/create";
export const DELETE_CUSTOM_WEB_PAGE_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/delete";
export const GET_CUSTOM_WEB_PAGE_NAVBAR_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/" + SERVICE_ACCOUNT_PARAM +"/navbar";
export const UPLOAD_NAVBAR_LOGO_IMAGE_API = GET_CUSTOM_WEB_PAGE_NAVBAR_API + "/image/logo";
export const GET_CUSTOM_WEB_PAGE_FOOTER_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/" + SERVICE_ACCOUNT_PARAM +"/footer";


export const CREATE_SERVICE_TRACKING_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/service_tracking/create";
export const UPDATE_SERVICE_TRACKING_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/service_tracking/update";
export const GET_ALL_SERVICE_TRACKING_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/service_tracking/" + SERVICE_ACCOUNT_PARAM;
export const GET_SINGLE_SERVICE_TRACKING_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/service_tracking/" + SERVICE_ACCOUNT_PARAM + "/tracking/" + TRACKING_ID_PARAM;

// WEB PAGE WIDGET
const GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API = GET_CUSTOM_WEB_PAGE_BASE_API + "/widget";
// export const GET_ALL_CUSTOM_WEB_PAGE_WIDGETS_API = GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API + "/" + SERVICE_ACCOUNT_PARAM + "/page/" + PAGE_ID_PARAM;
export const CREATE_CUSTOM_WEB_PAGE_WIDGET_API = GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API + "/create";
export const UPDATE_CUSTOM_WEB_PAGE_WIDGET_API = GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API + "/update";
export const DELETE_CUSTOM_WEB_PAGE_WIDGET_API = GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API + "/delete";
export const UPLOAD_IMAGE_CUSTOM_WEB_PAGE_WIDGET_API = GET_CUSTOM_WEB_PAGE_WIDGET_BASE_API + "/image";

// FEEDBACK
export const ITS_MY_WEB_FEEDBACK_API = GET_USER_API + "/feedback";