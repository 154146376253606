import {Button, Form, FormControl, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {SelectFromFollowing} from "./AboutUsSection";

export function PoliciesSection({footer, page_settings, OnChangeInput, OnClickDeletePage, OnClickAddPage}) {
    return <div className={"col-md-6 p-1"}>
        <div className={"shadow-sm p-2 rounded border h-100"}>
            <h5 className={"fw-bold"}>Policies</h5>
            <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Enable"}
                        checked={footer.policies.enabled} name={"policies.enabled"} onChange={OnChangeInput} />
            <div className={"policies_pages py-2"}>
                <p className={"fw-bold small m-0"}>
                    Selected pages for policies
                </p>
                {
                    footer.policies.pages.map((page, index) => <div key={"p_page_i_" + page.link} className={"py-1"}>
                        <InputGroup>
                            <FormControl className={"shadow-none"} defaultValue={page.name}
                                         disabled={true}
                                         data-link={page.link}/>
                            <Button variant={"danger"} className={"shadow-none"} onClick={() => OnClickDeletePage(index, "policies")} >
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                        </InputGroup>
                    </div>)
                }
            </div>
            <SelectFromFollowing footer={footer} page_settings={page_settings} OnClickAddPage={OnClickAddPage} page_name={"policies"} />
        </div>
    </div>
}