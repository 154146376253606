import React, {Component} from 'react';
import {Spinner, Form, Button, InputGroup} from "react-bootstrap";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNT_API} from "../../../../constants/backend/ApiConstant";
import {SERVICE_ACCOUNT_PARAM} from "../../../../constants/ParamConstant";
import {Connector} from "../../../../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class BusinessAndServiceSettings extends Component {
    state = {
        is_loading: true,
        business: {},
        is_disabled: false
    }

    constructor(props) {
        super(props);
        this.BusinessComponent = this.BusinessComponent.bind(this);
        this.LoaderComponent = this.LoaderComponent.bind(this);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
        this.OnFailureRequest = this.OnFailureRequest.bind(this);
        this.OnSwitchCheck = this.OnSwitchCheck.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNT_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]), this.OnSuccessRequest, this.OnFailureRequest)
    }

    OnSuccessRequest(response) {
        this.setState({
            is_loading: false,
            business: response.body
        });
    }

    OnFailureRequest(error) {
        this.setState({
            is_loading: false
        });
    }

    OnSwitchCheck(event) {
        if(window.confirm(`Are you sure to ${this.state.is_disabled? "enable":"disable"} this business/service?`)) {
            this.setState({
                is_disabled: event.target.checked
            });
        }
    }

    LoaderComponent() {
        return <div className={"d-flex flex-column justify-content-center align-items-center h-100 text-secondary"}>
            <Spinner animation={"border"} />
            Loading business/services
        </div>;
    }

    BusinessComponent() {
        if(this.state.business.businessName === undefined) {
            return <div className={"d-flex justify-content-center align-items-center h-100 text-muted"}>
                Something went wrong
            </div>
        }

        return <div>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    {this.state.business.businessName}
                </h4>
                {
                    this.state.business.businessShortName &&
                    <p className={"text-secondary m-0"}>
                        {this.state.business.businessShortName}
                    </p>
                }
                {
                    this.state.business.approved &&
                    <p className={"text-secondary m-0"}>
                        website: {this.state.business.businessUsername}.itsmyweb.com
                    </p>
                }
                <div className={"text-secondary"}>
                    {
                        this.state.business.approved? <div>
                            approved {" "}
                            <FontAwesomeIcon icon={"check-circle"} color={"green"} />
                        </div>:<div>
                            under review {" "}
                            <FontAwesomeIcon icon={"check-circle"} color={"info"} />
                        </div>
                    }
                </div>
            </div>
            {
                !this.state.business.approved &&
                <div className={"bg-light-info rounded shadow-sm p-2 mt-2"}>
                    Your service/business account is under review, it usually takes up to 48 business hrs
                    <p className={"m-0 text-muted small"}>Please do visit after sometime and all your setting and edit options will available on this page</p>
                    <p className={"m-0 text-muted small"}>Thank you!</p>
                </div>
            }
            {
                this.state.business.approved &&
                <div className={"bg-white rounded shadow-sm p-2 mt-2"}>
                    <InputGroup>
                        <InputGroup.Text>
                            Custom Website
                        </InputGroup.Text>
                        <Form.Control className={"shadow-none"} name={"customWebsite"}/>
                    </InputGroup>
                    <Button disabled className={"my-2"}> Update </Button>
                </div>
            }
            <div className={"bg-white rounded shadow-sm p-2 mt-2 border border-danger"}>
                <h6>Danger Zone</h6>
                <Form.Check checked={this.state.is_disabled} onChange={this.OnSwitchCheck} type={"switch"} label={"Disable Business/Service"} />
                <Button variant={"danger"} className={"mt-3"} disabled={!this.state.is_disabled}>
                    <FontAwesomeIcon icon={"trash"} /> Delete Business/Service
                </Button>
            </div>
        </div>;
    }

    render() {
        return (
            <div className={"p-2 flex-fill flex-column d-flex"}>
                {
                    this.state.is_loading ? <this.LoaderComponent />: <this.BusinessComponent />
                }
            </div>
        );
    }
}

export default Connector(BusinessAndServiceSettings);