import {ACCOUNT_URL, PARAM_SEPARATOR} from "../../ApplicationUrl";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM, TRACKING_ID_PARAM} from "../../../ParamConstant";

export const MY_SERVICES_URL = ACCOUNT_URL + "/services";
export const MY_SINGLE_SERVICE_URL = MY_SERVICES_URL + PARAM_SEPARATOR + SERVICE_ACCOUNT_PARAM;
export const CREATE_SERVICE_ACCOUNT_URL = MY_SERVICES_URL + "/services/create";

export const MANAGE_ADDRESS_PAGE = MY_SINGLE_SERVICE_URL + "/address";

// START ---------- BUSINESS AND SERVICE ----------
// Business/Service
export const MY_SINGLE_SERVICE_SETTINGS_URL = MY_SINGLE_SERVICE_URL + "/business/settings";
export const MY_SINGLE_SERVICE_PERMISSION_URL = MY_SINGLE_SERVICE_URL + "/business/permissions";
// Custom Page
export const CUSTOM_PAGE_MANAGE_URL = MY_SINGLE_SERVICE_URL + "/custom_page/settings";
export const CUSTOM_PAGE_CREATE_NEW_PAGE_URL = MY_SINGLE_SERVICE_URL + "/custom_page/settings/create";
export const CUSTOM_PAGE_SINGLE_WEB_PAGE_URL = MY_SINGLE_SERVICE_URL + "/custom_page/settings/page" + PARAM_SEPARATOR + PAGE_ID_PARAM;
export const CUSTOM_PAGE_SERVICE_TRACKING_CREATE_URL = MY_SINGLE_SERVICE_URL + "/custom_page/service_tracking/create";
export const SHOW_ALL_SERVICES_TRACKING_URL = MY_SINGLE_SERVICE_URL + "/custom_page/service_tracking/service_tracking";
export const SHOW_SINGLE_SERVICE_TRACKING_URL = MY_SINGLE_SERVICE_URL + "/custom_page/service_tracking/service_tracking" + PARAM_SEPARATOR + TRACKING_ID_PARAM;

// Manage Website
export const MANAGE_WEBSITE_SETTINGS_URL = MY_SINGLE_SERVICE_URL + "/manage_website";
// Manage Navbar
export const MANAGE_WEBSITE_NAVBAR_SETTINGS_URL = MY_SINGLE_SERVICE_URL + "/manage_navbar";
// Manage ItsMyWebFooter
export const MANAGE_WEBSITE_FOOTER_SETTINGS_URL = MY_SINGLE_SERVICE_URL + "/manage_footer";
// END ---------- BUSINESS AND SERVICE ----------