import React, {Component} from 'react';
import Loader from "../itsmyweb/loader/Loader";
import {DEFAULT_ERROR_MSG} from "../../constants/Constant";

class LoadingOrErrorOrComponent extends Component {
    render() {
        if(this.props.is_loading) {
            return <Loader label={this.props.label} />
        } else if(this.props.something_went_wrong) {
            return <div className={"text-muted d-flex justify-content-center flex-fill align-items-center"}>
                {DEFAULT_ERROR_MSG}
            </div>
        }

        return (<this.props.component />);
    }
}

export default LoadingOrErrorOrComponent;