import React, {Component} from 'react';
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {isEmptyString} from "../../../itsmyweb/utils/validators/Validator";
import {BackendPOSTRequestWithImage} from "../../../itsmyweb/components/webrequests/BackendRequest";
import {UPLOAD_IMAGE_CUSTOM_WEB_PAGE_WIDGET_API} from "../../../itsmyweb/constants/backend/ApiConstant";
import {
    ShowErrorModalFromError
} from "../../../itsmyweb/components/itsmyweb/modals/centermodal/CenterModalScreenManager";

class ImageGalleryWidgetEdit extends Component {
    state = {
        imageUrl: "",
        imageBlob: null
    }

    constructor(props) {
        super(props);
        this.OnUpdateEditField = this.OnUpdateEditField.bind(this);
        this.OnChangeImage = this.OnChangeImage.bind(this);
        this.OnClickUploadImage = this.OnClickUploadImage.bind(this);
        this.OnSuccessImageUpload = this.OnSuccessImageUpload.bind(this);
        this.image_input = React.createRef();
    }

    OnChangeImage(event) {
        if(event.target.files.length  <= 0 ) {
            this.props.onChangeImage(this.props.index, "");
            return this.setState({
                imageUrl: "",
                imageBlob: null
            });
        }

        this.setState({
            imageUrl: URL.createObjectURL(event.target.files[0]),
            imageBlob: event.target.files[0]
        });

        this.props.onChangeImage(this.props.index, URL.createObjectURL(event.target.files[0]));
    }

    OnClickUploadImage() {
        let formData = new FormData();
        formData.set("businessId", this.props.businessId);
        formData.set("pageId", this.props.pageId);
        formData.set("widgetId", this.props.dataComponent.id);
        formData.set("image", this.state.imageBlob);

        BackendPOSTRequestWithImage(this.props, UPLOAD_IMAGE_CUSTOM_WEB_PAGE_WIDGET_API, formData, this.OnSuccessImageUpload, (error) => ShowErrorModalFromError(this.props, "Custom web page error", error));
    }

    OnSuccessImageUpload(response) {
        this.props.onChangeImage(this.props.index, response.body.image, true);
        this.setState({
            imageUrl: "",
            imageBlob: null
        });
    }

    OnUpdateEditField(event) {
        let value = event.target.value;
        if(event.target.type === "checkbox") {
            value = event.target.checked;
        }

        this.props.OnUpdateEditField(this.props.index, event.target.name, value);
    }

    render() {
        return (
            <div className={"position-relative"}>
                <div className={"position-absolute top-0 end-0"}>
                    <Button onClick={this.props.onClickDone} className={"rounded-0"}>
                        Done
                    </Button>
                </div>

                <h5 className={"itsmyweb-title p-2"}>Image gallery edit</h5>
                <div>
                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Parent classes
                        </label>
                        <FormControl value={this.props.dataComponent.parentClassName} onChange={this.OnUpdateEditField} name={"parentClassName"} placeholder={"Parent classes"} className={"shadow-none"} />
                    </div>

                    {
                        !isEmptyString(this.props.dataComponent.id) &&
                        <div className={"my-1 rounded"}>
                            <label className={"fw-bold small"}>
                                Select image
                            </label>
                            <InputGroup>
                                <FormControl ref={this.image_input} type={"file"} accept="image/*"
                                             className={"shadow-none"} onChange={this.OnChangeImage} name={"image"}/>
                                {
                                    !isEmptyString(this.state.imageUrl) &&
                                    <Button onClick={this.OnClickUploadImage}> Upload </Button>
                                }
                            </InputGroup>
                            {
                                !isEmptyString(this.state.imageUrl) &&
                                <p className={"text-muted small"}>
                                    Please do click on upload button to start uploading image for this widget
                                </p>
                            }

                            <div className={"d-flex"}>
                                {
                                    this.props.dataComponent.images.map((image, index) => <div key={"gallery_" + index} className={"p-1"} style={{height: 100}}>
                                        <img src={image} alt={"gal_im_" + index} className={"img-object-fit-contain hover-shadow rounded"} />
                                    </div>)
                                }
                            </div>
                        </div>
                    }

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Image parent classes
                        </label>
                        <FormControl value={this.props.dataComponent.imageParentClassName} onChange={this.OnUpdateEditField} name={"imageParentClassName"} placeholder={"Image parent classes"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Image classes
                        </label>
                        <FormControl value={this.props.dataComponent.imageClassName} onChange={this.OnUpdateEditField} name={"imageClassName"} placeholder={"Image classes"} className={"shadow-none"} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageGalleryWidgetEdit;