import {IMAGE_GALLERY, HEADING, IMAGE_WITH_TEXT, TEXT_WITH_PARA} from "./WidgetKey";

const dummyText = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Senectus et netus et malesuada fames ac turpis. Cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Proin sed libero enim sed faucibus turpis in. Nisi porta lorem mollis aliquam. Nullam non nisi est sit amet. Tincidunt augue interdum velit euismod in pellentesque massa placerat. Risus commodo viverra maecenas accumsan. Laoreet sit amet cursus sit amet dictum sit amet. Nibh venenatis cras sed felis. Purus faucibus ornare suspendisse sed nisi lacus sed viverra. Viverra nibh cras pulvinar mattis nunc sed blandit libero. Feugiat nisl pretium fusce id velit ut tortor. Sed turpis tincidunt id aliquet risus feugiat. Aliquam etiam erat velit scelerisque. Facilisi etiam dignissim diam quis enim lobortis. Faucibus et molestie ac feugiat sed lectus vestibulum mattis. At varius vel pharetra vel turpis nunc eget. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit. Nibh nisl condimentum id venenatis a condimentum. Vivamus at augue eget arcu dictum varius. Scelerisque eleifend donec pretium vulputate. Leo duis ut diam quam nulla porttitor tincidunt tortor aliquam nulla.";

export function widgetHeader1() {
    return {
        widgetKey: HEADING,
        parentClassName: "text-center p-2",
        headerClassName: "display-3 fw-bold",
        headerType: "h-1",
        centerLine: false,
        content: "This is the heading"
    };
}

export function widgetHeader2() {
    return {
        widgetKey: HEADING,
        parentClassName: "text-center p-2",
        headerClassName: "display-4 fw-bold",
        headerType: "h-1",
        centerLine: false,
        content: "This is the heading"
    };
}

export function widgetHeader3() {
    return {
        widgetKey: HEADING,
        parentClassName: "text-center p-2",
        headerClassName: "fw-bold",
        headerType: "h-1",
        centerLine: false,
        content: "This is the heading"
    };
}

export function widgetHeader4() {
    return {
        widgetKey: HEADING,
        parentClassName: "p-2 pb-0",
        headerClassName: "fw-bold",
        headerType: "h-1",
        centerLine: false,
        content: "This is the heading"
    };
}


export function widgetHeader5() {
    return {
        widgetKey: HEADING,
        parentClassName: "p-2 pt-0",
        headerClassName: "text-muted",
        headerType: "h-6",
        centerLine: false,
        content: "This is the subheading"
    };
}

export function widgetTextWithPara() {
    return {
        widgetKey: TEXT_WITH_PARA,
        parentClassName: "p-2",
        textClassName: "text-dark",
        content: dummyText
    };
}

export function widgetImageWithText() {
    return {
        widgetKey: IMAGE_WITH_TEXT,
        parentClassName: "p-2",
        imageParentClassName: "col-md-6",
        imageClassName: "rounded w-100",
        textClassName: "px-2 col-md-6",
        image: "",
        imagePosition: "left",
        content: dummyText
    };
}

export function widgetImageWithText2() {
    return {
        widgetKey: IMAGE_WITH_TEXT,
        parentClassName: "p-2",
        imageParentClassName: "col-md-6",
        imageClassName: "rounded w-100",
        textClassName: "px-2 col-md-6",
        image: "",
        imagePosition: "right",
        content: dummyText
    };
}

export function widgetImageWithText3() {
    return {
        widgetKey: IMAGE_WITH_TEXT,
        parentClassName: "p-2",
        imageParentClassName: "col-md-12",
        imageClassName: "rounded w-100",
        textClassName: "",
        image: "",
        imagePosition: "left",
        content: ""
    };
}

export function widgetGallery() {
    return {
        widgetKey: IMAGE_GALLERY,
        parentClassName: "p-2",
        imageParentClassName: "p-1",
        imageClassName: "w-100",
        images: [
            "","",
            "","",
            "","",
        ]
    };
}

