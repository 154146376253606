import {Card} from "react-bootstrap";
import BusinessImage from "../../../../statics/images/business.webp";
import React from "react";

export function ServiceAccount({name, type, approved}) {
    return <Card className={"hover-shadow shadow-sm h-100"}>
        <Card.Img variant="top" src={BusinessImage} />
        <Card.Body className={"p-2"}>
            <Card.Title className={"fw-bold"}>{name}</Card.Title>
            <Card.Subtitle className={"text-muted small"}>
                {type}
                {
                    approved ||
                    "Your service/business account is under review."
                }
            </Card.Subtitle>
        </Card.Body>
    </Card>
}