import {Component} from "react";
import {Button, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Connector} from "../../../../../redux/Connector";

class Profile extends Component {
    render(){
        return(
            <Container fluid className="p-0">
                <div style={{background: 'url(https://images3.alphacoders.com/106/1062607.jpg) 100%', height: '180px', position: 'relative'}}>
                    <div className={"p-1 bg-white rounded-lg"} style={{position: 'absolute', bottom: -100, left: 40}}>
                        <img src="https://images3.alphacoders.com/106/1062607.jpg" style={{height:200, width:200}} alt={"profile"}/>
                    </div>
                </div>
                <div className={"d-flex flex-wrap p-2"}>
                    <div className="" style={{minWidth:280}}>
                        <div style={{height: 120}} />

                    </div>
                    <div className={"p-2 flex-grow-1 bg-white"}>
                        <p className={"fw-bold"}>{this.props.user.firstName} {this.props.user.lastName}</p>

                        <Tabs
                            id="controlled-tab-example"
                            defaultActiveKey={"about"}
                        >
                            <Tab eventKey="about" title={
                                <div>
                                    <FontAwesomeIcon icon={"user"} /> About
                                </div>
                            }>
                                <p className="font-weight-bold text-secondary mb-1 mt-3">Contact Information</p>
                                <Row>
                                    <Col md={6}> Contact No</Col>
                                    <Col md={6}> 8419027520</Col>
                                    <Col md={6}> Primary Email</Col>
                                    <Col md={6}> onego.help@gmail.com</Col>
                                    <Col md={6}> Site</Col>
                                    <Col md={6}> https://google.com</Col>
                                </Row>
                                <p className="font-weight-bold text-secondary mb-1 mt-3">Personal Information</p>
                                <Row>
                                    <Col md={6}> Birthday</Col>
                                    <Col md={6}> 19/04/1996</Col>
                                    <Col md={6}> Gender</Col>
                                    <Col md={6}> Male</Col>
                                </Row>
                            </Tab>

                            <Tab eventKey="address" title={
                                <div>
                                    <FontAwesomeIcon icon={"user"} /> Address
                                </div>
                            }>
                                <Button className={"m-2"}>Manage Address</Button>
                            </Tab>

                        </Tabs>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Connector(Profile);