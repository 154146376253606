import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {CUSTOM_PAGE_MANAGE_URL} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import {COL} from "../../../../../constants/application/ApplicationUrl";
import {SERVICE_ACCOUNT_PARAM} from "../../../../../constants/ParamConstant";
import {DEFAULT_ERROR_MSG} from "../../../../../constants/Constant";

export function OnPageLoadErrorComponent({serviceAccountId}) {
    return <div className={"d-flex flex-column flex-fill justify-content-center align-items-center"}>
        <p className={"text-secondary"}>
            {DEFAULT_ERROR_MSG}
        </p>
        <Button as={Link} to={CUSTOM_PAGE_MANAGE_URL.replace(COL + SERVICE_ACCOUNT_PARAM, serviceAccountId)}>
            Custom Web Page Setting
        </Button>
    </div>;
}