import HeadingWidget from "./component/HeadingWidget";
import TextParaWidget from "./component/TextParaWidget";
import ImageWithTextWidget from "./component/ImageWithTextWidget";
import {CAROUSEL, HEADING, IMAGE_GALLERY, IMAGE_WITH_TEXT, TEXT_WITH_PARA, SERVICE_TRACK} from "./WidgetKey";
import ImageGalleryWidget from "./component/ImageGalleryWidget";
import CarouselWidget from "./component/CarouselWidget";
import ServiceTrackingWidget from "./component/ServiceTrackingWidget";

export const WIDGET_TYPES = {
    HEADING : {
        code: HEADING,
        name: "Heading",
        component: (props) => <HeadingWidget {...props} />
    },
    TEXT_WITH_PARA: {
        code: TEXT_WITH_PARA,
        name: "Test with paragraph",
        component: (props) => <TextParaWidget {...props} />
    },
    IMAGE_WITH_TEXT: {
        code: IMAGE_WITH_TEXT,
        name: "Image with text",
        component: (props) => <ImageWithTextWidget {...props} />
    },
    IMAGE_GALLERY: {
        code: IMAGE_GALLERY,
        name: "Image gallery",
        component: (props) => <ImageGalleryWidget {...props} />
    },
    SERVICE_TRACK: {
        code: SERVICE_TRACK,
        name: "Tracking service",
        component: (props) => <ServiceTrackingWidget {...props} />
    },
    CAROUSEL: {
        code: CAROUSEL,
        name: "Image carousel",
        component: (props) => <CarouselWidget {...props} />
    }
};