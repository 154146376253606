import {Form, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export function ContactUsSection({footer, OnChangeInput}) {
    return <div className={"col-md-6 p-1"}>
        <div className={"shadow-sm p-2 rounded border h-100"}>
            <h5 className={"fw-bold"}>Contact {footer.name}</h5>
            <Form.Check type={"switch"} className={"shadow-none py-1"} label={"Enable"}
                        checked={footer.contact_us.enabled} name={"contact_us.enabled"} onChange={OnChangeInput} />
            <div>
                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2  d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={"envelope"} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>Email</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.email?.data || ""}
                                     name={"contact_us.contacts.email.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"Email"}/>
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.email?.enabled || false} name={"contact_us.contacts.email.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2  d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={"phone"} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>Contact</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.contact?.data || ""}
                                     name={"contact_us.contacts.contact.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"Contact"} type={"number"}/>
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.contact?.enabled || false} name={"contact_us.contacts.contact.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2  d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={["fab", "whatsapp"]} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>WhatsApp</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.whatsapp?.data || ""}
                                     name={"contact_us.contacts.whatsapp.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"whatsapp"} />
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.whatsapp?.enabled || false} name={"contact_us.contacts.whatsapp.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2  d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={["fab", "facebook"]} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>Facebook</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.facebook?.data || ""}
                                     name={"contact_us.contacts.facebook.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"facebook"} />
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.facebook?.enabled || false} name={"contact_us.contacts.facebook.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2  d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={["fab", "instagram"]} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>Instagram</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.instagram?.data || ""}
                                     name={"contact_us.contacts.instagram.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"instagram"} />
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.instagram?.enabled || false} name={"contact_us.contacts.instagram.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2 d-flex justify-content-center align-items-center"}>
                        <FontAwesomeIcon icon={["fab", "youtube"]} size={"3x"}/>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>Youtube</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.youtube?.data || ""}
                                     name={"contact_us.contacts.youtube.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"youtube"} />
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.youtube?.enabled || false} name={"contact_us.contacts.youtube.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>

                <div className={"my-1 d-flex bg-light border rounded"}>
                    <div className={"col-2 p-2 d-flex justify-content-center align-items-center"}>
                        <span className={"fw-bold"} style={{color: "#000", fontSize: 48}}>J</span>
                        <span className={""} style={{color: "#000", fontSize: 48}}>d</span>
                    </div>
                    <div className={"flex-fill p-2"}>
                        <label className={"fw-bold"}>JustDail</label>
                        <FormControl className={"shadow-none"}
                                     value={footer.contact_us.contacts.jd?.data || ""}
                                     name={"contact_us.contacts.jd.data"}
                                     onChange={OnChangeInput}
                                     placeholder={"jd"} />
                        <Form.Check type={"switch"} className={"shadow-none"} label={"Enable"}
                                    checked={footer.contact_us.contacts.jd?.enabled || false} name={"contact_us.contacts.jd.enabled"} onChange={OnChangeInput} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}