import {connect} from "react-redux";
import {setToken, resetUserData, setUserName, setFirstName, setLastName} from "./user/user.actions";
import {setErrorModalScreen} from "./modal/modal.actions";

const mapStateToProps = ({ user, modal }) => (
    {
        user: user,
        modal: modal
    }
)

const mapDispatchToProps = dispatch => ({
    // modal
    setErrorModalScreen: modelData => dispatch(setErrorModalScreen(modelData)),

    // user
    setUserName: username => dispatch(setUserName(username)),
    setToken: token => dispatch(setToken(token)),
    setFirstName: token => dispatch(setFirstName(token)),
    setLastName: token => dispatch(setLastName(token)),
    resetUserData: () => dispatch(resetUserData())
})

export const Connector = (component) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}