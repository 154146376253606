import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Connector} from "../../redux/Connector";
import {GetUser} from "../itsmyweb/components/webrequests/requestwrapper/Authenticate";
import {isEmptyString} from "../itsmyweb/utils/validators/Validator";
import ItsMyWebUrlsComponent from "../itsmyweb/urls/ItsMyWebUrlsComponent";
import PublicUrlsComponent from "../public/urls/PublicUrlsComponent";
import './App.css';
import {LOGIN_PAGE_URL} from "../itsmyweb/constants/application/ApplicationUrl";
import LoginPage from "../itsmyweb/components/user/LoginPage";
import {GetServiceAccountNameFromDomain} from "../itsmyweb/utils/ItsMyWebUtil";
import CenterScreenModal from "../itsmyweb/components/itsmyweb/modals/centermodal/CenterScreenModal";

class App extends Component {
    constructor(props) {
        super(props);
        this.GetApplicationUrls = this.GetApplicationUrls.bind(this);
    }

    componentDidMount() {
        GetUser(this.props);
    }

    GetApplicationUrls() {
        if(isEmptyString(GetServiceAccountNameFromDomain())) {
            return <ItsMyWebUrlsComponent />;
        }

        return <PublicUrlsComponent />;
    }

    render() {
        return (
            <div className="App d-flex flex-column">
                <Router>
                    <Switch>
                        <Route path={LOGIN_PAGE_URL} exact={true} component={LoginPage} />
                        <this.GetApplicationUrls />
                    </Switch>
                </Router>

                <CenterScreenModal />
            </div>
        );
    }
}

export default Connector(App);
