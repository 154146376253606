import React from "react";

export function FAQBlock({title, faqs}) {
    return <div className={"p-4  my-2 shadow-sm rounded bg-white"}>
        <h4>{title}</h4>
        {
            faqs.map((faq, index) => <div key={"faq_index_" + index}>
                <h6 className={"fw-bold"}>{faq.question}</h6>
                <p>{faq.answer}</p>
            </div>)
        }
    </div>;
}