import React, {Component} from 'react';
import {Button, Container} from "react-bootstrap";
import {CONTACT_US_URL} from "../../../../constants/application/ApplicationUrl";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import help from "../../../../statics/images/help.png";

class HelpAndSupportHome extends Component {
    render() {
        return (
            <div className={"bg-white"}>
                <Container className={"py-4"}>
                    <h1 className={"fs_heading fw-bold m-0 px-1"}>
                        Help & Support
                    </h1>
                    <div className={"d-flex py-4"}>
                        <div className={"bg-light p-2 rounded"}>
                            <div className={"pb-2"}>
                                Are you looking for contact us ?
                            </div>
                            <Button as={Link} to={CONTACT_US_URL}>
                                <FontAwesomeIcon icon={"user"} /> Contact us
                            </Button>
                        </div>
                    </div>
                    <div className={"d-flex py-4"}>
                        <div className={"text-start"} style={{height: 400}} >
                            <img src={help} alt={"help"} className={"img-object-fit-contain"} />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default HelpAndSupportHome;