import React, {Component} from 'react';
import {Button, Form, FormControl} from "react-bootstrap";

class HeaderWidgetEdit extends Component {

    constructor(props) {
        super(props);
        this.OnUpdateEditField = this.OnUpdateEditField.bind(this);
    }


    OnUpdateEditField(event) {
        let value = event.target.value;
        if(event.target.type === "checkbox") {
            value = event.target.checked;
        }

        this.props.OnUpdateEditField(this.props.index, event.target.name, value);
    }

    render() {
        return (
            <div className={"position-relative"}>
                <div className={"position-absolute top-0 end-0"}>
                    <Button onClick={this.props.onClickDone} className={"rounded-0"}>
                        Done
                    </Button>
                </div>

                <h5 className={"itsmyweb-title p-2"}>Header widget edit</h5>
                <div>
                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Parent classes
                        </label>
                        <FormControl value={this.props.dataComponent.parentClassName} onChange={this.OnUpdateEditField} name={"parentClassName"} placeholder={"Parent classes"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Header classes
                        </label>
                        <FormControl value={this.props.dataComponent.headerClassName} onChange={this.OnUpdateEditField} name={"headerClassName"} placeholder={"Header classes"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Header type
                        </label>
                        <Form.Select value={this.props.dataComponent.headerType} className={"shadow-none"} onChange={this.OnUpdateEditField} name={"headerType"} >
                            <option value={"h-1"}>h1</option>
                            <option value={"h-2"}>h2</option>
                            <option value={"h-3"}>h3</option>
                            <option value={"h-4"}>h4</option>
                            <option value={"h-5"}>h5</option>
                            <option value={"h-6"}>h6</option>
                        </Form.Select>
                    </div>

                    <div className={"my-1 rounded"}>
                        <Form.Check type={"switch"} checked={String(this.props.dataComponent.centerLine) === "true"} onChange={this.OnUpdateEditField} name={"centerLine"} label={"Show center line"} className={"shadow-none"} />
                    </div>

                    <div className={"my-1 rounded"}>
                        <label className={"fw-bold small"}>
                            Header content
                        </label>
                        <FormControl value={this.props.dataComponent.content} onChange={this.OnUpdateEditField} name={"content"} placeholder={"Header content"} className={"shadow-none"} />
                    </div>

                </div>
            </div>
        );
    }
}

export default HeaderWidgetEdit;