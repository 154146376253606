import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {CONTACT_US_URL, FEEDBACK_URL, HELP_AND_SUPPORT_URL} from "../../../constants/application/ApplicationUrl";

export default function Footer() {
    return(
        <section className={"bg-dark border-top border-secondary"} style={{background:"#343a40"}}>
            <Container className={"text-white py-4"}>
                <Row>
                    <Col>
                        <Link to={HELP_AND_SUPPORT_URL} className={"no-text-decor"}>
                            <p>
                                <b>Help & Support</b>
                            </p>
                        </Link>
                        <Link to={FEEDBACK_URL} className={"no-text-decor"}>
                            <p>
                                <b>Feedback</b>
                            </p>
                        </Link>
                    </Col>
                    <Col>
                        <Link to={CONTACT_US_URL} className={"no-text-decor"}>
                            <div>
                                <b>Contact Us</b>
                            </div>
                        </Link>
                        <div>
                            <a href="mailto:support@itsmyweb.in" rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                <p className={"my-1"}>
                                    <FontAwesomeIcon icon={"envelope"} className={"me-1"} />
                                    support@itsmyweb.in
                                </p>
                            </a>
                            <a href={"https://www.facebook.com/itsmywebsite"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                <p className={"my-1"}>
                                    <FontAwesomeIcon icon={["fab", "facebook"]} className={"me-1"} />
                                    Facebook
                                </p>
                            </a>
                            <a href={"https://www.instagram.com/itsmyweb.in"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                <p className={"my-1"}>
                                    <FontAwesomeIcon icon={["fab", "instagram"]} className={"me-1"} />
                                    Instagram
                                </p>
                            </a>
                            <a href={"https://wa.me/918142022097?text=Hi%20ItsMyWeb"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                <p className={"my-1"}>
                                    <FontAwesomeIcon icon={["fab", "whatsapp"]} className={"me-1"}/>
                                    8142022097
                                </p>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className={"text-secondary border-top border-secondary text-center"}>
                <b>ItsMyWeb</b>
                <span className={"small px-1"}>
                    Grow your business & reach more customers
                </span>
            </div>
        </section>
    );
}