import React, {Component} from 'react';
import {Col, Form, Row} from "react-bootstrap";

class NewAddress extends Component {

    state = {
        states_choice: [
            {
                "code": "MH",
                "name": "Maharashtra"
            },
            {
                "code": "KA",
                "name": "Karnataka"
            },
            {
                "code": "UP",
                "name": "Uttar Pradesh"
            },
            {
                "code": "00",
                "name": "Other"
            }
        ]
    }

    render() {
        return (
            <div>
                <h5 className={'fw-bold'}>{this.props.header || "New Address"}</h5>

                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative pe-1"}>
                            <Form.Label className="input-top">First Name</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"firstName"} type="text" minLength={2} maxLength={20} required/>
                        </Form.Group>
                        <Form.Group as={Col} className={"position-relative ps-1"}>
                            <Form.Label className="input-top">Last Name</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"lastName"} type="text" minLength={2} maxLength={20} />
                        </Form.Group>
                    </Row>

                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative"}>
                            <Form.Label className="input-top">Mobile No</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"mobile"} type="number" min="1000000000" required />
                        </Form.Group>
                        <Form.Group as={Col} className={"position-relative px-2"}>
                            <Form.Label className="input-top">Pincode</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"pinCode"} type="number" min="100000" required />
                        </Form.Group>
                        <Form.Group as={Col} className={"position-relative"}>
                            <Form.Label className="input-top">State</Form.Label>
                            <Form.Select className="shadow-none" name={"state"} required>
                                {
                                    this.state.states_choice.map( state_name => {
                                            return (
                                                <option value={state_name.code} key={"state_" + state_name.code}>{state_name.name}</option>
                                            );
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative pe-1"}>
                            <Form.Label className="input-top">City</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name="city" minLength={2} maxLength={20} required />
                        </Form.Group>
                        <Form.Group as={Col} className={"position-relative ps-1"}>
                            <Form.Label className="input-top">House No, street</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"addressLine1"} minLength={2} maxLength={40} required />
                        </Form.Group>
                    </Row>
                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative"}>
                            <Form.Label className="input-top">Place, Landmark</Form.Label>
                            <Form.Control className={"shadow-none validate-input"} name={"landmark"} maxLength={50}/>
                        </Form.Group>
                    </Row>
                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative"}>
                            <Form.Label className="input-top">Address Line 2 (optional)</Form.Label>
                            <Form.Control className="shadow-none validate-input" name={"addressLine2"} maxLength={40} />
                        </Form.Group>
                    </Row>
                    <Row className={"g-0 py-3"}>
                        <Form.Group as={Col} className={"position-relative"}>
                            <Form.Label className="input-top">Instruction (optional)</Form.Label>
                            <Form.Control className="shadow-none validate-input" name={"instruction"} maxLength={50} />
                        </Form.Group>
                    </Row>
            </div>
        );
    }
}

export default NewAddress;