import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {
    CREATE_SERVICE_ACCOUNT_URL,
    MY_SERVICES_URL, MY_SINGLE_SERVICE_URL
} from "../../../../../constants/application/account/services/ServiceAccountUrls";
import MyServiceAccountHome from "../MyServiceAccountHome";
import CreateServiceAccount from "../CreateServiceAccount";
import SingleServiceAccountHome from "../SingleServiceAccountHome";

class ServiceAccountUrlComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path={MY_SERVICES_URL} exact={true} component={MyServiceAccountHome}/>
                <Route path={CREATE_SERVICE_ACCOUNT_URL} exact={true} component={CreateServiceAccount}/>
                <Route path={MY_SINGLE_SERVICE_URL} component={SingleServiceAccountHome} />
            </Switch>
        );
    }
}

export default ServiceAccountUrlComponent;