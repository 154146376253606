import {GetAppBackendUrl} from "../../utils/ItsMyWebUtil";
import {PAGE_ID_PARAM, SERVICE_ACCOUNT_PARAM, TRACKING_ID_PARAM} from "../ParamConstant";

const PUBLIC_SERVICE_ACCOUNTS_API =  GetAppBackendUrl() + "/api/service/public";
const PUBLIC_HOME_API = PUBLIC_SERVICE_ACCOUNTS_API + "/home/" + SERVICE_ACCOUNT_PARAM;

export const PUBLIC_GET_ABOUT_US_API = PUBLIC_HOME_API + "/about_us"; // TODO - DELETE
export const PUBLIC_IS_ENABLED_ABOUT_US_API = PUBLIC_GET_ABOUT_US_API + "/enabled"; // TODO - DELETE

export const PUBLIC_GET_NAVBAR_API = PUBLIC_HOME_API + "/navbar";
export const PUBLIC_GET_FOOTER_API = PUBLIC_HOME_API + "/footer";
export const PUBLIC_GET_WEB_PAGE_API = PUBLIC_HOME_API + "/webpage/page/" + PAGE_ID_PARAM;

const PUBLIC_TRACKING_API = PUBLIC_SERVICE_ACCOUNTS_API + "/tracking/" + SERVICE_ACCOUNT_PARAM;
export const PUBLIC_TRACKING_SERVICE_API = PUBLIC_TRACKING_API + "/tracking/" + TRACKING_ID_PARAM;

