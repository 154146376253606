import {TRACKING_ID_PARAM} from "../ParamConstant";

export const HOME_URL = "/";
export const COL = ":";

export const PARAM_SEPARATOR = "/" + COL;
export const ITS_MY_WEB_PRICING_URL = "/plan/pricing";
export const TRACKING_SERVICE_URL = "/tracking/service" + PARAM_SEPARATOR + TRACKING_ID_PARAM;

export const ACCOUNT_URL = "/account";
export const ACCOUNT_PROFILE_URL = ACCOUNT_URL + "/profile";
export const LOGIN_PAGE_URL = ACCOUNT_URL + "/login";

export const CONTACT_US_URL = "/contact_us";
export const FEEDBACK_URL = "/feedback";
export const HELP_AND_SUPPORT_URL = "/help";