import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function SmallPanel({label, content, to, icon}) {
    return <Col className={"p-2"} md={6} lg={4}>
        <Link className={"shadow-sm rounded hover-shadow p-3 bg-white no-text-decor d-flex"} to={to}>
            <div className={"px-2"}>
                <FontAwesomeIcon icon={icon} style={{fontSize: 54, width: 60}}/>
            </div>
            <div className={"px-2 overflow-hidden"}>
                <p className={"font-weight-bold m-0"}> {label} </p>
                <div className={"text-secondary text-truncate"}> {content} </div>
            </div>
        </Link>
    </Col>;
}