export function CreateAddressPayload(addressComponent) {
    return {
        firstName: addressComponent.current.state.first_name,
        lastName: addressComponent.current.state.last_name,
        mobile: addressComponent.current.state.mobile_number,
        pinCode: addressComponent.current.state.pincode,
        state: addressComponent.current.state.state,
        city: addressComponent.current.state.city,
        addressLine1: addressComponent.current.state.address_line_1,
        landmark: addressComponent.current.state.landmark,
        addressLine2: addressComponent.current.state.address_line_2,
        instruction: addressComponent.current.state.instruction
    };
}