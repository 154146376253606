import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PublicServiceFooter extends Component {

    constructor(props) {
        super(props);
        this.AboutUs = this.AboutUs.bind(this);
        this.Policies = this.Policies.bind(this);
        this.ContactUs = this.ContactUs.bind(this);
        this.Address = this.Address.bind(this);
    }

    AboutUs() {
        if(!this.props.footer.about_us.enabled) {
            return <></>;
        }

        return <Col>
            <div>
                <b>About Us</b>
                <div className={"text-secondary"}>
                    <p>{this.props.footer.name}</p>
                    {
                        this.props.footer.about_us.pages.map((page, index) => <div key={"au_page_" + index}>
                            <Link to={page.link} className={"no-text-decor"}>{page.name}</Link>
                        </div>)
                    }
                </div>
            </div>
        </Col>;
    }

    Policies() {
        if(!this.props.footer.policies.enabled) {
            return <></>;
        }

        return <Col>
            <div>
                <b>Policies</b>
                <div className={"text-secondary"}>
                    {
                        this.props.footer.policies.pages.map((page, index) => <div key={"au_page_" + index}>
                            <Link to={page.link} className={"no-text-decor"}>{page.name}</Link>
                        </div>)
                    }
                </div>
            </div>
        </Col>;
    }

    ContactUs() {
        if(!this.props.footer.contact_us.enabled) {
            return <></>;
        }

        return <Col>
            <div>
                <b>Contact {this.props.footer.name}</b>
                <div className={"text-secondary"}>
                    <div className={"pb-3"}>
                        {
                            this.props.footer.contact_us.contacts.email?.data &&
                            this.props.footer.contact_us.contacts.email?.enabled &&
                            <p className={"m-0"}> Email: {this.props.footer.contact_us.contacts.email.data}</p>
                        }
                        {
                            this.props.footer.contact_us.contacts.contact?.data &&
                            this.props.footer.contact_us.contacts.contact?.enabled &&
                            <p className={"m-0"}> Contact: {this.props.footer.contact_us.contacts.contact.data}</p>
                        }
                    </div>
                    {
                        this.props.footer.contact_us.contacts.whatsapp?.data &&
                        this.props.footer.contact_us.contacts.whatsapp?.enabled &&
                        <a href={"https://wa.me/" + this.props.footer.contact_us.contacts.whatsapp.data}
                           className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                            <div className={"pe-1"}>
                                <FontAwesomeIcon icon={["fab", "whatsapp"]} color={"white"}/>
                            </div>
                            <p className={"m-0"}>
                                WhatsApp
                            </p>
                        </a>
                    }
                    {
                        this.props.footer.contact_us.contacts.facebook?.data &&
                        this.props.footer.contact_us.contacts.facebook?.enabled &&
                        <a href={this.props.footer.contact_us.contacts.facebook.data}
                           className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                            <div className={"pe-1"}>
                                <FontAwesomeIcon icon={["fab", "facebook"]} color={"white"}/>
                            </div>
                            <p className={"m-0"}>
                                Facebook
                            </p>
                        </a>
                    }
                    {
                        this.props.footer.contact_us.contacts.instagram?.data &&
                        this.props.footer.contact_us.contacts.instagram?.enabled &&
                        <a href={this.props.footer.contact_us.contacts.instagram.data}
                           className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                            <div className={"pe-1"}>
                                <FontAwesomeIcon icon={["fab", "instagram"]} color={"white"}/>
                            </div>
                            <p className={"m-0"}>
                                Instagram
                            </p>
                        </a>
                    }
                    {
                        this.props.footer.contact_us.contacts.youtube?.data &&
                        this.props.footer.contact_us.contacts.youtube?.enabled &&
                        <a href={this.props.footer.contact_us.contacts.youtube.data}
                           className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                            <div className={"pe-1"}>
                                <FontAwesomeIcon icon={["fab", "youtube"]} color={"white"}/>
                            </div>
                            <p className={"m-0"}>
                                YouTube
                            </p>
                        </a>
                    }
                    {
                        this.props.footer.contact_us.contacts.jd?.data &&
                        this.props.footer.contact_us.contacts.jd?.enabled &&
                        <a href={this.props.footer.contact_us.contacts.jd.data}
                           className={"no-text-decor d-flex"} rel={"noreferrer"} target={"_blank"}>
                            <div className={"pe-1"}>
                                <span className={"fw-bold"} style={{color: "#fff", fontSize: 16}}>J</span>
                                <span className={""} style={{color: "#fff", fontSize: 16}}>d</span>
                            </div>
                            <p className={"m-0"}>
                                JustDial
                            </p>
                        </a>
                    }
                </div>
            </div>
        </Col>;
    }

    Address() {
        if(!this.props.footer.address.enabled) {
            return <></>;
        }

        return <Col>
            <div>
                <b>Address {this.props.footer.name}</b>
                <div className={"text-secondary"}>
            <pre>
                {
                    this.props.footer.address.address
                }
            </pre>
                </div>
            </div>
        </Col>;
    }

    render() {
        if(!this.props.footer.enabled) {
            return <></>
        }

        return (
            <section className={"bg-dark p-4"}>
                <Container className={"text-white"}>
                    <Row>
                        <this.AboutUs/>
                        <this.Policies/>
                        <this.ContactUs/>
                        <this.Address/>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default PublicServiceFooter;