import React, {Component} from 'react';
import {Alert, Button, Container, Form, FormControl, Spinner} from "react-bootstrap";
import feedback_image from "../../../statics/images/feedback.png";
import {JsonFromFormEvent} from "../../../utils/FormManager";
import {BackendPOSTRequest} from "../../webrequests/BackendRequest";
import {ITS_MY_WEB_FEEDBACK_API} from "../../../constants/backend/ApiConstant";
import SignInButton from "../../general/SignInButton";
import {Connector} from "../../../../../redux/Connector";

class Feedback extends Component {
    state = {
        is_loading: false,
        something_went_wrong: false,
        feedback_success: false
    }

    constructor(props) {
        super(props);
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
        this.FeedbackForm = this.FeedbackForm.bind(this);
    }

    OnSubmitForm(event) {
        event.preventDefault();

        let formJsonData = JsonFromFormEvent(event);
        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, ITS_MY_WEB_FEEDBACK_API, formJsonData, (response) => {
            this.setState({
                is_loading: false,
                something_went_wrong: false,
                feedback_success: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        feedback_success: false
                    });
                }, 4000);
            });
        }, (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true,
                feedback_success: false
            }, () => {
                setTimeout(() => {
                    this.setState({
                        something_went_wrong: false
                    });
                }, 4000);
            });
        });

        return false;
    }

    FeedbackForm() {
        return <form onSubmit={this.OnSubmitForm}>
            <div className={"p-2"}>
                <label className={"small fw-bold"}>
                    What can be improved ?
                </label>
                <div className={"p-2"}>
                    <Form.Check label={"Content"} name={"check"} />
                    <Form.Check label={"Images"} name={"check"} />
                    <Form.Check label={"Feature"} name={"check"} />
                    <Form.Check label={"User Interface"} name={"check"} />
                </div>
                <label className={"small fw-bold"}>
                    Feedback
                </label>
                <FormControl as={"textarea"} className={"shadow-none"} name={"feedback"} required />
                <div className={"pt-2"}>
                    <Alert show={this.state.feedback_success} variant="success">
                        Your feedback is submitted successfully !
                    </Alert>
                    <Alert show={this.state.something_went_wrong} variant="danger">
                        Something went wrong
                    </Alert>
                </div>
                <Button className={"my-2 shadow-none"} type={"submit"} disabled={this.state.is_loading}>
                    {
                        this.state.is_loading &&
                        <Spinner animation={"border"} size={"sm"} />
                    } Submit feedback
                </Button>
            </div>
        </form>
    }

    render() {
        return (
            <div className={"bg-white"}>
                <Container className={"py-4"}>
                    <h1 className={"fs_heading fw-bold m-0 px-1"}>
                        Feedback
                    </h1>
                    <p className={"text-muted fw-bold px-1"}>
                        Your feedback is valuable to us, please provide feedback if you have anything that can be better or improved
                    </p>

                    {
                        this.props.user.username ? <this.FeedbackForm /> : <div className={"px-1"}>
                            <p className={"text-muted"}>Please do sign in first to give your feedback</p>
                            <SignInButton />
                        </div>
                    }

                    <div className={"text-center"} style={{height: 400}} >
                        <img src={feedback_image} alt={"feedback"} className={"img-object-fit-contain"} />
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(Feedback);