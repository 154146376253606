import React, {Component} from 'react';
import {Accordion, Badge, FormControl, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Connector} from "../../../../../redux/Connector";
import {SERVICE_ACCOUNT_PARAM, TRACKING_ID_PARAM} from "../../../../itsmyweb/constants/ParamConstant";
import {TrackServiceType} from "../../../../itsmyweb/constants/TrackServiceType";
import {
    ShowErrorModalFromError
} from "../../../../itsmyweb/components/itsmyweb/modals/centermodal/CenterModalScreenManager";
import {BackendGETRequest} from "../../../../itsmyweb/components/webrequests/BackendRequest";
import {DEFAULT_ERROR_MSG} from "../../../../itsmyweb/constants/Constant";
import Loader from "../../../../itsmyweb/components/itsmyweb/loader/Loader";
import {PUBLIC_TRACKING_SERVICE_API} from "../../../../itsmyweb/constants/backend/ApiPublicConstant";
import LoadingOrErrorOrComponent from "../../../../itsmyweb/components/general/LoadingOrErrorOrComponent";

class ShowPublicTrackingService extends Component {
    state = {
        is_loading: true,
        something_went_wrong: false,
        service: {},
    }

    constructor(props) {
        super(props);
        this.TrackServiceListComponent = this.TrackServiceListComponent.bind(this);
        this.OrderStatus = this.OrderStatus.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, PUBLIC_TRACKING_SERVICE_API.replace(SERVICE_ACCOUNT_PARAM, this.props.match.params[SERVICE_ACCOUNT_PARAM]).replace(TRACKING_ID_PARAM, this.props.match.params[TRACKING_ID_PARAM]), (response) => this.setState({
            is_loading: false,
            service: response.body,
        }), (error) => {
            this.setState({
                is_loading: false,
                something_went_wrong: true
            });

            ShowErrorModalFromError(this.props, "Tracking services", error);
        });
    }

    OrderStatus() {
        switch (this.state.service.state) {
            case TrackServiceType.START:
                return "Your service has been started";
            case TrackServiceType.WORK_IN_PROGRESS:
                return "We are working on your service";
            case TrackServiceType.WAITING_FOR_SPARE_PARTS:
                return "We are waiting for your spare part";
            case TrackServiceType.COMPLETED:
                return "Your service has been completed";
            default:
                return "Your service is in progress";
        }
    }


    TrackServiceListComponent() {
        if (this.state.something_went_wrong) {
            return <div className={"d-flex flex-fill justify-content-center align-items-center text-muted"}>
                {DEFAULT_ERROR_MSG}
            </div>
        }

        if (this.state.is_loading) {
            return <Loader label={"Loading tracking services"}/>
        }

        return <div className={"d-flex flex-column flex-fill"}>
            <div className={"bg-white rounded shadow-sm p-2"}>
                <h4>
                    Service Tracking ({this.state.service.trackingNumber})
                </h4>
                <p className={"text-secondary m-0"}>
                    Manage single service tracking
                </p>
            </div>
            <div className={"bg-light rounded g-0 my-2 flex-fill"}>
                <FormControl name="id" defaultValue={this.state.service.id} className={"visually-hidden"}/>

                <div className={"d-flex flex-wrap p-1"}>
                    <div className={"p-1 col-md-4 flex-fill"}>
                        <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                            <Table borderless className={""}>
                                <tr>
                                    <td className={"fw-bold"}>
                                        Bill Number
                                    </td>
                                    <td>
                                        {this.state.service.billNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"fw-bold"}>
                                        Tracking Number
                                    </td>
                                    <td>
                                        {this.state.service.trackingNumber}
                                    </td>
                                </tr>
                            </Table>
                        </div>
                    </div>
                    <div className={"p-1 col-md-4 flex-fill"}>
                        <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                            <div className={"fw-bold text-primary"}>
                                {this.state.service.customerName}
                            </div>
                            <div className={"small fw-bold text-muted"}>
                                {this.state.service.customerMobile}
                            </div>
                            <div className={"small text-muted"}>
                                {this.state.service.customerAddress}
                            </div>
                        </div>
                    </div>
                    <div className={"p-1 col-md-4 flex-fill"}>
                        <div className={"bg-white rounded p-2 shadow-sm border h-100"}>
                            <h5 className={"fw-bold"}>
                                Estimate
                            </h5>
                            <h4>
                                Rs. {this.state.service.estimate}
                            </h4>
                        </div>
                    </div>
                </div>

                <div className={"p-1"}>
                    <div className={"p-1"}>
                        <b>Order status</b>
                    </div>
                    <div className={"p-1 d-flex"}>
                        <div className={"p-2 bg-light-success rounded border border-success"}>
                            <FontAwesomeIcon icon={"check-circle"} className={"me-1"}/>
                            {this.OrderStatus()}
                        </div>
                    </div>
                </div>

                <div className={"p-1"}>
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Description</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {this.state.service.description}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Note</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {this.state.service.note}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Accessories taken from customer</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {
                                    this.state.service.accessoriesTaken?.split(/[\s,]+/).map(accessories => <Badge
                                        className={"me-1"}>{accessories}</Badge>)
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Extra accessories ordered/added</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {
                                    this.state.service.accessoriesAttached?.split(/[\s,]+/).map(accessories => <Badge
                                        className={"me-1"}>{accessories}</Badge>)
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Activity Log</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"p-2 small"}>
                                    {this.state.service.activities?.map(activity => <div>
                                        {activity}
                                    </div>)}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <div className={"fw-bolder"}>Chat with care</div>
                            </Accordion.Header>
                            <Accordion.Body>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    }

    render() {
        return (
            <div className={"p-2 d-flex flex-fill flex-column"}>
                <LoadingOrErrorOrComponent is_loading={this.state.is_loading}
                                           label={"Service Tracking"}
                                           something_went_wrong={this.state.something_went_wrong}
                                           component={this.TrackServiceListComponent}/>
            </div>
        );
    }
}

export default Connector(ShowPublicTrackingService);