import React, {Component} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Connector} from "../../../../../../redux/Connector";
import {HideErrorModal} from "./CenterModalScreenManager";
import {ResetUserDetails} from "../../../managers/UserManager";

class CenterScreenModal extends Component {
    constructor(props) {
        super(props);
        this.closeErrorModal = this.closeErrorModal.bind(this);
        this.onSessionInvalidLogin = this.onSessionInvalidLogin.bind(this);
        HideErrorModal(this.props);
    }

    onSessionInvalidLogin() {
        ResetUserDetails(this.props);
    }

    closeErrorModal() {
        HideErrorModal(this.props);
    }

    render() {
        return (
            <div>
                <Modal show={this.props.modal.error_modal_screen.show} style={{zIndex: 9999}} onHide={this.closeErrorModal} className={"pm-modal"} centered>
                    <Modal.Body className={"text-center position-relative"}>
                        {
                            this.props.modal.error_modal_screen.image &&
                            <img src={this.props.modal.error_modal_screen.image} alt={"exception icon"} style={{height: "8vh"}}/>
                        }
                        <div className={"fw-bold py-2"}>
                            <h4>
                                {this.props.modal.error_modal_screen.label}
                            </h4>
                        </div>
                        <div className={"py-2"} style={{whiteSpace: "pre-wrap"}}>
                            <p>
                                {this.props.modal.error_modal_screen.content}
                            </p>
                        </div>
                        <div className={"w-100"}>
                            {
                                this.props.modal.error_modal_screen.okButton &&
                                <Button onClick={this.closeErrorModal} style={{width: 120}}>
                                    Ok
                                </Button>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Connector(CenterScreenModal);