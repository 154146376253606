import React from 'react';
import {Accordion, Button, Dropdown, Form, InputGroup, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ALLOWED_ICONS} from "../../../../../constants/Constant";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {WIDGET_TYPES} from "../../../../../../public/widgets/WidgetTypes";
import {Connector} from "../../../../../../../redux/Connector";
import WidgetPicker from "../../../../../../public/widgets/WidgetPicker";
import WebPageFunctions from "./WebPageFunctions";
import {WidgetEditProvider} from "../../../../../../public/widgets/edits/WidgetEditProvider";
import hash from "object-hash";
import {isNullOrUndefinedObject} from "../../../../../utils/validators/Validator";
import {SERVICE_TRACK} from "../../../../../../public/widgets/WidgetKey";

class CustomWebPage extends WebPageFunctions {

    state = {
        show_mobile_view: false,
        show_laptop_view: true,
        show_widget_picker: false,
        is_loading: false,
        widget_hash: "",
        image_added_to_gallery: false
    }

    constructor(props) {
        super(props);
        this.SetupInitialHashes = this.SetupInitialHashes.bind(this);
        this.PrecomputeHash = this.PrecomputeHash.bind(this);
        this.GetLatestHash = this.GetLatestHash.bind(this);
        this.ToggleMobileView = this.ToggleMobileView.bind(this);
        this.OnClickEditMode = this.OnClickEditMode.bind(this);
        this.SimpleTitleComponent = this.SimpleTitleComponent.bind(this);
        this.TitleComponent = this.TitleComponent.bind(this);
        this.DragDropView = this.DragDropView.bind(this);
        this.MobileView = this.MobileView.bind(this);
        this.LaptopView = this.LaptopView.bind(this);
        this.WebPageView = this.WebPageView.bind(this);

    }

    componentDidMount() {
        this.SetupInitialHashes();
    }

    SetupInitialHashes() {
        this.setState({
            widget_hash: this.GetLatestHash()
        });

        this.PrecomputeHash();
        this.HideWidgetPicker();
    }

    PrecomputeHash() {
        if(isNullOrUndefinedObject(this.props.page)) {
            return;
        }

        this.props.page.widgets.forEach(widget => {
            widget.attribute = {
                hash: this.GetWidgetHash(widget)
            };
        });
    }

    GetWidgetHash(widget) {
        let localWidget = JSON.parse(JSON.stringify(widget));
        localWidget.attribute = {};
        localWidget.image = "";
        localWidget.images = [];
        return hash.sha1(localWidget);
    }

    GetLatestHash() {
        if(isNullOrUndefinedObject(this.props.page)) {
            return "";
        }

        let widgets = JSON.parse(JSON.stringify(this.props.page.widgets));

        widgets.forEach(widget => {
            widget.attribute = {};
            widget.image = "";
            widget.images = [];
        });

        return hash.sha1(widgets);
    }

    ToggleMobileView() {
        this.setState({
            show_mobile_view: !this.state.show_mobile_view,
            show_laptop_view: this.state.show_mobile_view
        });
    }

    OnClickEditMode() {
        this.setState({
            show_mobile_view: false,
            show_laptop_view: false
        });
    }

    SimpleTitleComponent() {
        return <div className={"bg-white rounded m-2 py-3 px-4 shadow-sm"}>
            <div>
                <h5> Page Settings ({this.props.page.pageName}) </h5>
                <p className={"text-muted small m-0"}>Manage, edit widgets, delete widgets at this page</p>
            </div>
        </div>
    }

    TitleComponent() {
        return <div className={"bg-white rounded m-2 shadow-sm"}>
            <Accordion>
                <Accordion.Item eventKey="page_info">
                    <Accordion.Header>
                        <div>
                            <h5> Page Settings ({this.props.page.pageName}) </h5>
                            <p className={"text-muted small m-0"}>Manage, edit, delete page etc. click here to check more page settings</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <InputGroup className={"col-2"}>
                            <InputGroup.Text>
                                Select Icon For Page
                            </InputGroup.Text>
                            <Dropdown align={"start"} onSelect={this.OnSelectDropdownIcon}>
                                <Dropdown.Toggle variant={"light"} className={"shadow-none border"}
                                                 bsPrefix={"p-0"}>
                                    <div style={{width: 100}} className={"d-flex justify-content-center p-2"}>
                                        {
                                            this.props.page.iconName &&
                                            <FontAwesomeIcon icon={this.props.page.iconName}/>
                                        }
                                    </div>
                                    <Form.Control disabled name={"iconName"} className={"visually-hidden"}
                                                  defaultValue={this.props.page.iconName}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={"border-primary-light"} style={{minWidth: 100}}>
                                    {
                                        ALLOWED_ICONS.map(icon => <Dropdown.Item key={icon} eventKey={icon}
                                                                                 className={"p-3"}
                                                                                 style={{width: 100}}>
                                            <div className={"d-flex justify-content-center"}>
                                                <FontAwesomeIcon icon={icon}/>
                                            </div>
                                        </Dropdown.Item>)
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </InputGroup>

                        <Form.Check type={"switch"} className={"mt-3"} label={"Enabled this page"}
                                    defaultChecked={this.props.page.enabled} />
                        {
                            !this.props.page.enabled &&
                            <p className={"text-muted small"}>Once the page will enabled it'll start displayed
                                on
                                your website.</p>
                        }

                        <label className={"mt-2 fw-bold small"}>
                            Page short name
                        </label>
                        <Form.Control required minLength={3} maxLength={20} pattern="^\S+$"
                                      className={"shadow-none mb-2"}
                                      name={"pageShortName"} placeholder={"Page short name without space"}
                                      value={this.props.page.pageShortName} onChange={this.OnShortNameUpdate}/>

                        <label className={"fw-bold small"}>
                            Page name
                        </label>
                        <Form.Control required minLength={3} maxLength={20} className={"shadow-none mb-2"}
                                      placeholder={"Page name"} name={"pageName"}
                                      value={this.props.page.pageName} onChange={this.OnPageNameUpdate} />

                        <div className={"border border-danger rounded p-2"}>
                            <h6>Delete this page ({this.props.page.pageName}) </h6>
                            <p className={"text-muted small"}>
                                First disabled this page to delete
                            </p>
                            <Button variant={"outline-danger"} className={"shadow-none"} onClick={this.OnClickDeletePageButton}>
                                <FontAwesomeIcon icon={"trash"}/> Delete
                            </Button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>;
    }

    DragDropView() {
        if(this.state.show_mobile_view || this.state.show_laptop_view) {
            return <></>;
        }

        return <DragDropContext onDragEnd={this.OnDragEnd}>
            <Droppable droppableId={"widget_items_d"}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}
                         className={"border mx-2 bg-white"}>
                        {
                            // isDragDisabled={this.state.active_widget_save_index !== null}
                            this.props.page.widgets &&
                            this.props.page.widgets.map((dataComponent, index) => <Draggable
                                key={"drop_widget_item_" + index} draggableId={"drop_widget_item_" + index}
                                index={index}>
                                {(providedDraggable) => (<div
                                    ref={providedDraggable.innerRef} {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps}
                                    key={dataComponent.widgetKey}>
                                    <div className={"hover-shadow position-relative web-page-widget"}>
                                        {
                                            this.state.active_widget_edit_index !== index &&
                                            <div className={"web-page-widget-btn"}>
                                                <div className={"d-flex p-2"}>
                                                    <div className={"me-2 fw-bold d-flex align-items-center"}>
                                                        Manage this below section
                                                    </div>
                                                    <div className={"d-flex justify-content-end flex-fill"}>
                                                        <Button variant={"success"} className={""}
                                                                onClick={() => this.OnClickEditWidget(index)}>
                                                            <FontAwesomeIcon icon={"edit"}/> Edit
                                                        </Button>
                                                        {
                                                            dataComponent.widgetKey !== SERVICE_TRACK &&
                                                            <Button variant={"danger"} className={"ms-1"}
                                                                    onClick={() => this.OnClickDeleteWidget(index)}>
                                                                <FontAwesomeIcon icon={"trash"}/> Delete
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.active_widget_edit_index === index &&
                                            <div className={"p-2 bg-white shadow-sm hover-shadow"}>
                                                {WidgetEditProvider(
                                                    this.props,
                                                    index,
                                                    this.props.serviceId,
                                                    this.props.pageId,
                                                    dataComponent,
                                                    this.OnUpdateEditField,
                                                    this.HideWidgetPicker,
                                                    this.OnChangeImage
                                                )}
                                            </div>
                                        }

                                        {
                                            WIDGET_TYPES[dataComponent.widgetKey]?.component(dataComponent)
                                        }
                                    </div>
                                </div>)}
                            </Draggable>)
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>;
    }

    MobileView() {
        if(!this.state.show_mobile_view) {
            return <></>;
        }

        return <div className={"d-flex justify-content-center p-2 bg-white mx-2"}>
            <div className={"mobile-view-css overflow-hidden h-100"}>
                <div className={"d-flex flex-column bg-white mobile-view-css-child position-relative"}>
                    <div className={"p-2 bg-light text-muted position-sticky top-0 shadow-sm"}>
                        Navbar
                        <div className={"float-end"}>
                            Sign In
                        </div>
                    </div>
                    <div className={"flex-fill"}>
                        {
                            this.props.page.widgets.map((dataComponent, index) => <div key={dataComponent.id}>
                                {
                                    WIDGET_TYPES[dataComponent.widgetKey]?.component(dataComponent)
                                }
                            </div>)
                        }
                    </div>
                    <div className={"p-2 bg-dark text-muted pb-4"}>
                        This is the footer
                    </div>
                </div>
            </div>
        </div>
    }

    LaptopView() {
        if(!this.state.show_laptop_view) {
            return <></>;
        }

        return <div className={"bg-white mx-2 p-2"}>
            <div className={"laptop-view-css overflow-hidden"}>
                <div className={"bg-white laptop-view-css-child h-100 position-relative"}>
                    <div className={"p-2 bg-light text-muted position-sticky top-0 shadow-sm"}>
                        Navbar
                        <div className={"float-end"}>
                            Sign In
                        </div>
                    </div>
                    <div style={{minHeight: 240}}>
                        {
                            this.props.page.widgets.map((dataComponent, index) => <div key={"lv_" + dataComponent.id}>
                                {
                                    WIDGET_TYPES[dataComponent.widgetKey]?.component(dataComponent)
                                }
                            </div>)
                        }
                    </div>
                    <div className={"p-2 bg-dark text-muted pb-4"}>
                        This is the footer
                    </div>
                </div>
            </div>
        </div>
    }

    WebPageView() {
        if(this.state.show_widget_picker) {
            return <div className={"position-relative flex-fill bg-light"} style={{zIndex: 99}}>
                <WidgetPicker onCloseButtonClick={this.HideWidgetPicker}
                              onClickWidget={this.OnClickWidget}/>
            </div>;
        }

        return <div className={"position-relative flex-fill"}>
            <div>
                {
                    this.props.noEditPageInfo? <this.SimpleTitleComponent />:<this.TitleComponent/>
                }

                <div className={"mx-2 p-2 text-muted small border bg-light"}>
                    Your page starts from here, please click <b className={"text-black"}>Add new widget</b> at
                    bottom to add new section to the page. Then
                    edit and save the web page
                </div>

                <this.DragDropView />
                <this.MobileView />
                <this.LaptopView />

                <div className={"mx-2 p-2 text-muted small border bg-light"}>
                    End of the page
                </div>

                <div className={"p-2 text-center"}>
                    {
                        (this.state.show_laptop_view || this.state.show_mobile_view) &&
                        <Button className={"me-2 rounded-pill shadow-none"}
                                onClick={this.OnClickEditMode} disabled={this.state.is_loading} >
                            {this.state.is_loading?<Spinner animation={"border"} size={"sm"}/>:<FontAwesomeIcon icon={"edit"}/>} Edit Mode
                        </Button>
                    }
                    <Button variant={"outline-primary"} className={"me-2 rounded-pill shadow-none"} onClick={this.ToggleMobileView}>
                        <FontAwesomeIcon icon={"mobile"}/>
                        {
                            this.state.show_mobile_view? " Show desktop view": " Show mobile view"
                        }
                    </Button>
                    {
                        !(this.state.show_laptop_view || this.state.show_mobile_view) &&
                        <Button className={"me-2 rounded-pill shadow-none"}
                                onClick={this.ShowWidgetPicker}>
                            <FontAwesomeIcon icon={"address-book"}/> Add new widget
                        </Button>
                    }
                    {
                        this.GetLatestHash() !== this.state.widget_hash &&
                        <Button variant={"success"} className={"me-2 rounded-pill shadow-none"}
                                onClick={this.OnClickSaveWidget} disabled={this.state.is_loading} >
                            {this.state.is_loading?<Spinner animation={"border"} size={"sm"}/>:<FontAwesomeIcon icon={"check-circle"}/>} Save updates
                        </Button>
                    }
                </div>
            </div>
        </div>;
    }

    render() {
        return (
            <this.WebPageView />
        );
    }
}

export default Connector(CustomWebPage);