import {Component} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link} from "react-router-dom";
import {GetAddress} from "../../webrequests/AddressRequest";
import {Connector} from "../../../../../redux/Connector";
import {CREATE_ADDRESS_URL} from "../../../constants/application/account/AddressUrls";
import {ACCOUNT_URL, HOME_URL} from "../../../constants/application/ApplicationUrl";

class MyAddress extends Component {
    state = {
        addresses: [],
    }

    constructor(props) {
        super(props);
        this.OnReceivedAddress = this.OnReceivedAddress.bind(this);
    }

    componentDidMount() {
        GetAddress(this.props, this.OnReceivedAddress, this.OnFailure);
    }

    OnReceivedAddress(address) {
        this.setState({
            addresses: address
        });
    }

    OnFailure(error) {
        // TODO
    }

    render() {
        return (
            <Container fluid>
                <Row noGutters>
                    <Col lg={2}>
                        <div className={"p-1 d-flex flex-wrap"}>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"} as={Link} to={CREATE_ADDRESS_URL}>
                                <FontAwesomeIcon icon="address-book"/> Add a new address
                            </Button>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"} as={Link} to={ACCOUNT_URL}>
                                <FontAwesomeIcon icon="address-book"/> My Account
                            </Button>
                            <Button variant={"success m-1 text-left"} className={"flex-grow-1"} as={Link} to={HOME_URL}>
                                <FontAwesomeIcon icon="address-book"/> Home
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <Row noGutters>
                            {
                                this.state.addresses.map(address => {
                                    return (
                                        <Col md={6} lg={4} className={"p-2"}>
                                            <div className="shadow-sm hover-shadow p-2 bg-white rounded">
                                                <b>{address.firstName} {address.lastName}</b>
                                                <p className="m-0">{address.addressLine1}</p>
                                                <p className="m-0">{address.addressLine2}</p>
                                                <p className="m-0">{address.city}, {address.state}, {address.pinCode}</p>
                                                <p>Mobile: {address.mobile}</p>
                                                <Link to="/" className={""}>
                                                    More instructions
                                                </Link>
                                                <Link to="/" className={""}>
                                                    <p>
                                                        <FontAwesomeIcon icon={"search-location"}/> Update location on
                                                        map
                                                    </p>
                                                </Link>
                                                <Button variant={"outline-success"} className="m-1"
                                                        onClick={this.onClickEditAddress}>
                                                    <FontAwesomeIcon icon={"edit"}/> Edit
                                                </Button>
                                                <Button variant={"outline-danger"} className="float-right m-1"
                                                        onClick={this.onClickDeleteAddress}>
                                                    <FontAwesomeIcon icon={"trash"}/> Delete
                                                </Button>
                                                <Button variant={"outline-primary"} className="float-right m-1"
                                                        onClick={this.onClickDeleteAddress}>
                                                    <FontAwesomeIcon icon={"default"}/> Set Default
                                                </Button>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                            <Col md={6} lg={4} className={"p-2  no-text-decor"} as={Link} to={CREATE_ADDRESS_URL} style={{minHeight: 200}}>
                                <div className="shadow-sm h-100 fw-bold d-flex flex-column item-align-center justify-content-center hover-shadow p-2 bg-light rounded">
                                    <div className={"text-center"}>
                                        Add a new address
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Connector(MyAddress);