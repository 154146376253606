import React from "react";
import {Connector} from "../../../../../redux/Connector";
import {Button, Col, Row} from "react-bootstrap";
import {ACCOUNT_URL, LOGIN_PAGE_URL, PARAM_SEPARATOR} from "../../../constants/application/ApplicationUrl";
import {Link} from "react-router-dom";
import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {GET_USER_SERVICE_ACCOUNTS_API} from "../../../constants/backend/ApiConstant";
import {
    MY_SERVICES_URL,
    MY_SINGLE_SERVICE_SETTINGS_URL
} from "../../../constants/application/account/services/ServiceAccountUrls";
import {SERVICE_ACCOUNT_PARAM} from "../../../constants/ParamConstant";
import {ServiceAccount} from "../../business/account/services/ServiceAccountCard";
import {ACCOUNT_ORDERS_URL} from "../../../constants/application/account/OrdersUrls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";

class ItsMyWebHome extends React.Component {
    state = {
        service_accounts: []
    }

    constructor(props) {
        super(props);
        this.onGetServiceAccountSuccess = this.onGetServiceAccountSuccess.bind(this);
    }

    componentDidMount() {
        SetDocumentTitle("Grow your business & reach more customers");

        if(this.props.user.username) {
            BackendGETRequest(this.props, GET_USER_SERVICE_ACCOUNTS_API, this.onGetServiceAccountSuccess);
        }
    }

    onGetServiceAccountSuccess(serviceAccounts) {
        this.setState({
            service_accounts: serviceAccounts.body["businessAccountResponseList"]
        });
    }

    render(){
        return(
            <div className={"flex-grow-1 d-flex flex-fill flex-column"}>
                <div className={"w-100 flex-fill position-relative overflow-hidden"} >
                    <svg className={"position-absolute top-0"} style={{zIndex: -1, height: "70%"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#294cda" fillOpacity="1" d="M0,256L60,224C120,192,240,128,360,133.3C480,139,600,213,720,245.3C840,277,960,267,1080,250.7C1200,235,1320,213,1380,202.7L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                    </svg>

                    <div className={"text-white"} style={{paddingLeft: "5%", paddingTop: "4%"}}>
                        <h1 className={"fw-bold"}>ITS MY WEB</h1>
                        <p className={"my-3"}>
                            Create website to grow your business and reach more customers
                        </p>
                        {
                            this.props.user.username?<div className={"d-flex mt-4 flex-wrap"}>
                                    <Button as={Link} to={ACCOUNT_URL} variant={"primary"} className={"shadow rounded-pill"}>
                                        <FontAwesomeIcon icon={"user"} /> My account
                                    </Button>
                                    <Button as={Link} to={MY_SERVICES_URL} variant={"primary"} className={"shadow rounded-pill mx-2"}>
                                        <FontAwesomeIcon icon={"user-tie"} /> My services
                                    </Button>
                                    <Button as={Link} to={ACCOUNT_ORDERS_URL} variant={"primary"} className={"shadow rounded-pill hide-on-small-screen"}>
                                        <FontAwesomeIcon icon={"box-open"} /> My orders
                                    </Button>
                                </div>:
                                <Button as={Link} to={LOGIN_PAGE_URL} variant={"primary"} className={"shadow rounded-pill mt-4"}>Login now</Button>
                        }
                    </div>
                    <div className={"mt-3"}  style={{paddingLeft: "4%", paddingRight: "4%"}} >
                        <Row className={"g-0"}>
                            {
                                this.state.service_accounts.map(account => {
                                    return <Col sm={6} md={4} lg={3} className={"p-1 no-text-decor"} as={Link} key={account.id}
                                                to={MY_SINGLE_SERVICE_SETTINGS_URL.replace(PARAM_SEPARATOR + SERVICE_ACCOUNT_PARAM, "/" + account.id)}>
                                        <ServiceAccount name={account.businessName} type={account.businessType} approved={account.approved} />
                                    </Col>;
                                })
                            }
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default Connector(ItsMyWebHome);